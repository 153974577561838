export enum TokenFormat { Base64 = 'base64' }
export const DEFAULT_SOCURE_CONTEXT = 'Lynx';
export const DEVICE_SCRIPT_SOURCE = 'https://js.dvnfo.com/devicer.min.js';
export const DEVICE_RISK_SCRIPT_SOURCE = 'https://cdn.dvnfo.com/device-risk-sdk.js';
export interface TrackingOptions {
  publicKey: string,
  userConsent: boolean,
  context: string,
  tokenFormat?: TokenFormat,
}

export enum TrackingResult {
  Captured = 'Captured',
  Ignored = 'Ignored'
}

export interface TrackingResponse {
  sessionId: string,
  result: TrackingResult
}

export type TrackingCallback = (response: TrackingResponse) => void;

export interface Devicer {
  run: (
    options: TrackingOptions,
    callback: TrackingCallback,
  ) => void;
}

export interface SigmaDeviceManager {
  getDeviceSessionId:  () => Promise<string>;
}

export enum SOCURE_MODULE  {
  DEVICE= 'DEVICE',
  DEVICE_RISK ='DEVICE_RISK'
}