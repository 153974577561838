import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {tap} from 'rxjs/operators';
import {environment} from '../../../../../environments/environment';

@Injectable({providedIn: 'root'})
export class LynxPrefillStarterService {
  prefillUrl: string = `${environment.lynxApi}/prefilled-application`;

  constructor(private httpClient: HttpClient) {
  }

  prefillApplication(ccApp: Object) {
    return this.httpClient.post(this.prefillUrl, ccApp).pipe(
      tap((resp: Object) => {
        const applicationUrl = resp['applicationUrl'];
        if (applicationUrl) {
          this.navigateToPrefillUrl(applicationUrl);
        }
      })
    )
  }

  mapErrors = (error: HttpErrorResponse): string[] => {
    const errors: string[] = [];
    const errorResponse = error.error;
    if (errorResponse && errorResponse.errors) {
      errorResponse.errors.forEach(errorMessage => {
        let errorLine: string = `${errorMessage.fieldName}: `;
        let ctr = 0;
        if (errorMessage.errorMessages) {
          errorMessage.errorMessages.forEach(errorMsg => {
            errorLine += errorMsg;
            if (ctr != errorMessage.errorMessages.length - 1) {
              errorLine += ', ';
            }
            ctr++;
          });
        }
        errors.push(errorLine);
      });
    } else {
      errors.push(`An error has occurred: ${error.status}, Please check your ShortCode`);
    }
    return errors;
  }

  navigateToPrefillUrl = (applicationUrl: string) => {
    window.location.href = applicationUrl;
  }
}

export interface LynxPrefillResponse {
  applicationUrl: string,
  errors: string[]
}


