import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {NgForm} from '@angular/forms';
import {CommercialApplicationModel} from '../../shared/models/commercial/commercial-application-model';
import {BaseForm} from '../../shared/components/base-form/base-form';
import {LynxPrefillStarterService} from '../../shared/services/prefill/prefill-starter-page.service';
import {HttpErrorResponse} from '@angular/common/http';

@Component({
  selector: 'lynx-commercial-starter',
  templateUrl: './commercial-starter.component.html',
  styleUrls: ['./commercial-starter.component.scss']
})
export class CommercialStarterComponent implements OnInit {

  @ViewChild('form') form: NgForm;
  @ViewChild('htmlForm') domForm: ElementRef;

  request = {
    shortCode: null,
    agentBank: null,
    companyRewardsNumber: null,
    subAgent: null,
    rewardsNumber: null,
    employeeId: null,
    referralId: null,
    salesId: null,
    bankBranch: null,
    referringEmployeeId: null,
    thirdPartyCustomerId: null,
    customerSessionId: null,
    customerLoginId: null,
    partnerMarketingCode: null,
  };
  errors: string[] = [];

  constructor(private lynxPrefillStarterService: LynxPrefillStarterService) {
  }

  ngOnInit(): void {
  }

  prefill() {
    this.errors.length = 0;
    if (!this.form.valid) {
      BaseForm.handleScrollToInvalid(this.domForm);
      return;
    }
    const prefillRequest = this.mapToPrefillRequest(this.request);
    this.lynxPrefillStarterService.prefillApplication(prefillRequest)
      .subscribe(() => {},
        (error: HttpErrorResponse) => {
          this.errors = this.lynxPrefillStarterService.mapErrors(error);
        });
  }

  private mapToPrefillRequest(starterPageRequest: Object) {
    const prefillRequest = {
      commercialInfo: {
        company: {
          companyRewardsNumber: starterPageRequest['companyRewardsNumber']
        },
      } as Partial<CommercialApplicationModel>,
      ...starterPageRequest
    }
    delete prefillRequest['companyRewardsNumber'];
    return prefillRequest;
  }
}
