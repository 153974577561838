import {ApplicationModel} from '../application-model';
import {Injectable} from '@angular/core';
import {Representative} from './representative';
import {CompanyInfo} from './CompanyInfo';
import Address from '../../../commercial/company/Address';
import {AdditionalEmployeeCard} from '../AdditionalEmployeeCard';
import {BeneficialOwner} from './BeneficialOwner';
import {ModalService} from '../../services/modalService.service';
import {BankInfo} from '../BankInfo';
import {ControllingParty} from '../ControllingParty';
import {AttestationInfo} from '../AttestationInfo';
import {LegalEntityRules} from '../../../commercial/services/LegalEntityRules';

export const maxNumberOfBfos = 4;

@Injectable({providedIn: 'root'})
export class CommercialApplicationModel extends ApplicationModel {
  // TODO shared, move to ApplicationModel
  returnRelativePath?: string;
  company: CompanyInfo;
  bank: BankInfo;
  grossAnnualRevenueType: string;
  authorizedCompanyRepresentative: Representative;
  companyLegalStructure: string;
  naicsCode: string;
  employeeCards: AdditionalEmployeeCard[];
  bfos: BeneficialOwner[];
  controllingParty: ControllingParty;
  modalService: ModalService;

  // Review
  authorizedRepresentativeName: string;
  title: string;

  attestationInfo: AttestationInfo;
  hasOwnershipChanged: boolean;

  securedDepositAmount?: number;
  securedDepositAccountType?: string;
  securedDepositRoutingNumber?: string;
  securedDepositVerifyRoutingNumber?: string;
  securedDepositAccountNumber?: string;
  securedDepositVerifyAccountNumber?: string;

  backupWithholding: boolean;
  prequalId: string;

  constructor(modalService: ModalService) {
    super();
    this.setInitialValues();
    this.company = new CompanyInfo();
    this.modalService = modalService;
  }

  protected setInitialValues() {
    super.setInitialValues();
    this.authorizedCompanyRepresentative = new Representative();
    this.controllingParty = new ControllingParty();
    this.bank = new BankInfo();
    this.bfos = [];
    for (let x = 0; x < maxNumberOfBfos; x++) {
      this.bfos.push(new BeneficialOwner());
    }
    this.employeeCards = [];
    if (this.modalService) {
      this.modalService.hasViewedBfoRequirements = false;
    }
  }

  getCompanyPhysicalAddress(): Address {
    return new Address({
      addressLine1: this.company.physicalAddressLine1,
      addressLine2: this.company.physicalAddressLine2,
      city: this.company.physicalAddressCity,
      state: this.company.physicalAddressState,
      zipCode: this.company.physicalAddressZip
    });
  }

  getPayload(shortCode: string, fraudData: string, legalEntityRules: LegalEntityRules, socureSessionId: string = null) {
    const activeBusinessRules = legalEntityRules.businessRules;
    if (!activeBusinessRules.beneficialOwnerRequired || (legalEntityRules.promptForBfoChanges && !this.hasOwnershipChanged)) {
      this.bfos = [];
    }
    if (!activeBusinessRules.controllingPartyRequired || (legalEntityRules.promptForBfoChanges && !this.hasOwnershipChanged)) {
      this.controllingParty = null;
    }
    const payload = {
      ...this as Object,
      ...{company: {...this.company}},
      fraudData,
      socureSessionId,
      shortCode
    };

    delete payload['modalService'];
    return payload;
  }
}
