import {Injectable} from '@angular/core';
import {InstantCreditResults} from './instant-credit-results';

@Injectable({
  providedIn: 'root'
})
export class ResultService {

    creditLimit ?: number;
    productName: string;
    approvedResponseUrl?: string;
    instantCredit?: InstantCreditResults;
    idiEvaluationIndicator?: IdiEvaluationIndicator
    temporaryCreditLineDetails?: TemporaryCreditLineDetails;

    constructor() {
    }
}

export enum IdiEvaluationIndicator {
    APPROVED_FOR_INSTANT_DIGITAL_ISSUANCE = 'APPROVED_FOR_INSTANT_DIGITAL_ISSUANCE',
    DECLINED_FOR_INSTANT_DIGITAL_ISSUANCE = 'DECLINED_FOR_INSTANT_DIGITAL_ISSUANCE',
    NOT_EVALUATED_FOR_INSTANT_DIGITAL_ISSUANCE = 'NOT_EVALUATED_FOR_INSTANT_DIGITAL_ISSUANCE'
}

export type TemporaryCreditLineDetails = {
    temporaryCreditLine: number;
    temporaryCreditLineRestrictionMessage: string;
}
