import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse} from '@angular/common/http';
import {Observable} from 'rxjs';
import {tap} from 'rxjs/operators';
import {Router} from '@angular/router';
import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class HttpErrorInterceptor implements HttpInterceptor {

  constructor(private router: Router) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request)
      .pipe(
        tap(event => this.checkForApplicationError(event))
      );
  }

  private checkForApplicationError(event: HttpEvent<any>) {
    if (event instanceof HttpResponse) {
      // Some errors are returned with a 207 status since the firewall removes all headers from 4xx/5xx
      // responses. This prevents the UI app from receiving a fresh syncToken header token which causes the
      // next request to fail.
      if (event.status === 207) {
        if (event.body['terminalError']) {
          this.router.navigate(['/error']);
        }
      }
    }
  }
}