import {Pipe, PipeTransform} from '@angular/core';

@Pipe({name: 'ssnMask'})
export class SsnMaskPipe implements PipeTransform {
  transform(ssn: string) {
    if (!ssn || this.alreadyFormatted(ssn)) {
      return ssn;
    }
    const last4 = ssn.substring(ssn.length - 4);
    return 'XXX-XX-' + last4;
  }

  private alreadyFormatted(ssn: string) {
    return ssn.startsWith('XXX-XX-');
  }
}