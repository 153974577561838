import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ErrorDisplay} from '../../services/error-display';
import {FormControl} from '@angular/forms';

@Component({
  selector: 'lynx-inline-select',
  templateUrl: './inline-select.component.html',
  styleUrls: ['./inline-select.component.scss']
})
export class InlineSelectComponent implements OnInit {
  @Input() submitted = false;
  @Input() control: FormControl;
  @Input() fieldId: string;
  @Input() options: {value: any, display?: string}[];
  @Input() explanatoryText: string;
  @Input() showLabel = true;
  @Input() isGroupInvalid: boolean;
  @Output() focusChange: EventEmitter<boolean> = new EventEmitter();
  @Output() ngModelChange: EventEmitter<string> = new EventEmitter();

  constructor(public errorDisplay: ErrorDisplay) { }

  ngOnInit() {
    if(this.control && this.control.value === undefined) {
      this.control.setValue(null, {emitEvent: false});
    }
  }

  displayOption(item: {value: any, display?: string}): string {
    return item.display || item.value;
  }
  onFocus() {
    this.focusChange.emit(true);
  }

  onBlur() {
    this.focusChange.emit(false);
  }

  onChange($event) {
    this.ngModelChange.emit($event);
  }
}
