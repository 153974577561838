import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {IdiEvaluationIndicator, ResultService, TemporaryCreditLineDetails} from '../../services/result-service.service';
import * as currencyFormatter from 'currency-formatter';
import {PromoConfigurationService} from '../../services/promo-configuration.service';
import {Title} from '@angular/platform-browser';
import {TsiDisclosuresClient} from '../../../consumer/tsi-disclosures-client';
import {ModalService} from '../../services/modalService.service';
import {AccountDetails, InstantCreditResults} from '../../services/instant-credit-results';
import {MarkdownWithBreaksService} from '../../services/markdown-with-breaks.service';
import {BehaviorSubject, combineLatest, EMPTY, Observable, Subscription} from 'rxjs';
import {removeHtmlMarkup} from '../../services/text-transforms';
import {ApplicationIdService} from '../../services/application-id.service';
import {filter, finalize, map} from 'rxjs/operators';
import {HttpClient, HttpResponse} from "@angular/common/http";
import {environment} from "../../../../../environments/environment";
import {AdobeLaunchService} from "../../tracking/analytics/adobe-launch/adobe-launch.service";

interface IdiDataForAnalyticsTracking {
  applicationId: string;
  promoCode: string;
}

@Component({
  selector: 'approved',
  templateUrl: './approved.component.html',
  styleUrls: ['./approved.component.scss']
})
export class ApprovedComponent implements OnInit, OnDestroy {

  private static readonly PRINT_TERMS_ATTEMPT_LIMIT: number = 2;
  readonly printTermsAvailable$: BehaviorSubject<boolean> = new BehaviorSubject(true);
  private printTermsAttempts: number = 0;

  productName: string;
  approvedResponseButtonText: string;
  approvedResponseUrl: string;
  preApprovedMessage: string;
  postApprovedMessage: string;
  approvedMessage: string;
  accountDetails: AccountDetails;
  showReserveMessage: boolean;
  autoProvision: boolean = false;
  autoProvisionEnabled: boolean = false;
  enforceIDIDecisionOutCome: boolean = false;
  temporaryCreditLineDetails: TemporaryCreditLineDetails;

  applicationId$: Observable<string> = EMPTY;
  idiData$: Observable<IdiDataForAnalyticsTracking> = EMPTY;

  private instantCredit: InstantCreditResults;
  private promoSubscription: Subscription;

  constructor(private resultService: ResultService,
    private promoConfigurationService: PromoConfigurationService,
    private title: Title,
    private tsiDisclosuresClient: TsiDisclosuresClient,
    private modalService: ModalService,
    private markdownService: MarkdownWithBreaksService,
    @Inject('Window') private _window: Window,
    private applicationIdService: ApplicationIdService,
    private http: HttpClient,
    private adobeLaunchService: AdobeLaunchService,
  ) {
  }

  ngOnInit() {
    this.promoConfigurationService.invalidateShortCode();



    this.promoSubscription = this.promoConfigurationService.promoConfiguration.subscribe((promoConfiguration) => {
      this.approvedResponseButtonText = promoConfiguration.approvedResponseButtonText;
      this.approvedResponseUrl = promoConfiguration.approvedResponseUrl;
      this.approvedMessage = this.markdownService.render(promoConfiguration.approvedMessage);
      this.showReserveMessage = promoConfiguration.partnerConfiguration['showReserveMessage'] as boolean;

      this.productName = this.resultService.productName;
      this.instantCredit = this.resultService.instantCredit;
      this.enforceIDIDecisionOutCome = promoConfiguration.partnerConfiguration['enforceIDIDecisionOutCome'] as boolean;
      let provisioningEnabled = this.enforceIDIDecisionOutCome ? this.isInstantDigitalWalletProvisioningEnabled(this.resultService.idiEvaluationIndicator) : promoConfiguration.autoProvision;
      this.autoProvision = provisioningEnabled;
      this.autoProvisionEnabled = provisioningEnabled;
      if (this.resultService.instantCredit) {
        if (this.instantCredit.preApprovedMessage) {
          this.preApprovedMessage = this.markdownService.render(this.instantCredit.preApprovedMessage);
        }
        if (this.instantCredit.postApprovedMessage) {
          this.postApprovedMessage = this.markdownService.render(this.instantCredit.postApprovedMessage);
        }
        this.accountDetails = this.instantCredit.accountDetails;
      }
      this.title.setTitle(removeHtmlMarkup(this.productName));
      let enforceCreditLineRestriction = promoConfiguration.partnerConfiguration['enforceCreditLineRestriction'] as boolean;
      if (enforceCreditLineRestriction) {
        this.temporaryCreditLineDetails = this.resultService.temporaryCreditLineDetails;
      }
    });
    this.applicationId$ = this.applicationIdService.applicationId$
      .pipe(filter(id => !!id));

    this.idiData$ = combineLatest([this.applicationId$, this.promoConfigurationService.promoConfiguration])
      .pipe(
          map(([applicationId, promoConfiguration]) => ({
            applicationId,
            promoCode: promoConfiguration.promoCode
          })
        )
      );
  }

  presentCreditLimit() {
    return currencyFormatter.format(this.resultService.creditLimit, {locale: 'en-US', precision: 0});
  }

  shouldDisplayResponseButton(): boolean {
    return !!this.approvedResponseButtonText && !!this.approvedResponseUrl;
  }

  shouldDisplayCreditLimit(): boolean {
    const creditLimit = this.resultService.creditLimit;
    return !!creditLimit || creditLimit === 0;
  }

  showPrint(): boolean {
    const instantCredit = this.resultService.instantCredit;
    return !!instantCredit && !!instantCredit.disclosureStatus;
  }

  shouldDisplayWeReserveMessage(): boolean {
    return this.showReserveMessage
  }

  printTsiDisclosures() {

    this.printTermsAvailable$.next(false);

    if (this.instantCredit.disclosureStatus !== 'ERROR') {
      this.modalService.openModal('pendingTerms');
      this.tsiDisclosuresClient.get(this.instantCredit.tsiDisclosureId)
      .pipe(
        this.limitPrintAttempts()
      )
      .subscribe((html: string) => {
        this.modalService.closeModal();

        const iframe = document.getElementById('tsiFrame') as HTMLFrameElement;
        iframe.longDesc = 'hidden content';
        iframe.name = 'tsiFrame';

        iframe.onload = function() {
          iframe.contentWindow.focus();
          /**
           * IE Print support
           * https://stackoverflow.com/questions/8055889/iframe-print-vs-window-print-on-ie-small-font-in-former
           */
          try {
            if (!iframe.contentWindow.document.execCommand('print', false, null)) {
              iframe.contentWindow.print();
            }
          } catch {
            iframe.contentWindow.print();
          }
        };

        iframe.contentWindow.document.open();
        iframe.contentWindow.document.write(html);
        iframe.contentWindow.document.close();

      }, _ => {
        this.openTermsError();
      });
    } else {
      this.openTermsError();
    }
  }

  private limitPrintAttempts = () => {
    return finalize(() => {
      if (ApprovedComponent.PRINT_TERMS_ATTEMPT_LIMIT > ++this.printTermsAttempts) {
        this.printTermsAvailable$.next(true);
      }
    });
  };

  getApprovedResponseUrl(): string {
    const { approvedResponseUrl } = this.resultService;
    if (approvedResponseUrl) {
      return approvedResponseUrl;
    } else {
      return this.approvedResponseUrl;
    }
  }

  goToPage() {
    const approvedResponseUrl = this.getApprovedResponseUrl();
    if (approvedResponseUrl) {
      const trimmedUrl = approvedResponseUrl.trim();
      if (trimmedUrl.endsWith('/#/close')) {
        this._window.close();
      } else {
        this._window.open(trimmedUrl, '_self');
      }
    }
  }

  private openTermsError() {
    this.modalService.openModal('termsError', this.instantCredit.disclosureErrorMessage);
  }

  ngOnDestroy() {
    if (this.promoSubscription) {
      this.promoSubscription.unsubscribe();
    }

    this.applicationIdService.applicationId$.next(null);
  }

  provisionCard(walletType: string) {
    this.trackIdiWasUsedByCustomer(walletType);
    this.http.post(`${environment.lynxApi}/lynx/auto-provisions`, {observe: 'response', responseType: 'text'}, {params: {walletType: walletType }})
        .subscribe((res: HttpResponse<Object>) => {
            this.autoProvisionEnabled = false;
    });
  }

  private trackIdiWasUsedByCustomer(walletType: string) {
    this.idiData$.subscribe((idiData) => {
      this.adobeLaunchService.trackDefer({
        page_name: 'Lynx IDI Initiated',
        application_id: idiData.applicationId,
        idiData: {
          ...idiData,
          walletType,
        },
      });
    });
  }

  private isInstantDigitalWalletProvisioningEnabled(idiEvaluationIndicator: IdiEvaluationIndicator) {
    return IdiEvaluationIndicator.APPROVED_FOR_INSTANT_DIGITAL_ISSUANCE === idiEvaluationIndicator;
  }
}
