import {BfoBusinessRules} from "../../shared/models/commercial/BfoBusinessRules";

export class LegalEntityRules {
  businessRules : BfoBusinessRules;
  promptForBfoChanges: boolean;
}

export class LegalEntityRulesRequest {
  taxId: string;
  entityType: string;
}