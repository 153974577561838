import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HeaderService {
  private titleChanges: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  private byLineChanges: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  setTitle(title: string) {
    this.titleChanges.next(title);
  }

  getTitleChanges() {
    return this.titleChanges;
  }

  setByLine(byLine: string) {
    this.byLineChanges.next(byLine);
  }

  getByLineChanges() {
    return this.byLineChanges;
  }
}