export enum RESULTSTATE {
    GRANTED = 'GRANTED',
    DENIED = 'DENIED',
    ERROR = 'ERROR',
    NOT_REQUESTED = 'NOT_REQUESTED'
}

export enum GeolocationPositionErrorCode {
    POSITION_DENIED = 1,
    POSITION_UNAVAILABLE = 2,
    TIMEOUT = 3
}

export class GeolocationPositionError {
    code: number;
    message: string;
}