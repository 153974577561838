import {Component, OnInit, Renderer2} from '@angular/core';
import {BaseModalComponent} from '../base-modal.component';
import {ModalService} from '../../../services/modalService.service';
import {ElectronicTermsService} from './electronic-terms.service';

@Component({
  selector: 'electronic-terms',
  templateUrl: './electronic-terms.component.html',
  styleUrls: ['../modal.scss', './electronic-terms.component.scss']
})
export class ElectronicTermsComponent extends BaseModalComponent implements OnInit {

  constructor(renderer: Renderer2, modalService: ModalService, private electronicTermsService: ElectronicTermsService) {
    super(renderer, modalService);
  }

  agreeClick() {
    this.electronicTermsService.agreeClick();
    this.closeMyself();
  }

  closeMyself() {
    this.electronicTermsService.closeClick();
    super.closeMyself();
  }
}
