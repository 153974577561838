import {AfterViewInit, Component, Input, OnChanges, OnInit} from '@angular/core';
import {ReactiveSelectFieldComponent} from '../reactive-select-field/reactive-select-field.component';
import {ErrorDisplay} from '../../services/error-display';
import {Global} from '../../global';

@Component({
  selector: 'lynx-reactive-state-select-field',
  templateUrl: '../reactive-select-field/reactive-select-field.component.html',
  styles: ['select.form-control:not([size]):not([multiple]) { height: 44px; }']
})
export class ReactiveStateSelectFieldComponent extends ReactiveSelectFieldComponent implements OnInit {
  @Input() customOptions: {value: any, display?: string}[];

  constructor(errorDisplay: ErrorDisplay, protected global: Global) {
    super(errorDisplay, global);
  }

  options = [
    {value: 'AL'},
    {value: 'AK'},
    {value: 'AZ'},
    {value: 'AR'},
    {value: 'CA'},
    {value: 'CO'},
    {value: 'CT'},
    {value: 'DE'},
    {value: 'DC'},
    {value: 'FL'},
    {value: 'GA'},
    {value: 'HI'},
    {value: 'ID'},
    {value: 'IL'},
    {value: 'IN'},
    {value: 'IA'},
    {value: 'KS'},
    {value: 'KY'},
    {value: 'LA'},
    {value: 'ME'},
    {value: 'MA'},
    {value: 'MD'},
    {value: 'MI'},
    {value: 'MN'},
    {value: 'MS'},
    {value: 'MO'},
    {value: 'MT'},
    {value: 'NE'},
    {value: 'NV'},
    {value: 'NH'},
    {value: 'NJ'},
    {value: 'NM'},
    {value: 'NY'},
    {value: 'NC'},
    {value: 'ND'},
    {value: 'OH'},
    {value: 'OK'},
    {value: 'OR'},
    {value: 'PA'},
    {value: 'RI'},
    {value: 'SC'},
    {value: 'SD'},
    {value: 'TN'},
    {value: 'TX'},
    {value: 'UT'},
    {value: 'VT'},
    {value: 'VA'},
    {value: 'WA'},
    {value: 'WV'},
    {value: 'WI'},
    {value: 'WY'},
    {value: 'AA'},
    {value: 'AE'},
    {value: 'AP'}];

  ngOnInit(): void {
    this.options = this.customOptions || this.options;
  }

  getErrorText() {
    const firstError = Object.keys(this.control.errors)[0];
    if (this.errorMessages && this.errorMessages[firstError] !== undefined) {
      return this.errorMessages[firstError];
    } else if (this.requiredErrorMessage) {
      return this.requiredErrorMessage.replace('{}', this.labelText);
    } else if (this.global.requiredMessageTemplateStateSelect) {
      return this.global.requiredMessageTemplateStateSelect.replace('{}', this.labelText);
    } else {
      return `Please select your ${this.labelText}`;
    }
  }
}
