const ADDRESS = 'address';

export function isFormEmpty(object: object) {
    return !Object.keys(object).some((key) => {
      const value = object[key];
      if(key === ADDRESS) {
        return !isFormEmpty(value);
      } else {
        return value !== null && value !== '' && value !== undefined && value !== false;
      }
    });
}