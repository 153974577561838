import {Directive, HostListener} from '@angular/core';
import {environment} from '../../../../../environments/environment';
import {TealiumService} from '../../tracking/analytics/tealium/tealium.service';

@Directive({
  selector: '[lynxConfirmExit]'
})
export class ConfirmExitDirective {

  constructor(private tealiumService: TealiumService) {
  }

  @HostListener('window:beforeunload', ['$event'])
  @HostListener('window:pagehide')
  unloadNotification($event: any) {
    if (environment.shouldShowConfirmAbandonPopup) {
      $event.returnValue = true;
      this.tealiumService.link({exit_warning_impression: '1'});
    }
  }
}
