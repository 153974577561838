import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {ErrorDisplay} from '../../services/error-display';
import {filter, takeUntil} from 'rxjs/operators';
import {BaseReactiveFieldComponent} from '../base-reactive-field/base-reactive-field-component';
import {Global} from '../../global';
import {Subject} from 'rxjs';

@Component({
  selector: 'reactive-text-field',
  templateUrl: './reactive-text-field.component.html',
  styleUrls: ['./reactive-text-field.component.scss'],
})
export class ReactiveTextFieldComponent extends BaseReactiveFieldComponent implements OnInit, OnDestroy {

  @Input() fieldId: string;
  @Input() explanatoryText: string;
  @Input() maxlength: number;
  @Input() autocomplete = 'on';
  @Input() inputType = 'text';
  @Input() readOnly = false;
  @Input() submitted: boolean;
  // [ -~] matches all ASCII printable characters
  @Input() pattern = '[ -~]*';
  @Input() textMask = {};
  @Input() showLabel = true;
  @Input() inputMin: number = undefined;
  @Input() inputMax: number = undefined;
  @Output() blur: EventEmitter<any> = new EventEmitter();
  @Output() ngModelChange: EventEmitter<any> = new EventEmitter();

  private destroy$: Subject<void> = new Subject<void>();

  constructor(
    public errorDisplay: ErrorDisplay, protected global: Global
  ) {
    super(global);
  }

  ngOnInit(): void {
    if (this.control) {
      if (this.inputType === 'tel') {
        this.control.valueChanges
          .pipe(takeUntil(this.destroy$), filter(x => !!x))
          .subscribe(x => this.sanitizeValue(x))
      } else if (this.inputType === 'number') {
        this.control.valueChanges
          .pipe(takeUntil(this.destroy$), filter(x => (!!x || x === '')))
          .subscribe(x => this.sanitizeValue(x))
      }
    }
  }

  private sanitizeValue(x: string) {
    let sanitizedValue = x.replace(/\D/g, '');
    this.control.setValue(sanitizedValue, {emitEvent: false});
  }

  onBlur($event) {
    this.blur.emit($event)
  }

  onChanges($event) {
    this.ngModelChange.emit($event);
  }

  ngOnDestroy() {
    this.destroy$.next();
  }
}
