import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FieldsToDisplay, PromoConfigurationService} from '../../services/promo-configuration.service';
import {CommercialApplicationModel} from '../../models/commercial/commercial-application-model';
import {Subscription} from 'rxjs';
import {FormControl, FormGroup} from '@angular/forms';

@Component({
  selector: 'lynx-substitute-W9',
  templateUrl: './substitute-W9.component.html',
  styleUrls: ['./substitute-W9.component.scss']
})
export class SubstituteW9Component implements OnInit {

  @Input() submitted: boolean = false;
  @Output() substituteW9FormGroupCreated = new EventEmitter<FormGroup>();

  showSubstituteW9: boolean;
  fieldsToDisplay: FieldsToDisplay = {};
  private promoSubscription: Subscription;
  backupWithholding: FormControl;
  substituteW9AgreementText: string;
  substituteW9FormGroup: FormGroup = new FormGroup({});

  constructor(
    private promoConfigurationService: PromoConfigurationService,
    private ccApp: CommercialApplicationModel
  ) { }

  ngOnInit() {
    this.substituteW9AgreementText = 'Certification instructions. YOU MUST CHECK THIS BOX if you have been notified by the IRS that you are currently subject to backup withholding because you have failed to report all interest and dividends on your tax return.';

    this.promoSubscription = this.promoConfigurationService.promoConfiguration.subscribe((promoConfig) => {
      this.fieldsToDisplay = promoConfig.fieldsToDisplay;
      this.showSubstituteW9 = (!!promoConfig.fieldsToDisplay.substituteW9
        && promoConfig.fieldsToDisplay.substituteW9.required);
    });
    this.backupWithholding = new FormControl<boolean|null>(this.ccApp.backupWithholding, []);
    this.substituteW9FormGroup.addControl('backupWithholding', this.backupWithholding);

    this.substituteW9FormGroupCreated.emit(this.substituteW9FormGroup);
  }

  ngOnDestroy() {
    if (this.promoSubscription) {
      this.promoSubscription.unsubscribe();
    }
  }

}
