import {SocureEnvironment} from './socure-environment';

export const environment = {
  production: false,
  lynxApi: '/lynx/api/v1',
  lynxApiV2: '/lynx/api/v2',
  adminApi: '',
  tealiumEnvironment: 'dev',
  socureEnvironment: SocureEnvironment.SANDBOX,
  timeoutWarning: 1000 * 4 * 60,
  employeeLedTimeoutWarning: 1000 * 11 * 60,
  dynamicAppURL: 'https://wwd.mybusinessbankcard.com/OnlineApp/apply.do?pageName=mkt&destination=814JZR2G35LRN&agent=001&sub=021',
  shouldShowExitConfirmation: true,
  shouldShowConfirmAbandonPopup: true,
  logoutUrl: '/logout',
  adobeLaunchURL: 'https://assets.adobedtm.com/aaee7fd81fbc/7ea67bec12a3/launch-f59cb88c69bf-development.min.js'
};
