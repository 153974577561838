import {Component, Input, OnChanges, OnDestroy, OnInit, Renderer2, SimpleChanges} from '@angular/core';
import {DEFAULT_TIMEOUT_ROUTE, SessionTimeoutService} from '../shared/services/sessionTimeout.service';
import {ModalService} from '../shared/services/modalService.service';
import {environment} from '../../../environments/environment';
import {SessionService} from '../shared/services/session.service';
import {Subscription} from 'rxjs';
import {PromoConfigurationService} from '../shared/services/promo-configuration.service';

@Component({selector: 'times-out', template: ''})
export class TimesOutComponent implements OnInit, OnChanges, OnDestroy {

  @Input() onTimeoutNavigateTo: string;
  @Input() markApplicationAsNew = false;

  unlisteners: (() => void)[];
  private sessionTimeoutServiceSubscription: Subscription;

  constructor(protected renderer: Renderer2,
              protected sessionTimeoutService: SessionTimeoutService,
              protected sessionService: SessionService,
              protected modalService: ModalService
    ) {
    this.unlisteners = [];
  }

  ngOnInit() {
    this.sessionTimeoutServiceSubscription = this.sessionTimeoutService.startListeners.subscribe(() => {
      this.setupListeners();
    });
    this.resetSessionTimeout();

    if (this.markApplicationAsNew){
      this.sessionTimeoutService.setApplicationStatusToNew();
    }

    if (this.onTimeoutNavigateTo){
      this.sessionTimeoutService.attemptToSetNavigationPage(this.onTimeoutNavigateTo);
    }
  }

  private setupListeners() {
    this.unlisteners = ['mousemove', 'keydown', 'scroll', 'mousedown'].map(event =>
      this.renderer.listen('document', event, this.resetSessionTimeout.bind(this)));
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.onTimeoutNavigateTo) {
      const onTimeoutNavigateTo = changes.onTimeoutNavigateTo.currentValue;
      this.sessionTimeoutService.attemptToSetNavigationPage(onTimeoutNavigateTo);
    }
  }

  ngOnDestroy(): void {
    this.sessionTimeoutService.abort();
    this.unlisteners.forEach(f => f());

    if(this.sessionTimeoutServiceSubscription) {
      this.sessionTimeoutServiceSubscription.unsubscribe();
    }
  }

  resetSessionTimeout() {
    this.sessionTimeoutService.restart(() => {
      this.unlisteners.forEach(f => f());
      this.modalService.openModal('sessionTimeout');
    });
  }
}
