import {AbstractControl} from '@angular/forms';
import {Directive, Input} from '@angular/core';
import {Global} from '../../global';

@Directive()
export class BaseReactiveFieldComponent {
  @Input() control: AbstractControl;
  @Input() errorMessages: object;
  // Used for local overrides only - see global
  @Input() requiredErrorMessage: string;
  @Input() labelText: string;

  constructor(protected global: Global) {}

  getPatternErrorMessage() {
    return 'No special characters allowed';
  }

  getMinimumLengthMessage(length: number) {
    return `Please enter at least ${length} characters`;
  }

  getMaximumLengthMessage(length: number) {
    return `Please enter at most ${length} characters`;
  }

  getErrorText() {
    const firstError = Object.keys(this.control.errors)[0];
    if (this.errorMessages && this.errorMessages[firstError] !== undefined) {
      return this.errorMessages[firstError];
    } else if (firstError === 'pattern') {
      return this.getPatternErrorMessage();
    } else if (firstError === 'minlength') {
      return this.getMinimumLengthMessage(this.control.errors.minlength.requiredLength);
    } else if (firstError === 'maxlength') {
      return this.getMaximumLengthMessage(this.control.errors.maxlength.requiredLength);
    } else if (this.requiredErrorMessage) {
      return this.requiredErrorMessage.replace('{}', this.labelText);
    } else if (this.global.requiredMessageTemplate) {
      return this.global.requiredMessageTemplate.replace('{}', this.labelText);
    } else {
      return `Please enter your ${this.labelText}`;
    }
  }
}
