import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'lynx-label-value',
  templateUrl: './label-value.component.html',
  styleUrls: ['./label-value.component.scss']
})
export class LabelValueComponent implements OnInit {
  @Input() label: string;
  @Input() value: string;
  @Input() valueId: string;

  constructor() { }

  ngOnInit() {
  }

}
