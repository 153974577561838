import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../../environments/environment';

@Injectable({providedIn: 'root'})
export class HttpService {

  constructor(private _http: HttpClient) {
  }

  public get(uri: string, opts?: any): Observable<any> {
    uri = this.normalizeUri(uri);
    return this._http.get(`${environment.lynxApi}/lynx${uri}`, opts);
  }

  public post(uri: string, data: any, opts?: any): Observable<any> {
    uri = this.normalizeUri(uri);
    return this._http.post(`${environment.lynxApi}/lynx${uri}`, data, opts);
  }

  public postV2(uri: string, data: any, opts?: any): Observable<any> {
    uri = this.normalizeUri(uri);
    return this._http.post(`${environment.lynxApiV2}/lynx${uri}`, data, opts);
  }

  private normalizeUri(uri: string) {
    if (uri.indexOf('/') !== 0) {
      return  '/' + uri;
    }
    return uri;
  }
}
