import {Injectable} from '@angular/core';
import {Action} from './action-determiner';
import {Observable, Subject} from 'rxjs';

@Injectable({providedIn: 'root'})
export class ActionDispatcher {

    private actionEmitter: Subject<Action> = new Subject();
    public $actions: Observable<Action> = this.actionEmitter.asObservable();

    dispatchAction(action: Action) {
        this.actionEmitter.next(action);
    }
}