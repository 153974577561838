import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormControl, FormGroup, ValidatorFn, Validators} from '@angular/forms';
import {CommercialApplicationModel} from '../../models/commercial/commercial-application-model';
import {FieldsToDisplay, PromoField} from '../../services/promo-configuration.service';
import {RequiredNonBlankValidator} from '../../validators/required-non-blank.validator';

@Component({
  selector: 'lynx-pb-contact-fields',
  templateUrl: './pb-contact-fields.component.html',
  styleUrls: ['./pb-contact-fields.component.scss']
})
export class PbContactFieldsComponent implements OnInit {
  @Input() ccApp: CommercialApplicationModel;
  @Input() fieldsToDisplay: FieldsToDisplay;
  @Input() submitted = false;

  @Output() pbContactFieldsCreated = new EventEmitter<FormGroup>();

  shouldDisplayPbContactFields = true;

  pbContactFormGroup: FormGroup = new FormGroup({});
  nameFormControl: FormControl;
  emailAddressFormControl: FormControl;
  phoneNumberFormControl: FormControl;

  constructor() { }

  ngOnInit() {
    this.shouldDisplayPbContactFields = this.shouldDisplayComponent();
    if (this.shouldDisplayPbContactFields) {
      const ftd = this.fieldsToDisplay;

      if (ftd.bankerInformationName){
        const name = this.ccApp.bankerInformation != null ? this.ccApp.bankerInformation.name : '';
        this.nameFormControl = new FormControl<string|null>(name, this.fieldValidations(ftd.bankerInformationName));
        this.pbContactFormGroup.addControl('name', this.nameFormControl);
      }
      if (ftd.bankerInformationEmailAddress) {
        const email = this.ccApp.bankerInformation != null ? this.ccApp.bankerInformation.emailAddress : '';
        this.emailAddressFormControl = new FormControl<string|null>(email,
          [Validators.email, ...this.fieldValidations(ftd.bankerInformationEmailAddress)]);
        this.pbContactFormGroup.addControl('emailAddress', this.emailAddressFormControl);
      }
      if (ftd.bankerInformationPhoneNumber) {
        const phone = this.ccApp.bankerInformation != null ? this.ccApp.bankerInformation.phoneNumber : '';
        this.phoneNumberFormControl = new FormControl<string|null>(phone,
          [Validators.minLength(10), ...this.fieldValidations(ftd.bankerInformationPhoneNumber)]);
        this.pbContactFormGroup.addControl('phoneNumber', this.phoneNumberFormControl);
      }

      this.pbContactFieldsCreated.emit(this.pbContactFormGroup);
    }
  }

  private shouldDisplayComponent() : boolean {
    if (!this.fieldsToDisplay) {
      return false;
    }
    return (!!this.fieldsToDisplay.bankerInformationName)
      || (!!this.fieldsToDisplay.bankerInformationPhoneNumber)
      || (!!this.fieldsToDisplay.bankerInformationEmailAddress);
  }

  private fieldValidations(promoField:PromoField): ValidatorFn[] {
    if (!promoField){
      return [];
    }
    let validators : ValidatorFn[] = [];
    if (promoField.required){
      validators.push(RequiredNonBlankValidator.valid());
    }
    return validators;
  }

}
