import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {NgForm} from '@angular/forms';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {BaseForm} from '../../shared/components/base-form/base-form';

@Component({
  selector: 'lynx-starter-prefill',
  templateUrl: './consumer-starter.component.html',
  styleUrls: ['./consumer-starter.component.scss']
})
export class ConsumerStarterComponent implements OnInit {
  @ViewChild('form') form: NgForm;
  @ViewChild('htmlForm') domForm: ElementRef;

  prefillUrl: string = `${environment.lynxApi}/prefilled-application`;

  request: {
    shortCode: string
    agentBank: string
    subAgent: string
    rewardsNumber: string
    employeeId: string
    referralId: string
    salesId: string
    bankBranch: string
    referringEmployeeId: string
    firstName: string
    middleInitial: string
    lastName: string
    thirdPartyCustomerId: string
    customerLoginId: string
    customerSessionId: string
    partnerMarketingCode: string
  } = {
    shortCode: null,
    agentBank: null,
    subAgent: null,
    rewardsNumber: null,
    employeeId: null,
    referralId: null,
    salesId: null,
    bankBranch: null,
    referringEmployeeId: null,
    firstName: null,
    middleInitial: null,
    lastName: null,
    thirdPartyCustomerId: null,
    customerLoginId: null,
    customerSessionId: null,
    partnerMarketingCode: null,
  };
  errors: string[] = [];

  constructor(private httpClient: HttpClient) {}

  ngOnInit() {
  }

  prefill() {
    this.errors.length = 0;
    if (!this.form.valid) {
      BaseForm.handleScrollToInvalid(this.domForm);
      return;
    }

    this.httpClient.post(this.prefillUrl, this.request)
      .subscribe((resp: Object) => {
        const applicationUrl = resp['applicationUrl'];
        if (applicationUrl) {
          this.navigateToPrefillUrl(applicationUrl);
        }
      }, (error) => {
        const errorResponse = error.error;
        if (errorResponse && errorResponse.errors) {
          errorResponse.errors.forEach(errorMessage => {
            let errorLine: string = `${errorMessage.fieldName}: `;
            let ctr = 0;
            if(errorMessage.errorMessages) {
              errorMessage.errorMessages.forEach(errorMsg => {
                errorLine += errorMsg;
                if (ctr != errorMessage.errorMessages.length - 1) {
                  errorLine += ', ';
                }
                ctr++;
              });
            }
            this.errors.push(errorLine);
          });
        } else {
          this.errors.push(`An error has occurred: ${error.status}, Please check your ShortCode`);
        }
      });
  }

  navigateToPrefillUrl = (applicationUrl: string) => {
    window.location.href = applicationUrl;
  }
}
