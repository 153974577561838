import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {createNumberMask} from 'text-mask-addons/dist/textMaskAddons';
import {ErrorDisplay} from '../../services/error-display';
import {BaseReactiveFieldComponent} from '../base-reactive-field/base-reactive-field-component';
import {Global} from '../../global';
import {Subscription} from 'rxjs';

@Component({
  selector: 'currency-field',
  templateUrl: './currency-field.component.html',
  styleUrls: ['./currency-field.component.scss']
})
export class CurrencyFieldComponent extends BaseReactiveFieldComponent implements OnInit, OnChanges, OnDestroy {
  textMask = {};

  @Input() fieldId: string;
  @Input() explanatoryText: string;
  @Input() submitted: boolean;
  @Input() maxlength: number;
  @Input() readOnly = false;
  private currencyControlSubscription: Subscription;
  private numberMaskConfig: {};

  constructor(public errorDisplay: ErrorDisplay, protected global: Global) {
    super(global);
  }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.maxlength) {
      this.numberMaskConfig = {
        allowDecimal: true,
        decimalLimit: 2,
        integerLimit: changes.maxlength.currentValue,
        allowLeadingZeroes: false
      };

      this.textMask = {pipe: this.currencyCentsPipe, mask: createNumberMask(this.numberMaskConfig),
        guide: false};
    }
    if (changes.disabled) {
      changes.disabled.currentValue ? this.control.disable() : this.control.enable();
    }
  }

  getErrorText() {
    if (this.control.hasError('wholeDollar')) {
      return 'Please enter a whole dollar amount';
    }
    return super.getErrorText();
  }

  currencyCentsPipe = (conformedValue, config) => {
    const rawValuePeriodMatches = config.rawValue.match(/\./g) || [];

    if(config.rawValue != '') {
      if (config.previousConformedValue && rawValuePeriodMatches.length !== 1 && config.rawValue.length > 1) {
        if (rawValuePeriodMatches.length > 1) {
          // if two period are in a row
          let returnValue = config.previousConformedValue;
          if(config.rawValue.match(/\.\.$/g)) {
            returnValue = conformedValue + ' ';
          }
          return {
            value: returnValue
          };
        }
      }
    }

    if(config.previousConformedValue && conformedValue &&
      config.previousConformedValue.length > conformedValue.length && config.previousConformedValue.match(/\.\d$/)) {
      return conformedValue + '.';
    }

    if (config.rawValue.match(/^\$0[1-9]/) || config.rawValue.startsWith('0$')) {
      return '$' + config.rawValue.substring(2);
    }

    return conformedValue;
  }

  ngOnDestroy(): void {
    if(this.currencyControlSubscription) {
      this.currencyControlSubscription.unsubscribe();
    }
  }
}
