import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProcessingComponentService {

  private config: BehaviorSubject<ProcessingConfig> =
    new BehaviorSubject({mainMessage: defaultProcessingText});
  public config$: Observable<ProcessingConfig> = this.config.asObservable();

  overrideProcessingText(newText: string) {
    this.config.next({...this.config.value, ...{mainMessage: newText}});
  };

}
const defaultProcessingText = 'Nice work, we\'re processing your application.';
export type ProcessingConfig = { mainMessage: string};