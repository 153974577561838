import {Component, Input, OnInit, Renderer2} from '@angular/core';
import {Router} from '@angular/router';
import {BaseModalComponent} from '../../../shared/components/modal/base-modal.component';
import {environment} from '../../../../../environments/environment';
import {ModalService} from '../../../shared/services/modalService.service';
import {PromoConfigurationService} from '../../../shared/services/promo-configuration.service';

@Component({
  selector: 'print-soct',
  templateUrl: './print-soct.component.html',
  styleUrls: ['../../../shared/components/modal/modal.scss', './print-soct.component.scss']
})
export class PrintSoctComponent extends BaseModalComponent implements OnInit {

    @Input() navigationRouteOnFinish = '/review';

    printSoctUrl;
    printed: boolean = false;

    constructor(
      modalService: ModalService,
        private renderer: Renderer2,
        private router: Router,
        private promoConfigurtion: PromoConfigurationService
    ) {
        super(renderer, modalService);
    }

    ngOnInit() {
        this.printSoctUrl =`${environment.lynxApi}/lynx/soct/${this.promoConfigurtion.shortCode}`
    }

    close() {
        this.modalService.closeModal();
    }

}
