import {Component, OnInit, Renderer2, ViewChild, ViewContainerRef} from '@angular/core';
import {Subscription} from 'rxjs';
import {ModalService} from '../../../services/modalService.service';

@Component({
  selector: 'shared-modal-factory',
  templateUrl: './shared-modal-factory.component.html',
  styleUrls: ['./shared-modal-factory.component.scss']
})
export class SharedModalFactoryComponent implements OnInit {
  private modalServiceSubscription: Subscription;
  private currentModal: string;
  termsErrorMessage: string;

  @ViewChild('modalContainer', { read: ViewContainerRef }) container;

  constructor(
    private modalService: ModalService,
    private renderer: Renderer2
  ) {
  }

  ngOnInit() {
    this.modalServiceSubscription = this.modalService.showModal$.subscribe((modalData) => {
      this.currentModal = modalData.modalName;

      if (this.currentModal === 'termsError') {
        this.termsErrorMessage = modalData.args;
      }

      this.unFocusToRemoveVirtualKeyboard();
      this.setTabbing();
    });
  }

  private setTabbing() {
    const tabbableElements = 'a,input,button,select,img';
    let index = this.currentModal ? '-1' : '0';
    const elements = document.querySelectorAll(tabbableElements);
    for (var i = 0; i < elements.length; i++) {
      this.renderer.setAttribute(elements[i], 'tabindex', index);
    }
  }

  private unFocusToRemoveVirtualKeyboard() {
    const elem: any = document.activeElement;
    if (elem && elem.blur) {
      elem.blur();
    }
  }

  shouldShowModal(modal: string): boolean {
    return modal === this.currentModal;
  }
}
