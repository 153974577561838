import {Pipe, PipeTransform} from '@angular/core';

@Pipe({name: 'zipCode'})
export class ZipCodePipe implements PipeTransform {
  transform(zipCode: string) {
    if (!zipCode || this.alreadyFormatted(zipCode)) {
      return zipCode;
    } else if (zipCode.indexOf('-') > -1) {
      return zipCode.replace('-', '\u2011');
    } else {
      return zipCode.substring(0, 5) + '\u2011' + zipCode.substring(5);
    }

  }

  private alreadyFormatted(zipCode: string) {
    return zipCode[5] === '\u2011' || zipCode.length === 5;
  }
}
