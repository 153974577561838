import {Inject, Injectable} from '@angular/core';
import {environment} from '../../../../../../environments/environment';
import {BehaviorSubject, ReplaySubject, zip} from 'rxjs';
import {ScriptInjectorService} from '../common/script-injector.service';
import {PromoConfigurationService} from '../../../services/promo-configuration.service';
import {filter, map, take} from 'rxjs/operators';
import {Router} from '@angular/router';
import {SessionService} from "../../../services/session.service";

@Injectable({
  providedIn: 'root'
})
export class AdobeLaunchService {
  private readonly isScriptLoaded$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private readonly hasApplicationStarted$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private readonly views$: ReplaySubject<any> = new ReplaySubject<any>();
  private readonly applicationStart$: BehaviorSubject<string> = new BehaviorSubject<string>(null);


  constructor(injector: ScriptInjectorService, @Inject('Window') private _window, private router: Router,
              private promoService: PromoConfigurationService, private sessionService: SessionService) {
    injector.injectAndLoadScript(
      environment.adobeLaunchURL
    ).then(() => {
      _window.adobeDataLayer = _window.adobeDataLayer || [];
      this.isScriptLoaded$.next(true);
    });

    const lynxProductConfiguration$ = this.promoService.promoConfiguration.pipe(
      filter(config => {
        return !!config && !!config.adobeLaunchConfiguration;
      }),
      map(config => {
        return config.adobeLaunchConfiguration;
      }),
      filter(config => {
        return !!config && Object.keys(config).length > 0;
      }),
      take(1));

    lynxProductConfiguration$.subscribe(c => {
      this.views$.subscribe((v) => {
        this.view(v, c);
      });
    });
    zip(
      this.isScriptLoaded$.pipe(take(1)),
      this.hasApplicationStarted$.pipe(filter(started => !started), take(1)),
      lynxProductConfiguration$.pipe(take(1)),
      this.applicationStart$.pipe(filter(s => !!s)).pipe(take(1))
    ).pipe(take(1)).subscribe(([, , , entry]) => {
      this._window._satellite && this._window._satellite.track('lynx_app_start', {entry});
      this.hasApplicationStarted$.next(true);
    });
  }

  /*
    Track event, but wait until we have the promo config loaded so all promo-specific attributes (app type, agent bank,
    ect) get populated with the event
   */
  trackDefer(options: AdobeLaunchOptions) {
    this.views$.next({...options});
  }

  /*
    Track event and send to Adobe now and assume the promo config has not been loaded. Event will not associate with
    a particular promo code or partner or app type.
  */
  trackNow(options: AdobeLaunchOptions) {
    this.view({...options}, {});
  }

  trackStart(entry: string) {
    this.applicationStart$.next(entry);
  }


  private view(viewInfo: AdobeLaunchOptions, config: any) {
    const {application_submit, application_id, page_name, ...allOtherViewInfo} = viewInfo;

    const newData = {
      ...allOtherViewInfo,
      event: viewInfo.page_name,
      page: {
        pageInfo: {
          pageTitle: this._window.document.title,
          pageURL: this._window.location.href,
          path: this.router.url,
          pageName: page_name,
          pageHash: this._window.location.hash,
          type: 'Credit Card Applications'
        }
      },
      application: {
        submit: (application_submit ? 'submit' : undefined),
        id: (application_id ? application_id : undefined),
      },
      session: {
        lynx_session_id: this.sessionService.getLynxSessionId(),
        multi_factor_auth_session_id: this.sessionService.getMfaSessionId(),
      },
      ...config
    };

    this.isScriptLoaded$.pipe(filter(v => v === true))
      .subscribe(() => {
        this._window.adobeDataLayer.push(newData);
      });
  }
}

interface AdobeLaunchOptions {
  page_name?: string;
  application_submit?: any;
  application_id?: any;

  [key: string]: any;
}