import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {PromoConfigurationService, PromoStatus} from '../../services/promo-configuration.service';
import {HeaderService} from '../../services/header.service';
import {ActivatedRoute, NavigationStart, Router} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../../environments/environment';
import {Subscription} from 'rxjs';
import {ApplicationIdService} from '../../services/application-id.service';

@Component({
  selector: 'page-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})

export class HeaderComponent implements OnInit, OnDestroy {
  logoUrl: string;
  productName: string;
  title: string;
  byline: string;
  inNetworkIndicator: boolean;
  status: PromoStatus;
  private queryParamSubscriber: Subscription;
  private promoSubscription: Subscription;
  private appIdSubscription: Subscription;

  promoCode: string;
  agentBankCode: string;
  subAgentBankCode: string;
  applicationId: string;
  isApplicationIdVisible: boolean;

  constructor(
    @Inject(ActivatedRoute) private activatedRoute: ActivatedRoute,
    private promoConfigurationService: PromoConfigurationService,
    private applicationIdService: ApplicationIdService,
    private headerService: HeaderService,
    private router: Router,
    private http: HttpClient
  ) {
  }

  ngOnInit(): void {
    this.promoSubscription = this.promoConfigurationService.promoConfiguration.subscribe((promoConfiguration) => {
      this.logoUrl = promoConfiguration.logoUrl;
      this.inNetworkIndicator = promoConfiguration.inNetworkIndicator;
      const proof = promoConfiguration.proof;
      this.promoCode = this.parsePromoCode(proof);
      this.agentBankCode = this.parseAgentBankCode(proof);
      this.subAgentBankCode= this.parseSubAgentBankCode(proof);
      this.status = promoConfiguration.status;
    });

    this.appIdSubscription = this.applicationIdService.applicationId$.subscribe(appId =>{
      if(appId !== undefined) {
        this.isApplicationIdVisible = true;
      }
      this.applicationId = appId;
    });

    if (this.logoUrl === '') {
      this.queryParamSubscriber = this.activatedRoute.queryParamMap.subscribe((paramMap) => {
        if(!paramMap.get('promo') && paramMap.get('agent')) {
          const logoId = paramMap.get('agent').toUpperCase();
          this.fetchLogoUrl(logoId);
        }
      });
    }

    this.headerService.getTitleChanges().subscribe(title => this.title = title);
    this.headerService.getByLineChanges().subscribe(byline => this.byline = byline);
    this.queryParamSubscriber = this.router.events.subscribe((e) => {
      if (e instanceof NavigationStart) {
        this.headerService.setTitle('');
        this.headerService.setByLine('');
      }
    });
  }

  private parsePromoCode(proof:string) : string {
    return proof.substring(3, 9)
  }
  private parseAgentBankCode(proof:string) : string {
    return proof.substring(9, 12)
  }
  private parseSubAgentBankCode(proof:string) : string {
    return proof.substring(12, 15)
  }

  ngOnDestroy() {
    if (this.promoSubscription) {
      this.promoSubscription.unsubscribe();
    }
    if (this.appIdSubscription) {
      this.appIdSubscription.unsubscribe();
    }
    if (this.queryParamSubscriber) {
      this.queryParamSubscriber.unsubscribe();
    }
  }

  private fetchLogoUrl(logo: string) {
    this.http
      .get(environment.lynxApi + '/lynx/direct-mail/logo/' + logo, {responseType: 'text'})
      .subscribe((response: string) => {
        this.logoUrl = response;
        }
      );
  }
}
