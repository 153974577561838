import {Injectable} from '@angular/core';
import {BalanceTransfer} from './balance-transfer';
import {ApplicationModel} from './application-model';
import {PhoneNumberType} from './phone-number/phone-number-models';

@Injectable({providedIn: 'root'})
export class ConsumerApplicationModel extends ApplicationModel {
  // TODO shared, move to ApplicationModel
  returnRelativePath?: string;

  firstName?: string;
  middleInitial?: string;
  lastName?: string;
  emailAddress?: string;
  primaryPhoneNumber: string;
  primaryPhoneNumberType: PhoneNumberType;
  businessPhoneNumber?: string;
  businessPhoneNumberType: PhoneNumberType;
  residentialAddress?: string;
  unit?: string;
  city?: string;
  state?: string;
  zipCode?: string;

  mailingAddress?: string;
  mailingUnit?: string;
  mailingCity?: string;
  mailingState?: string;
  mailingZipCode?: string;

  totalAnnualIncome?: number;
  presentEmployer?: string;
  monthlyRentMortgage?: number;
  homeOwnership?: string;
  yearsAtAddress?: number;
  collegeStudent?: boolean;

  ssn?: string;
  dob?: string;
  mothersMaidenName?: string;

  authorizedUser?: {
    firstName?: string;
    middleInitial?: string;
    lastName?: string;
    dob?: string;
  };

  agreeToTerms?: boolean;
  plasticSuffix?: string;

  customerIdIssuanceState?: string;
  customerIdType?: string;
  customerIdNumber?: string;

  checkFraudData: boolean;
  checkingAccountBalance?: number;
  savingsAccountBalance?: number;
  investmentAccountBalance?: number;
  prequalId: string;
  printerCode: string;

  securedDepositAmount?: number;
  securedDepositAccountType?: string;
  securedDepositRoutingNumber?: string;
  securedDepositVerifyRoutingNumber?: string;
  securedDepositAccountNumber?: string;
  securedDepositVerifyAccountNumber?: string;

  walletType?: string;

  ucl?: {
    requestedTerm?: number;
    requestedAmount?: number;
    occupationalStatus?: string;
    titleAtEmployer?: string;
    yearsAtEmployer?: number;
    additionalIncomePerMonth?: number;
    usResidentStatus?: string;
  };

  constructor() {
    super();
    this.setInitialValues();
  }

  protected setInitialValues() {
    this.balanceTransfers = [new BalanceTransfer(), new BalanceTransfer(), new BalanceTransfer()];
    this.authorizedUser = {};
    this.ucl = {};
  }
}
