import {Component, Input, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup} from "@angular/forms";
import {SelectOption} from '../../models/select-option';

@Component({
  selector: 'radio-group',
  templateUrl: './radio-group.component.html',
  styleUrls: ['./radio-group.component.scss']
})

export class RadioGroupComponent implements OnInit {
  @Input('options') options : SelectOption[];
  @Input() nonblank: boolean = true;
  @Input() required: boolean = true;
  @Input() control: FormControl;
  @Input('labelText') labelText: string;
  @Input() fieldId: string;
  @Input() shouldShowError: boolean;
  @Input() fieldName: string;
  @Input() submitted: boolean;
  formGroup: FormGroup;

  ngOnInit(): void {
    let formBuilder = new FormBuilder();
    this.formGroup = formBuilder.group({
      [this.fieldName]: this.control
    });
  }
}
