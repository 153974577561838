import {Inject, Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {DEVICE_RISK_SCRIPT_SOURCE, DEVICE_SCRIPT_SOURCE, SOCURE_MODULE} from './socure-types';
import {filter} from 'rxjs/operators';
import {AvailableTrackingService, TrackingServiceConfig} from '../third-party/analytics-tracking-types';
import {SocureScriptInjectorService} from '../common/socure-script-injector.service';
import {AnalyticsTrackingConfigService} from '../third-party/analytics-tracking-config.service';
import {SocureDeviceFactory} from './socure-device-factory';


enum LoadingState {
  NOT_STARTED,
  IN_PROGRESS,
  COMPLETE,
  FAILED
}
const isNotNull = (s: any) => s != null;
const isSocureConfig = (config: TrackingServiceConfig) => config.serviceName === AvailableTrackingService.SOCURE;

@Injectable({
  providedIn: 'root'
})
export class SocureDeviceTrackingService {
  private state: BehaviorSubject<LoadingState> = new BehaviorSubject<LoadingState>(LoadingState.NOT_STARTED);
  private sessionId: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(null);
  private trackingConfigService: BehaviorSubject<TrackingServiceConfig> = new BehaviorSubject<TrackingServiceConfig>(SocureDeviceTrackingService.defaultTrackingConfigService());

  constructor(
    @Inject('Window') private _window,
    private injector: SocureScriptInjectorService,
    private analyticsTrackingService: AnalyticsTrackingConfigService,
    private factory: SocureDeviceFactory
  ) {

  }

  /**
   * Loads tracking Javascript (if not already loaded), initiates Socure device tracking,
   * and resolves promise with the Socure session ID. Rejects promise if error encountered during
   * any phase.
   */
  async track(): Promise<string> {
    switch (this.state.getValue()) {
      case LoadingState.IN_PROGRESS:
      case LoadingState.COMPLETE:
      case LoadingState.FAILED:
        return await this.sessionId.pipe(filter(isNotNull)).toPromise();

      case LoadingState.NOT_STARTED: {
        this.state.next(LoadingState.IN_PROGRESS);
        await this.analyticsTrackingService.getTrackingServiceConfigs().toPromise()
          .then(((configs: TrackingServiceConfig[]) => {
            if (!!configs && configs.length > 0) {
              this.trackingConfigService.next(configs.find(config => isSocureConfig(config)));
            }
          }))
        let module = this.getModule(this.trackingConfigService.getValue());
        let deviceManager = this.factory.getDeviceManager(module);
        const script = (module === SOCURE_MODULE.DEVICE_RISK) ? DEVICE_RISK_SCRIPT_SOURCE : DEVICE_SCRIPT_SOURCE;
        return this.injector.injectAndLoadScript(script)
          .then(() => deviceManager.runDeviceManagerAndReturnSessionId(this._window))
          .then((fetchedSessionId) => this.setSessionId(fetchedSessionId))
          .catch(e => this.handleError(e));
      }
      default:
        throw new Error(`unrecognized state ${this.state.getValue()}`);
    }
  }

  /**
   * Gets the current value of session id, whether loaded or not, ignoring any errors
   * that we may have encountered when fetching the id. `null` is returned when session id is
   * not yet loaded or an error occurred during loading.
   */
  getSessionIdIfAvailable(): string | null {
    try {
      return this.sessionId.getValue();
    } catch (e) {
      return null;
    }
  }

  private setSessionId(fetchedSessionId: string): string {
    this.sessionId.next(fetchedSessionId);
    this.sessionId.complete();
    this.state.next(LoadingState.COMPLETE);
    return fetchedSessionId;
  }

  private handleError(e: Error): Promise<never> {
    const error = new Error(`Failed to get Socure session id because: ${e.message}`);
    this.state.next(LoadingState.FAILED);
    this.sessionId.error(error);
    return Promise.reject(error);
  }

  private static defaultTrackingConfigService(): TrackingServiceConfig {
    return {
      serviceName: AvailableTrackingService.SOCURE,
      shouldTrack: true,
      configs: {'module': SOCURE_MODULE.DEVICE}
    }
  }

  private getModule(service: TrackingServiceConfig): SOCURE_MODULE {
    let newVar = !!service.configs && service.configs['module'];
    return newVar === 'DEVICE_RISK' ? SOCURE_MODULE.DEVICE_RISK : SOCURE_MODULE.DEVICE;
  }
}
