import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'lynx-temporary-credit-line',
  templateUrl: './temporary-credit-line.component.html',
  styleUrls: ['./temporary-credit-line.component.scss']
})
export class TemporaryCreditLineComponent implements OnInit {

  @Input() temporaryCreditLine: number;
  @Input() temporaryCreditLineRestrictionMessage: string;


  ngOnInit(): void {
  }

}
