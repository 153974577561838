import {Inject, Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {RESULTSTATE} from '../../../shared/models/geolocation-type';
import {ConsumerApplicationModel} from '../../../shared/models/consumer-application-model';
import {GeolocationData} from '../../../shared/models/application-model';

const options = {
    enableHighAccuracy: true,
    /**
     *   The PositionOptions.maximumAge property is a positive long value indicating the maximum age in milliseconds
     *   of a possible cached position that is acceptable to return.
     *   If set to 0, it means that the device cannot use a cached position and must attempt to retrieve the real
     *   current position
     */
    maximumAge: 0
};

/**
 *      GeolocationPositionError.code
 * 1    PERMISSION_DENIED    The acquisition of the geolocation information failed because the page didn't have the necessary permissions, for example because it is blocked by a Permissions Policy
 * 2    POSITION_UNAVAILABLE    The acquisition of the geolocation failed because at least one internal source of position returned an internal error.
 * 3    TIMEOUT    The time allowed to acquire the geolocation was reached before the information was obtained.
 */

@Injectable({providedIn: 'root'})
export class GeolocationService {
    private resultState$: BehaviorSubject<RESULTSTATE> = new BehaviorSubject<RESULTSTATE>(RESULTSTATE.NOT_REQUESTED);

    constructor(
        @Inject('Navigator') private _navigator,
        private applicationModel: ConsumerApplicationModel
    ) {
    }

    promptForPermissions() {
        if (this._navigator.geolocation) {
            this._navigator.permissions.query({name: 'geolocation'}).then((result) => {
                this.handlePermissionStatus(result);
                result.onchange = (status: PermissionStatus, ) => {
                    this.handlePermissionStatus(status);
                }
            })
        }
    }

    handlePermissionStatus = (result: PermissionStatus) => {
        if (result.state === 'denied') {
            this.setResultState(RESULTSTATE.DENIED)
            this.applicationModel.save({geolocationData: {agreeToCollectGeoLocation: false}})
        } else {
            this._navigator.geolocation.getCurrentPosition(this.handleSuccess, error => this.handleError(error, result), options)
        }
    }

    handleSuccess = (position) => {
        const geolocationData = {
            agreeToCollectGeoLocation: true,
            coordinates: {latitude: position.coords.latitude, longitude: position.coords.longitude}
        } as GeolocationData;
        this.setResultState(RESULTSTATE.GRANTED);
        this.applicationModel.save({geolocationData: geolocationData});
    }


    handleError = (err: GeolocationPositionError, result: PermissionStatus) => {
        let agreeToCollectGeoLocationOnError = (!!result && result.state) === 'granted';
        const positionError = {
            code: err.code,
            message: err.message,
            permissionDenied: err.PERMISSION_DENIED,
            positionUnavailable: err.POSITION_UNAVAILABLE,
            timeout: err.TIMEOUT
        }
        this.setResultState(RESULTSTATE.ERROR);
        this.applicationModel.save({geolocationData: {agreeToCollectGeoLocation: agreeToCollectGeoLocationOnError, geolocationPositionError: positionError}})
    }

    getResultState() {
        return this.resultState$.getValue();
    }

    setResultState(state: RESULTSTATE) {
        this.resultState$.next(state);
    }
}