import {
  DEFAULT_SOCURE_CONTEXT,
  Devicer, SigmaDeviceManager,
  SOCURE_MODULE,
  TokenFormat,
  TrackingOptions,
  TrackingResponse
} from './socure-types';
import {getSocurePublicKeyForEnvironment} from './socure-public-key';
import {environment} from '../../../../../../environments/environment';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SocureDeviceFactory {
  getDeviceManager(module: SOCURE_MODULE) {
    switch (module) {
      case SOCURE_MODULE.DEVICE_RISK:
        return new SocureSigmaDeviceManager();
      default:
        return new SocureDeviceManager();
    }
  }

}
export interface DeviceManager {
  runDeviceManagerAndReturnSessionId: (_window) => Promise<string>;
}

@Injectable({
  providedIn: 'root'
})
export class SocureDeviceManager implements DeviceManager {
  runDeviceManagerAndReturnSessionId(_window): Promise<string> {
    return new Promise((resolve, reject) => {
      const options: TrackingOptions = {
        publicKey: getSocurePublicKeyForEnvironment(environment.socureEnvironment),
        userConsent: true,
        context: DEFAULT_SOCURE_CONTEXT,
        tokenFormat: TokenFormat.Base64,
      };
      const devicer: Devicer = _window.devicer;
      devicer.run(options,
        (response: TrackingResponse) => {
          if (response.sessionId) {
            resolve(response.sessionId);
          } else {
            reject(new Error('no session id returned'));
          }
        }
      );
    });
  }
}

@Injectable({
  providedIn: 'root'
})
export class SocureSigmaDeviceManager implements DeviceManager {
  runDeviceManagerAndReturnSessionId(_window): Promise<string> {
    return new Promise((resolve, reject) => {
      if (_window.SigmaDeviceManager && _window.SigmaDeviceManager['getDeviceSessionId']) {
        const sigmaDeviceManager: SigmaDeviceManager = _window.SigmaDeviceManager;
        sigmaDeviceManager.getDeviceSessionId().then(response => {
          if (response === undefined) {
            reject(new Error('no session id returned'));
          } else {
            resolve(response);
          }
        })
      }
    })
  }
}