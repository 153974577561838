import {Pipe, PipeTransform} from '@angular/core';

@Pipe({name: 'prefixMask'})
export class PrefixMaskPipe implements PipeTransform {
  transform(premasked: string, charCount: number, charMask = 'x') {
    if (!premasked) {
      return premasked;
    }
    const prefix = charMask.repeat(charCount);
    const unmasked = premasked.substring(charCount);
    return prefix + unmasked;
  }

}