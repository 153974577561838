import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {FormControl} from '@angular/forms';
import {ErrorDisplay} from '../../services/error-display';
import {BaseReactiveFieldComponent} from '../base-reactive-field/base-reactive-field-component';
import {Global} from '../../global';

@Component({
  selector: 'reactive-select-field',
  templateUrl: './reactive-select-field.component.html',
  styleUrls: ['./reactive-select-field.component.scss']
})
export class ReactiveSelectFieldComponent extends BaseReactiveFieldComponent implements OnChanges {
  @Input() submitted = false;
  @Input() control: FormControl;
  @Input() labelText: string;
  @Input() fieldId: string;
  @Input() options: {value: any, display?: string}[];
  @Input() disabled = false;
  @Input() explanatoryText: string;
  @Input() showLabel = true;
  @Input() hasFormGroupBehavior = true;
  @Input() isGroupInvalid: boolean;
  @Input() infoText?: string;
  @Output() blur: EventEmitter<Event> = new EventEmitter();
  @Output() focusChange: EventEmitter<boolean> = new EventEmitter();
  @Output() ngModelChange: EventEmitter<any> = new EventEmitter();

  constructor(public errorDisplay: ErrorDisplay, protected global: Global) {
    super(global);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.disabled) {
      changes.disabled.currentValue ? this.control.disable() : this.control.enable();
    }
  }

  displayOption(item: {value: any, display?: string}): string {
    return item.display || item.value;
  }

  getErrorText() {
    if (!this.hasFormGroupBehavior) return null;

    const firstError = Object.keys(this.control.errors)[0];
    if (this.errorMessages && this.errorMessages[firstError] !== undefined) {
      return this.errorMessages[firstError];
    } else if (this.requiredErrorMessage) {
      return this.requiredErrorMessage.replace('{}', this.labelText);
    } else {
      return 'Please select an option';
    }
  }

  onBlur($event: any) {
    this.blur.emit($event);
  }

  onFocusChange($event) {
    this.focusChange.emit($event);
  }

  onChange($event: any) {
    this.ngModelChange.emit($event);
  }
}
