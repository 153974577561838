import {Pipe, PipeTransform} from '@angular/core';

@Pipe({name: 'phone'})
export class PhoneNumberPipe implements PipeTransform {
  transform(value: string): string {
    if (!value) return null;

    const areaCode = value.substring(0, 3);
    const subscriber = value.substring(3, 6);
    const exchange = value.substring(6);

    return '(' + areaCode + ') ' + subscriber + '-' + exchange;
  }
}
