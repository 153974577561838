import { OnInit, Directive } from '@angular/core';
import {Router} from '@angular/router';
import {ErrorDisplay} from '../../../common/services/error-display';
import {BaseForm} from '../../shared/components/base-form/base-form';
import {CommercialApplicationModel} from '../../shared/models/commercial/commercial-application-model';

@Directive()
export abstract class BaseCommercialFormComponent extends BaseForm implements OnInit {
  errorDisplay: ErrorDisplay = new ErrorDisplay();
  ccApp: CommercialApplicationModel;

  protected constructor(ccApp: CommercialApplicationModel, router: Router) {
    super(ccApp, router);
  }

  ngOnInit() {
    this.formGroup = this.form.form;
    Object.keys(this.getFormObject()).forEach(key => this[key] = this.ccApp[key]);
  }
}
