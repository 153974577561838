import {Directive, forwardRef, Input} from '@angular/core';
import {AbstractControl, NG_VALIDATORS, Validator} from '@angular/forms';

@Directive({
  selector: '[nonblank]',
  providers: [
    { provide: NG_VALIDATORS, useExisting: forwardRef(() => NonblankDirective), multi: true }
    ]
})
export class NonblankDirective implements Validator {
  @Input('nonblank') inUse: any;

  validate(control: AbstractControl): { [key: string]: any } {
    if (this.inUse) {
      if (control.value === null || control.value === undefined) {
        return {blank: true};
      }

      if ((typeof control.value === 'string') && (control.value.trim().length === 0)) {
        return {blank: true};
      }
    }
    return null;
  }
}
