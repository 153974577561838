import {Address} from './Address';
import {Owner} from './commercial/Owner';
import {objectAssign} from '../utils/object-copy';

export class ControllingParty extends Owner {
  firstName: string;
  middleInitial: string;
  lastName: string;
  address = new Address();
  dob: string;
  isUsCitizen: boolean;
  ssn: string;
  title: string;

  populate(formGroupValue: Object) {
    objectAssign(this, formGroupValue);
    if(this.ssn == '') {
      this.ssn = null;
    }
  }
}

