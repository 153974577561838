import {Injectable} from '@angular/core';
import {Action, Page} from '../action-determiner';
import {Router} from '@angular/router';
import {ActionDispatcher} from '../action-dispatcher';
import {ModalService} from '../../modalService.service';

@Injectable({providedIn: 'root'})
export class RouterActionHandler {
    constructor(private dispatcher: ActionDispatcher, private router: Router, private modalService: ModalService) {
        this.dispatcher.$actions.subscribe(this.handle);
    }

    handle = (action: Action) => {
        if (action.type === 'NAVIGATION') {
            this.modalService.closeModal();
            this.router.navigate([ConvertMetaDataToRouterInfo(action.metaData)]);
        }
    }
}

function ConvertMetaDataToRouterInfo(metaData) {
    return toRoute(metaData.which);
}

function toRoute(which: Page) {
    switch (which) {
        case('FINANCIAL'):
            return '/financial';
        case('MFA_TEXT_VERIFICATION_FAILED'):
            return '/multi-factor/failed-authorization/mobile';
        case('MFA_EMAIL_VERIFICATION_FAILED'):
            return  '/multi-factor/failed-authorization/email';
    }
}
