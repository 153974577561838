import {ElementRef} from '@angular/core';

export class DomHelper {
  public blurForm(domForm: ElementRef<any>) {
    if (domForm) {
      const activeElement = domForm.nativeElement.ownerDocument.activeElement;
      if (activeElement instanceof HTMLElement) {
        activeElement.blur();
      }
    }
  }
}
