import {Injectable} from '@angular/core';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {environment} from '../../../../environments/environment';

import {TokenHolder} from './token-holder.service';
import {Observable} from 'rxjs';
import {ConsumerApplicationModel} from '../models/consumer-application-model';
import {CommercialApplicationModel} from '../models/commercial/commercial-application-model';
import {LYNX_SESSION_ID_HEADER_NAME} from "./lynx-session-header-id-interceptor";
import {MFA_SESSION_ID_HEADER_NAME} from "./mfa-session-id-interceptor";

const SYNC_TOKEN = 'syncToken';

@Injectable({
  providedIn: 'root'
})
export class SessionService {

  constructor(private http: HttpClient,
              private sessionTokenHolder: TokenHolder,
              private consumerApplicationModel: ConsumerApplicationModel,
              private commercialApplicationModel: CommercialApplicationModel) {
  }

  startSession() {
    return this.http.post(environment.lynxApi + '/lynx/sessions', null, {observe: 'response'}).subscribe();
  }

  endSession() {
    this.consumerApplicationModel.clear();
    this.commercialApplicationModel.clear();
    if (this.sessionTokenHolder.getToken(SYNC_TOKEN)) {
      return this.http.delete(`${environment.lynxApi}/lynx/sessions/${this.sessionTokenHolder.getToken(SYNC_TOKEN)}`).subscribe();
    }
  }

  requestSessionAsync(): Observable<HttpResponse<Object>> {
    return this.http.post(environment.lynxApi + '/lynx/sessions', null, {observe: 'response'});
  }

  getLynxSessionId(): string | undefined {
    const result = this.sessionTokenHolder.getToken(LYNX_SESSION_ID_HEADER_NAME);
    return result === '' ? undefined : result;
  }

  getMfaSessionId(): string | undefined {
    const result = this.sessionTokenHolder.getToken(MFA_SESSION_ID_HEADER_NAME);
    return result === '' ? undefined : result;
  }
}
