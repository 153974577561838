import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'lynx-security-center-lock',
  templateUrl: './security-center-lock.component.html',
  styleUrls: ['./security-center-lock.component.scss']
})
export class SecurityCenterLockComponent implements OnInit {
  ngOnInit(): void {
  }
}
