import {Injectable} from '@angular/core';
import {BalanceTransfer} from './balance-transfer';
import {ContactMethod} from './ContactMethod';
import {PreQualSubmissionData} from './prequal/pre-qual-service-model';
import {
  GeolocationPositionError
} from './geolocation-type';

const defaultIgnoreKeyListOnClear: String[] = ['shortCode', 'agentBank', 'subAgent', 'rewardsNumber', 'salesId', 'referralId', 'employeeId', 'bankBranch', 'referringEmployeeId', 'company', 'companyRewardsNumber', 'modalService'];
const defaultIgnoreKeyChildrenListOnClear = ['modalService'];

export type MfaSubmissionContactMethod = 'T' | 'E' ;

export class MfaSubmissionData {
  passCode: string;
  contactValue: string;
  contactMethod: MfaSubmissionContactMethod;
}

export class GeolocationData {
  agreeToCollectGeoLocation: boolean;
  coordinates?: {
    latitude: number;
    longitude: number;
  };
  geolocationPositionError?: GeolocationPositionError;
}
@Injectable()
export abstract class ApplicationModel {

  agentBank?: string;
  subAgent?: string;
  bankBranch?: string;
  employeeId?: string;
  referralId?: string;
  referringEmployeeId?: string;
  rewardsNumber?: string;
  customerLoginId?: string;
  customerSessionId?: string;
  salesId?: string;
  solicitationId?: string;
  thirdPartyCustomerId? : string;
  bankerInformation?: {
    name: string;
    emailAddress: string;
    phoneNumber: string;
  };

  balanceTransfers?: BalanceTransfer[];

  mfaData?: MfaSubmissionData;

  shortCode?: string;

  authorizationMethod: ContactMethod;

  firstName?: string;
  middleInitial?: string;
  lastName?: string;
  residentialAddress?: string;
  unit?: string;
  city?: string;
  state?: string;
  zipCode?: string;

  prequalSubmissionData?: PreQualSubmissionData;

  dateOfEstablishedRelationship?: string;
  dateOfLastPurchase?: string;
  totalAmountSpent?: number;
  geolocationData?: GeolocationData;
  instantDigitalWalletProvisioningEnabled?: boolean;

  partnerMarketingCode?: string;

  constructor() {
    this.setInitialValues();
  }

  // For simple types, set the value
  // For Objects (non-array objects), add the new properties to the existing object
  save(param: Object) {
    Object.keys(param).forEach(key => {
      if (this.isHashmap(this[key])){
        this[key] = {...this[key], ...param[key]};
      } else {
        this[key] = param[key];
      }
    });
  }

  private isHashmap(value) {
    return value && typeof value  === 'object' && !(value instanceof Array);
  }

  private canBeRemoved(k: string) {
    return typeof this[k] !== 'function' &&
      !defaultIgnoreKeyListOnClear.find(obj => obj === k);
  }

  private canChildrenBeRemoved(k: string) {
    return typeof this[k] !== 'function' &&
      !defaultIgnoreKeyChildrenListOnClear.find(obj => obj === k);
  }

  private removeKeys(obj) {
    Object.keys(obj).forEach(k => {
      const value = obj[k];
      if (typeof value === 'object' && !Array.isArray(value) && value !== null && !this.canBeRemoved(k) && this.canChildrenBeRemoved(k)) {
        this.removeKeys(value);
      } else if (this.canBeRemoved(k)) {
        delete obj[k];
      }
    });
  }

  clear() {
    this.removeKeys(this);
    this.setInitialValues();
  }

  protected setInitialValues() {
    this.balanceTransfers = [new BalanceTransfer({}), new BalanceTransfer({}), new BalanceTransfer({})];
  }
}
