import {Pipe, PipeTransform} from "@angular/core";
import createAutoCorrectedDatePipe from './addons/create-auto-corrected-date-pipe-addon';

@Pipe({name: 'customDatePipe'})
export class DatePipe implements PipeTransform {
  transform(value: string): string {
    if (!value) return null;
    const autoCorrectedDate = createAutoCorrectedDatePipe('mm-dd-yyyy')(value);
    if (!autoCorrectedDate || !autoCorrectedDate.value) return null;

    return autoCorrectedDate.value
  }
}