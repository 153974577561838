import {Observable, throwError as observableThrowError, concat} from 'rxjs';
import {environment} from '../../../environments/environment';
import {delay, map, retryWhen, take} from 'rxjs/operators';
import {OperatorFunction} from 'rxjs';
import {Inject, Injectable, InjectionToken} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {pipeFromArray} from 'rxjs/internal/util/pipe';

export const defaultRetryStrategy = [
  take(6),
  delay(5000),
  o => concat(o, observableThrowError(new Error('Retry limit exceeded!'))),
];

export const DISCLOSURE_RETRY_STRATEGY_TOKEN = new InjectionToken('disclosureRetryStrategy',
  {
    providedIn: 'root',
    factory: () => defaultRetryStrategy
  });

@Injectable({
  providedIn: 'root'
})
export class TsiDisclosuresClient {
  constructor(
    private http: HttpClient,
    @Inject(DISCLOSURE_RETRY_STRATEGY_TOKEN) private retryStrategy: DisclosureRetryStrategy
  ) {
  }

  get(id: string): Observable<string> {
    return this.http
      .get(`${environment.lynxApi}/lynx/disclosures/${id}`, {observe: 'response'})
      .pipe(
        map(response => {
          if (['NOT_FOUND', 'ERROR', 'NOT_HANDLED', 'PENDING'].indexOf(response.body['status']) > -1) {
            throw new Error(response.body['status']);
          }
          return response.body['disclosureHTML'];
        }),
        // When upgrading to angular 7 remove the usage of the internal function pipeFromArray
        // Move back to
        //    retryWhen(errors => errors.pipe(...this.retryStrategy)),
        // Because as I understand it, typescript 3 will make the spread syntax work again
        // see https://github.com/ReactiveX/rxjs/issues/3989 for more info
        retryWhen(errors => errors.pipe(pipeFromArray(this.retryStrategy))),
      );
  }
}

export type DisclosureRetryStrategy = (OperatorFunction<any, any>)[];