import {NgModule} from '@angular/core';
import {ApplicationForm} from './application-form/application-form.component';
import {LynxUiRoutingModule} from './lynx-ui-routing.module';
import {BrowserModule} from '@angular/platform-browser';
import {LynxUiCommonModule} from './shared/modules/lynx-ui-common.module';
import {ActionsModule} from './shared/services/actions/actions.module';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {CSRFTokenInterceptor} from './shared/services/csrf-token-interceptor';
import {SessionHeaderInterceptor} from './shared/services/session-header-interceptor';
import {HttpErrorInterceptor} from './shared/services/http-error-interceptor';
import {Router} from '@angular/router';
import {LynxSessionIdHeaderInterceptor} from "./shared/services/lynx-session-header-id-interceptor";
import {MfaSessionIdHeaderInterceptor} from "./shared/services/mfa-session-id-interceptor";

@NgModule({
  imports: [
    BrowserModule,
    HttpClientModule,
    LynxUiCommonModule,
    LynxUiRoutingModule,
    ActionsModule
  ],
  bootstrap: [ApplicationForm],
  declarations: [ApplicationForm],
  providers: [
    {provide: 'Window', useValue: window},
    {provide: 'Navigator', useValue: navigator},
    {provide: HTTP_INTERCEPTORS, useClass: CSRFTokenInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: SessionHeaderInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: LynxSessionIdHeaderInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: MfaSessionIdHeaderInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true, deps: [Router]},
  ]
})
export class LynxUiModule {
}
