import {BehaviorSubject} from 'rxjs';
import {Card} from '../models/card';
import {Injectable} from '@angular/core';
import {SelectOption} from '../../../common/models/select-option';

@Injectable({
  providedIn: 'root'
})
export class PromoConfigurationService {
  shortCode: string;

  promoConfiguration: BehaviorSubject<PromoConfiguration> = new BehaviorSubject<PromoConfiguration>({
    agentBank: null,
    subAgent: null,
    logoUrl: '',
    inNetworkIndicator: false,
    productName: '',
    selectedCard: null,
    cards: [],
    soctUrl: '',
    disclosures: '',
    disclosuresOfNote: '',
    approvedResponseButtonText: '',
    approvedResponseUrl: '',
    pendingResponseButtonText: '',
    pendingResponseUrl: '',
    approvedMessage: '',
    pendingMessage: '',
    proof: '',
    status: 'live',
    fieldsToDisplay: {},
    balanceTransfer: false,
    showPlastics: false,
    bySubmittingLanguage: {
      agreeToTermsLanguage: '',
      agreeToTermsLabel: '',
      agreeToPrivacyNoticeLabel: null,
      agreeToElectronicTermsLabel: '',
      agreeToTermsError: '',
      agreeToPrivacyNoticeError: null,
      agreeToElectronicTermsError: '',
      sectionHeader: null,
      cssStyle: ''
    },
    printSoct: false,
    tealiumConfiguration: {},
    adobeLaunchConfiguration: {},
    partnerConfiguration: {},
    checkFraudData: false,
    limitedTimeOffer: true,
    autoProvision: true,
    commercialConfig: {
      legalStructures: [],
      stateDropDown: [],
      identificationTypeDropDown: [],
      countryDropDown: [],
      attestationCopy: '',
    },
    writtenAuthorizationFormUrl: '',
    showSsnMask: false,
    enableMfa: false,
    employeeLed: false,
    electronicTermsDeliveryConsent: false,
    review: {
      progressionStep: 'SUBMIT_APPLICATION',
      progressionText: 'Submit Application'
    },
    businessLineConfig: {},
    enableGeofencing: false,
    temporaryCreditLineRestrictionMessage: ''
  });

  /**
   * Invalidate shortcode, which will not allow the user to use any the main form pages => 404 page
   */
  invalidateShortCode() {
    this.shortCode = null;
  }
}

export type PromoField = {
  required: boolean,
  displayName: string,
  maxLength?: number,
  locked?: boolean,
  selectValues?: SelectOption[],
  min?: number,
  max?: number
  configuration?: {
    explanatoryText?: string
    length?: number,
    format?: string
  }
};

export interface IdTypeSelectOption extends SelectOption {
  identificationIssuer: 'STATE' | 'COUNTRY';
}

export type FieldsToDisplay = {
  rewardsNumber?: PromoField,
  businessPhone?: PromoField,
  emailAddress?: PromoField,
  bankNumber?: PromoField,
  branchNumber?: PromoField,
  bankBranch?: PromoField,
  salesId?: PromoField,
  referralId?: PromoField,
  employeeId?: PromoField,
  referringEmployeeId?: PromoField,
  customerIdFields?: PromoField,
  collegeStudent?: PromoField,
  mailingAddressFields?: PromoField,
  accountBalanceFields?: PromoField,
  secureDeposit?: PromoField,
  depositVerification?: PromoField,
  bankerInformationName?: PromoField,
  bankerInformationEmailAddress?: PromoField,
  bankerInformationPhoneNumber?: PromoField,
  personalBankerText?: PromoField,
  personalNameFields?: PromoField,
  authorizedUser?: PromoField,
  uclLoanAmount?: PromoField,
  uclRequestedTerm?: PromoField,
  uclOccupationalStatus?: PromoField,
  uclTitleAtEmployer?: PromoField,
  uclYearsAtEmployer?: PromoField,
  uclAdditionalIncomePerMonth?: PromoField,
  uclUsResidentStatus?: PromoField,
  agentBankOverrides?: PromoField;
  authorizedUserDisclosure?: PromoField;
  substituteW9?: PromoField;
  writtenAuthorizationForm?: PromoField;
  phoneNumberType?: PromoField;

  acrFirstName?: PromoField;
  acrMiddleInitial?: PromoField;
  acrLastName?: PromoField;
  acrAddressLine1?: PromoField;
  acrAddressLine2?: PromoField;
  acrCity?: PromoField;
  acrState?: PromoField;
  acrZipCode?: PromoField;
  acrEmailAddress?: PromoField;
  acrHomePhoneNumber?: PromoField;
  acrSsn?: PromoField;
  acrDob?: PromoField;
  acrAnnualIncome?: PromoField;
  companyName?: PromoField;
};

const referrerFieldNames = [
  'bankNumber',
  'branchNumber',
  'bankBranch',
  'employeeId',
  'referralId',
  'referringEmployeeId',
  'salesId',
  'rewardsNumber'];

export const getReferralFields = (fieldsToDisplay: FieldsToDisplay) => {
  return Object.keys(fieldsToDisplay)
    .filter(field => referrerFieldNames.includes(field))
    .reduce((map, key) => {
      map[key] = fieldsToDisplay[key] as PromoField;
      return map;
    }, {}
    );
};

export type PromoStatus = 'new' | 'draft' | 'live' | 'archived';

export type BySubmittingLanguage = {
  agreeToTermsLanguage: string;
  agreeToTermsLabel: string;
  agreeToPrivacyNoticeLabel: string;
  agreeToElectronicTermsLabel: string;
  agreeToTermsError: string;
  agreeToPrivacyNoticeError: string;
  agreeToElectronicTermsError: string;
  sectionHeader?: string;
  cssStyle: string;
};

export type CommercialConfig = {
  legalStructures: LegalStructureSelectOption[];
  stateDropDown: SelectOption[];
  identificationTypeDropDown: IdTypeSelectOption[];
  countryDropDown: SelectOption[];
  attestationCopy: string
};

export interface LegalStructureSelectOption extends SelectOption {
  entityCode: string;
}
export interface PromoConfiguration {
  agentBank: string;
  subAgent: string;
  promoCode?: string;
  logoUrl: string;
  inNetworkIndicator: boolean;
  productName: string;
  selectedCard: Card;
  cards: Card[];
  soctUrl: string;
  disclosures: string;
  disclosuresOfNote: string;
  approvedResponseButtonText: string;
  approvedResponseUrl: string;
  pendingResponseButtonText: string;
  pendingResponseUrl: string;
  approvedMessage: string;
  pendingMessage: string;
  proof: string;
  status: PromoStatus;
  fieldsToDisplay: FieldsToDisplay;
  balanceTransfer: boolean;
  showPlastics: boolean;
  bySubmittingLanguage: BySubmittingLanguage;
  printSoct: boolean;
  tealiumConfiguration: {[key: string]: string};
  adobeLaunchConfiguration: {[key: string]: any};
  partnerConfiguration: {
    [key: string]: string | boolean;
    copyright?: string;
  };
  checkFraudData: boolean;
  limitedTimeOffer: boolean;
  autoProvision: boolean;
  commercialConfig?: CommercialConfig;
  writtenAuthorizationFormUrl: string;
  showSsnMask: boolean;
  enableMfa: boolean;
  employeeLed: boolean;
  electronicTermsDeliveryConsent: boolean;
  review: {progressionText: string, progressionStep: ReviewSteps};
  businessLineConfig: {[key: string]: string};
  enableGeofencing?: boolean;
  temporaryCreditLineRestrictionMessage?: string;
}

export type ReviewSteps = 'SUBMIT_APPLICATION' | 'VERIFY_PHONE_NUMBER' | 'AUTHORIZE_SECURED_DEPOSIT' | 'CUSTOMER_AUTHORIZE';

