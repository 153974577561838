import { OnDestroy, OnInit, Renderer2, Directive } from '@angular/core';
import {ModalService} from '../../services/modalService.service';

@Directive()
export class BaseModalComponent implements OnInit, OnDestroy {
  constructor(private renderer2: Renderer2, public modalService: ModalService) {}

  ngOnInit(): void {
    this.renderer2.addClass(document.body, 'modal-open');
  }

  ngOnDestroy() {
    this.renderer2.removeClass(document.body, 'modal-open');
  }

  closeMyself() {
    this.modalService.closeModal();
  }
}
