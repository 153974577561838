import {Pipe, PipeTransform} from '@angular/core';

@Pipe({name: 'superscriptSymbol'})
export class SuperscriptSymbolPipe implements PipeTransform {
  transform(productName: string) {
    if (!productName) {
      return productName;
    }

    // Utilizes Negative Lookahead.
    // If the ® is immediately succeeded by </sup> or </sub>, do nothing.
    return productName.replace(/®(?!<\/su([bp])>)/g, '<sup>®</sup>');
  }
}