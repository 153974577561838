import {Component, Input, OnInit} from '@angular/core';
import {FormControl, NgForm} from '@angular/forms';
import {ErrorDisplay} from '../../services/error-display';
import {ReactiveTextFieldComponent} from '../reactive-text-field/reactive-text-field.component';
import {Global} from '../../global';

@Component({
  selector: 'agent-subagent-field',
  templateUrl: './agent-subagent-field.component.html',
  styleUrls: ['./agent-subagent-field.component.scss']
})
export class AgentSubagentFieldComponent extends ReactiveTextFieldComponent implements OnInit {
  @Input() submitted: boolean;
  @Input() autocomplete = 'on';
  @Input() control = new FormControl<string | null>(null);
  @Input() form: NgForm;
  @Input() explanatoryText: string;
  @Input() fieldId = 'agent-subagent';

  private focus = false;

  private mask = [/[\da-zA-Z]/, /[\da-zA-Z]/, /[\da-zA-Z]/, '-', /[\da-zA-Z]/, /[\da-zA-Z]/, /[\da-zA-Z]/];

  constructor(public errorDisplay: ErrorDisplay, global: Global) {
    super(errorDisplay, global);
  }

  ngOnInit(): void {}

  focusInput() {
    this.focus = true;
  }

  unfocusInput() {
    this.focus = false;
  }

  getTextMask() {
    return {mask: this.mask};
  }

  getPlaceholder() {
    return '___-___';
  }

  getPattern() {
    return this.pattern;
  }
}
