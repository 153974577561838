import {Directive, ElementRef, ViewChild} from '@angular/core';
import {FormControl, FormGroup, NgForm} from '@angular/forms';
import {Router} from '@angular/router';
import {DomHelper} from '../../../../common/services/dom-helper';
import {ApplicationModel} from '../../models/application-model';
import {PromoField} from '../../services/promo-configuration.service';
import {RequiredNonBlankValidator} from '../../validators/required-non-blank.validator';
import {NgFormFormBuilderAdapter} from '../../forms/ng-form-form-builder-adapter';


@Directive()
export abstract class BaseForm {
  @ViewChild('form', { static: true }) form: NgForm;
  @ViewChild('htmlForm') domForm: ElementRef;
  formGroup: FormGroup;
  private domHelper: DomHelper;
  submitted: boolean;

  constructor(
    public ccApp: ApplicationModel,
    protected router: Router) {
    this.domHelper = new DomHelper();
  }

  abstract getFormObject();
  abstract getPreviousRoute();
  abstract getNextRoute();

  protected shouldShowExitConfirmation() {
    return false;
  }
  
  handleMouseDown(event) {
    event.preventDefault();
    Object.keys(this.form.control.controls)
      .forEach(key => this.form.control.controls[key]['focused'] = false);
  }

  blurForm() {
    this.domHelper.blurForm(this.domForm);
  }

  next() {
    this.submitted = true;
    this.blurForm();
    this.formGroup.updateValueAndValidity();

    if (this.formGroup.invalid) {
      this.scrollToFirstInvalidField();
      return;
    }
    this.submitForm();
  }

  submitForm() {
    this.ccApp.save(this.getFormObject());
    this.navigateNextRoute();
  }

  navigateNextRoute() {
    this.router.navigate([this.getNextRoute()]);
  }

  previous() {
    this.router.navigate([this.getPreviousRoute()]);
  }

  protected createFormControl(formBuilderAdapter: NgFormFormBuilderAdapter, fieldToDisplay: PromoField, fieldName: string, fieldState: any): FormControl {
    if (fieldToDisplay) {
      const validators = [];
      if (fieldToDisplay.required) {
        validators.push(RequiredNonBlankValidator.valid());
      }
      return formBuilderAdapter.control(
        fieldName,
        fieldState,
        validators
      );
    }

    return null;
  }

  protected scrollToFirstInvalidField() {
    BaseForm.handleScrollToInvalid(this.domForm);
  }

  static handleScrollToInvalid(domForm: ElementRef) {
    let firstInvalidNode: Element = domForm.nativeElement.querySelector('input.ng-invalid, select.ng-invalid, textarea.ng-invalid');
    if (firstInvalidNode === null) {
      firstInvalidNode = domForm.nativeElement.querySelector('input.invalid, select.invalid, textarea.invalid');
    }
    (firstInvalidNode?.closest('.form-group') as Element)?.scrollIntoView();
  }

  protected makeNumericOrUndefined(value: any): number | undefined {
    if (value === undefined || value === null || value === '') {
      return undefined;
    }
    if (typeof value === 'number') {
      return value;
    }

    const filteredValue = value.replace(/[$,]/g, '');
    // Match whole number digits.
    const regex = /^(\d*)(\..*)?$/;
    const match: RegExpMatchArray = filteredValue.match(regex);
    return match ? Number(match[1]) : undefined;
  }
}
