import {Inject, Injectable} from '@angular/core';
import {AbstractScriptInjectorService} from './abstract-script-injector.service';

@Injectable({
  providedIn: 'root'
})
export class ScriptInjectorService extends AbstractScriptInjectorService {
  constructor(@Inject('Window') _window) {
    super(_window)
  }

  createScriptElement(doc: Document, scriptSrc: string) {
    let scriptToInsert;

    scriptToInsert = doc.createElement('script');
    scriptToInsert.language = 'javascript';
    scriptToInsert.type = 'text/javascript';
    scriptToInsert.async = 1;
    scriptToInsert.charset = 'utf-8';
    scriptToInsert.src = scriptSrc;
    return scriptToInsert;
  }
}