import {Injectable} from '@angular/core';
import {Subscriber} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TokenHolder {
  private _tokens: {[keys: string]: string} = {};

  public setToken(key: string, value: string) {
    this._tokens[key] = value || '';
  }
  public getToken(key: string) {
    return this._tokens[key] || '';
  }
}