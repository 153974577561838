import {SocureEnvironment} from '../../../../../../environments/socure-environment';

export function getSocurePublicKeyForEnvironment(socureEnvironment: SocureEnvironment): string {
  switch (socureEnvironment) {
    case SocureEnvironment.PRODUCTION:
      return '7b3644e5-ff91-46e2-b343-5d165db3af20';
    case SocureEnvironment.CERTIFICATION:
      return '745a8cc0-94b2-4099-8d6a-28c645f2ed84';
    case SocureEnvironment.SANDBOX:
    default:
      return 'a7e56d00-d94b-4de1-8be4-51f83a8b78e4';
  }
}