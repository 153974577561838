import {Component, Input} from '@angular/core';
import {BaseReactiveFieldComponent} from '../base-reactive-field/base-reactive-field-component';
import {Global} from '../../global';
import {ErrorDisplay} from '../../services/error-display';

@Component({
  selector: 'reactive-text-area-field',
  templateUrl: './reactive-text-area-field.component.html',
  styleUrls: ['./reactive-text-area-field.component.scss'],
})
export class ReactiveTextAreaFieldComponent extends BaseReactiveFieldComponent {

  @Input() fieldId: string;
  @Input() inputType = 'text';
  @Input() submitted: boolean;
  @Input() maxlength: string;
  @Input() rows: number;
  @Input() cols: number;
  @Input() showLabel = true;
  @Input() required: boolean = true;

  constructor(public errorDisplay: ErrorDisplay, protected global: Global) {
    super(global);
  }
}
