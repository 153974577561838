import {NgModule} from '@angular/core';
import {RouterActionHandler} from './handlers/router-action-handler';
import {ModalActionHandler} from './handlers/modal-action-handler';

@NgModule()
export class ActionsModule {
    constructor(
        routerActionHandlerService: RouterActionHandler,
        modalActionHandler: ModalActionHandler
    ) {
    }

}