import {
  AfterContentInit,
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output, ViewChild,
  ViewEncapsulation
} from '@angular/core';
import {AbstractControl} from '@angular/forms';
import {BySubmittingLanguage} from '../../services/promo-configuration.service';
import {PromoConfiguration} from '../../services/promo-configuration.service';
import {ModalService} from '../../services/modalService.service';
import {ElectronicTermsService} from '../modal/electronic-terms/electronic-terms.service';
import {take} from 'rxjs/operators';

const GLOBAL_SCOPE_CSS_ID = 'commercial-by-submitting-css';

@Component({
  selector: 'lynx-by-submitting-language',
  templateUrl: './by-submitting-language.component.html',
  styleUrls: ['./by-submitting-language.component.scss',],
  encapsulation: ViewEncapsulation.None
})
export class BySubmittingLanguageComponent implements OnInit, AfterViewInit {
  @Input() bySubmittingLanguage: BySubmittingLanguage;
  @Input() promoConfiguration: PromoConfiguration;
  @Input() submitted: boolean;
  @Input() electronicTermsDeliveryConsent: boolean;
  @Input() agreeControl: AbstractControl;
  @Input() privacyNoticeControl: AbstractControl;
  @Input() etermsControl: AbstractControl;
  @Input() isCommercial: boolean;
  @Output() hasViewedWrittenAuthorizationFormChange = new EventEmitter();

  @Input() agreeToTerms = '';
  @Input() checkboxLabel = '';
  @Input() checkboxElectronicLabel = '';

  @ViewChild('eterms', { read: ElementRef }) etermsElement: ElementRef<HTMLElement>;

  etermsHasTooltip: boolean = false;

  constructor(protected elRef: ElementRef, private modalService: ModalService, private electronicTermsService: ElectronicTermsService) {
  }

  ngOnInit() {
    if (!document.querySelector(`#${GLOBAL_SCOPE_CSS_ID}`)) {
      const cssTextNode = document.createTextNode(this.bySubmittingLanguage.cssStyle);
      const style = document.createElement('style');
      style.id = GLOBAL_SCOPE_CSS_ID;
      style.appendChild(cssTextNode);
      document.head.appendChild(style);
    }
  }

  ngAfterViewInit(): void {
    this.elRef.nativeElement.querySelectorAll('.writtenAuthorizationFormLink')
      .forEach(link => link.addEventListener('click', this.onAuthorizationFormClick.bind(this), {once: true}));

    if(this.etermsElement) {
      let etermsAnchor = this.etermsElement.nativeElement.querySelector("a.edt");
      if (etermsAnchor) {
        setTimeout(() => {
          this.etermsControl.disable();
          this.etermsHasTooltip = true;

          etermsAnchor.addEventListener('click', e => {
            e.preventDefault();
            this.etermsControl.enable();
            this.modalService.openModal("electronicTerms");
            this.electronicTermsService.agreeClicked$.pipe(take(1)).subscribe(agreeClicked => {
              if(agreeClicked) {
                this.etermsControl.setValue(true);
              }
            });
          });
        });
      }
    }
  }

  onAuthorizationFormClick() {
    this.hasViewedWrittenAuthorizationFormChange.emit();
  }

  ngOnDestroy(): void {
    this.elRef.nativeElement.querySelectorAll('.writtenAuthorizationFormLink')
      .forEach(link => link.removeEventListener('click', this.onAuthorizationFormClick));
  }
}
