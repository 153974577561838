import {BehaviorSubject} from 'rxjs';
import {LegalEntityRules, LegalEntityRulesRequest} from './LegalEntityRules';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {Injectable} from '@angular/core';

const url = `${environment.lynxApi}/lynx/entity-rules-lookup`;

@Injectable()
export class LegalEntityRulesService {
  legalEntityRules: BehaviorSubject<LegalEntityRules> = new BehaviorSubject<LegalEntityRules>(
    new LegalEntityRules());

  constructor(private httpClient: HttpClient) {}

  lookupLegalEntityRules(request: LegalEntityRulesRequest): void {
    this.httpClient.post(url, request).subscribe(
      (response) => {
          this.legalEntityRules.next(response as LegalEntityRules);
      },
      (error) => {
        // TODO Check dynamic app to see what to do for the error handling
        // TODO Do we respect entity type or assume all is true?
        this.legalEntityRules.next(new LegalEntityRules());
      }
    );
  }
}
