import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'expandable-section',
  templateUrl: './expandable-section.component.html',
  styleUrls: ['./expandable-section.component.scss']
})
export class ExpandableSectionComponent implements OnInit {
  @Output() private onCollapse = new EventEmitter<any>();
  @Output() private onExpand = new EventEmitter<any>();
  @Input() sectionTitle: string;
  @Input() sectionClass: string = 'toggle-section';
  @Input() expanded = false;
  @Input() alwaysExpanded = false;
  @Input() addSuffixTitle: string;
  componentId = `${Math.random()}`;

  ngOnInit(): void {
    this.emit();
  }

  toggle() {
    this.expanded = !this.expanded;
    this.emit();
  }

  private emit() {
    if (this.expanded) {
      this.onExpand.emit();
    } else {
      this.onCollapse.emit();
    }
  }
}
