import {AbstractControl, ValidatorFn} from '@angular/forms';

export class RequiredNonBlankValidator {
  static valid(customGroupControl?, defaultErrorKey = 'required'): ValidatorFn {
    return (control: AbstractControl) => {
      if(customGroupControl) {
        control = customGroupControl;
      }
      const isString =  (typeof control.value === 'string');
      const value = control.value;
      if ((value === null || control.value === undefined) || (isString && (value.trim().length === 0))) {
        const errors = {};
        errors[defaultErrorKey] = true;
        return errors;
      }
      return null;
    };
  }
}
