import {Injectable} from '@angular/core';
import {PromoField} from '../services/promo-configuration.service';
import {AbstractControl, FormControl, ValidationErrors, ValidatorFn, Validators} from '@angular/forms';
import * as moment from 'moment';
import {
  EMAIL_ENDS_WITH_DOT_CON_REGEX,
  INVALID_COMPANY_NAME_ON_SOL_PROP_REGEX,
  VALID_EMAIL_FORMAT_REGEX
} from '../constants';
import {PhoneNumberType} from '../models/phone-number/phone-number-models';

@Injectable({
  providedIn: 'root'
})
export class FormFieldValidators {
  currencyValidators(promoField: PromoField | null) {
    if (!promoField) {
      return [];
    }
    const validators = [];

    if (promoField.maxLength) {
      validators.push(Validators.maxLength(promoField.maxLength));
    }
    if (promoField.required) {
      validators.push(Validators.required);
    }
    validators.push(validateWholeDollarFormat());
    return validators;
  }

  phoneNumberValidators(promoField: PromoField): Array<ValidatorFn> {
    const validators = [Validators.minLength(10)];
    if (promoField && promoField.required) {
      validators.push(Validators.required);
    }
    return validators;
  }

  emailAddressValidators(promoField: PromoField, opts?: { isRequired?: boolean }): Array<ValidatorFn> {
    const validators = [FormFieldValidators.emailValidator];
    const required = opts ? opts.isRequired : promoField && promoField.required;
    if (required) {
      validators.push(Validators.required);
    }
    return validators;
  }

  ssnValidators(opts?: { required?: boolean, exemptFromCreditCheck?: boolean }) {
    const defaults = {required: true, exemptFromCreditCheck: false};
    const {required, exemptFromCreditCheck} = {...defaults, ...opts};
    const validators = [Validators.minLength(9), this.ValidateSSN(exemptFromCreditCheck)];
    if (required) {
      validators.push(Validators.required);
    }
    return validators;
  }

  minCurrencyValidator = (minAmount: number): ValidatorFn => {
    return (control: AbstractControl): ValidationErrors | null => {
      let value = control.value;
      if (value === null || value === undefined || value === '') {
        return null;
      }
      if (typeof value === 'string') {
        let amountNumeric = control.value.replace(/[$,]/g, '');
        amountNumeric = amountNumeric.replace(/\.\d\d$/g, '');
        value = Number(amountNumeric.trim());
      }
      if (value < minAmount) {
        return {min: true};
      }
      return null;
    };
  };

  maxCurrencyValidator = (maxAmount: number): ValidatorFn => {
    return (control: AbstractControl): ValidationErrors | null => {
      let value = control.value;
      if (value === null || value === undefined || value === '') {
        return null;
      }
      if (typeof value === 'string') {
        let amountNumeric: string = control.value.replace(/[$,]/g, '');
        amountNumeric = amountNumeric.replace(/\.\d\d$/g, '');
        value = Number(amountNumeric.trim());
      }
      if (value > maxAmount) {
        return {max: true};
      }
      return null;
    };
  };

  maxNumberValidator = (maxAmount: number): ValidatorFn => {
    return (control: AbstractControl): ValidationErrors | null => {
      let value = control.value;
      if (value == null || value === '') {
        return null;
      }
      let amountNumeric = value;
      if (typeof value === 'string') {
        amountNumeric = Number(value.trim());
      }
      if (amountNumeric > maxAmount) {
        return {'maxlength': {requiredLength: maxAmount}};
      }
      return null;
    };
  };

  multipleOf50Validator = (): ValidatorFn => {
    return this.multiplesValidator(50);
  }
  multiplesValidator = (amount: number): ValidatorFn => {
    function isNotMultipleOfAmount(control: AbstractControl) {
      if (control.value == null) {
        return false;
      }
      if (typeof control.value === 'string') {
        const amountNumeric = control.value.replace(/[$,]/g, '');
        return Number(amountNumeric.trim()) % amount !== 0;
      }
      // if not string, should be number
      return Number(control.value) % amount !== 0;
    }

    return (control: AbstractControl): ValidationErrors | null => {
      const multipleOf = `multipleOf${amount}`;
      return isNotMultipleOfAmount(control) ? {[multipleOf]: true} : null;
    };
  }

  dateOfBirthValidators(required = true) {
    return dobValidators(required);
  }

  expirationDateValidators(required = true) {
    if (required) {
      return [Validators.minLength(10), Validators.required, this.ValidateFutureDate()];
    } else {
      return [Validators.minLength(10), this.ValidateFutureDate()];
    }
  }

  issueDateValidators(required = true) {
    if (required) {
      return [Validators.minLength(10), Validators.required, this.ValidatePastDate()];
    } else {
      return [Validators.minLength(10), this.ValidatePastDate()];
    }
  }

  validateNotBlank = (): ValidatorFn => {
    return (control: AbstractControl): ValidationErrors | null => {
      if ((control.value || '').trim().length === 0) {
        return {blank: true};
      }
      return null;
    };
  };

  static validatePhoneNumberIfTypeExists = (phoneNumberTypeControl: FormControl, phoneNumberControl: FormControl): ValidatorFn => {
    return (): ValidationErrors | null => {
      const isPhoneNumberNotValid = phoneNumberControl.errors;
      const isPhoneNumberEmpty = (phoneNumberControl.value || '').trim().length === 0;
      if (phoneNumberTypeControl.value && (isPhoneNumberNotValid || isPhoneNumberEmpty)) {
        return {phoneNumberIsRequiredDueToTypeValue: true};
      }
      return null;
    };
  };

  static validatePhoneNumberTypeIfPhoneNumberExists = (phoneNumberControl: FormControl<string>, phoneNumberTypeControl: FormControl<PhoneNumberType>, isPhoneNumberRequired: boolean): ValidatorFn => {
    return (): ValidationErrors | null => {
      const isPhoneNumberEmpty = (phoneNumberControl.value || '').trim().length === 0;
      const hasType = phoneNumberTypeControl.value;
      if (!hasType && (isPhoneNumberRequired || (!isPhoneNumberEmpty && !isPhoneNumberRequired))) {
        return {phoneNumberTypeIsRequired: true};
      }
      return null;
    };
  };

  // Wrapper around minlength validator for using a custom control
  static minLengthValidator(minLength: number, customGroupControl: AbstractControl): ValidatorFn {
    return (): ValidationErrors | null => {
      if (Validators.minLength(minLength)(customGroupControl)) { // if min not valid
        return Validators.minLength(minLength)(customGroupControl);
      }
    };
  }

  validateWholeDollarFormat = validateWholeDollarFormat;

  private ValidateSSN = (isExemptFromCreditCheck: boolean): ValidatorFn => {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!control.value) {
        return null;
      }
      const ssn = control.value ? (control.value as string).replace(/-/g, '') : null;

      const regexNoZeros = /((?!0{3})[\d]{3})((?!0{2})[\d]{2})((?!0{4})[\d]{4})/;

      let regexNoSameDigit = /(?!(1{9}|2{9}|3{9}|4{9}|5{9}|6{9}|7{9}|8{9}|9{9}))[\d]{9}/;
      if (isExemptFromCreditCheck) {
        const allowAllNinesRegex = /9{9}/;
        regexNoSameDigit = new RegExp(regexNoSameDigit.source + '|' + allowAllNinesRegex.source);
      }

      const regexNotOneThruNine = /(?!(123456789))[\d]{9}/;
      const isValid = regexNoZeros.test(ssn)
        && regexNoSameDigit.test(ssn)
        && regexNotOneThruNine.test(ssn);
      return isValid ? null : {invalidSSN: true};
    };
  };

  private ValidateFutureDate = (): ValidatorFn => {
    return (control: AbstractControl) => {
      if (!control.value) {
        return null;
      }
      const idAge = (moment(control.value, 'MM-DD-YYYY', true)).diff(moment().startOf('day'), 'days');
      return (isNaN(idAge) || idAge <= 0) ? {'invalidIdExpirationDate': true} : null;
    };
  };

  private ValidatePastDate = (): ValidatorFn => {
    return (control: AbstractControl) => {
      if (!control.value) {
        return null;
      }
      const idAge = (moment(control.value, 'MM-DD-YYYY', true)).diff(moment().startOf('day'), 'days');
      return (isNaN(idAge) || idAge >= 0) ? {'invalidIdIssueDate': true} : null;
    };
  };

  static fullNameLengthValidator = (control: FormControl, secondaryControl: FormControl, middle: FormControl, fullLength: number): ValidatorFn => {
    control.valueChanges.subscribe(() => secondaryControl.updateValueAndValidity({onlySelf: true, emitEvent: false}));
    middle.valueChanges.subscribe(() => control.updateValueAndValidity({onlySelf: true, emitEvent: false}));

    return (): ValidationErrors | null => {
      const firstLength = control.value ? control.value.length : 0;
      const lastLength = secondaryControl.value ? secondaryControl.value.length + 1 : 0;
      const midLength = middle.value ? middle.value.length + 1 : 0;

      let length = firstLength + lastLength + midLength;
      return length > fullLength ? {fullNameTooLong: true} : null;
    };
  };

  static emailValidator: ValidatorFn = function isEmailTypoValidator(control: AbstractControl): ValidationErrors | null {
    const value = control.value;
    if (value === null || value === undefined || value === '') {
      return null;
    }

    const isValidEmail = VALID_EMAIL_FORMAT_REGEX.test(value) &&
      !EMAIL_ENDS_WITH_DOT_CON_REGEX.test(value);

    return isValidEmail ?
      null :
      {invalidEmail: true};
  };

  static validateCompanyNameOnSoleProp = (legalStructure: FormControl, companyName: FormControl): ValidatorFn => {
    legalStructure.valueChanges.subscribe(() => companyName.updateValueAndValidity({onlySelf: true, emitEvent: false}));

    return (): ValidationErrors | null => {
      const isLegalStructureEmpty = !legalStructure.value && (legalStructure.value === null || legalStructure.value === undefined || legalStructure.value === '')
      const isCompanyNameEmpty = !companyName.value && (companyName.value === null || companyName.value === undefined || companyName.value === '')
      if (isCompanyNameEmpty || isLegalStructureEmpty || !(typeof companyName.value === 'string')) {
        return null;
      } else {
        const inValidCompanyName =  INVALID_COMPANY_NAME_ON_SOL_PROP_REGEX.test(companyName.value);
        const isLegalStructureSolProp = legalStructure.value === 'SOL';
        return (inValidCompanyName && isLegalStructureSolProp) ? {invalidCompanyNameOnSolProp: true} : null;
      }
    };
  };
}

export function dobValidators(required = true) {
  if (required) {
    return [Validators.minLength(10), Validators.required, ValidateDOB()];
  } else {
    return [Validators.minLength(10), ValidateDOB()];
  }
}

export function authorizedUserDobValidators(required = true) {
  if (required) {
    return [Validators.minLength(10), Validators.required, ValidateDOB(0)];
  } else {
    return [Validators.minLength(10), ValidateDOB(0)];
  }
}

function ValidateDOB(minimumAge = 18): ValidatorFn {
  return (control: AbstractControl) => {
    if (!control.value) {
      return null;
    }
    const age = (moment().startOf('day')).diff(moment(control.value, 'MM-DD-YYYY', true), 'years');
    return (isNaN(age) || age >= 130 || age < minimumAge) ? {'invalidDOB': true} : null;
  };
}

export const poBoxValidator: () => ValidatorFn = () => {
  function hasPoBox(control: AbstractControl) {
    if (!control.value) {
      return null;
    }

    const STARTS_WITH_PO = /^[Pp]\.?[Oo]\.?#?\s.*/;
    const CONTAINS_PO = /.*\s[Pp]\.?[Oo]\.?#?\s.*/;
    const CONTAINS_BOX_FOLLOWED_BY_NUMERALS = /.*[Bb][Oo]?[Xx]\s+[0-9]+.*/;
    const CONTAINS_POBO = /.*[Pp][Oo][Bb][Oo]\s.*/;
    const CONTAINS_FULL_PO_TITLE = /.*post office box.*/gi;
    return STARTS_WITH_PO.test(control.value) || CONTAINS_PO.test(control.value) || CONTAINS_BOX_FOLLOWED_BY_NUMERALS.test(control.value) || CONTAINS_POBO.test(control.value) ||
      CONTAINS_FULL_PO_TITLE.test(control.value);
  }

  return (control: AbstractControl): { [key: string]: any } => {
    return hasPoBox(control) ? {poBox: true} : null;
  };
};

export const validateWholeDollarFormat = (): ValidatorFn => {
  return (control: AbstractControl): { [key: string]: boolean } | null => {
    if (!control.value) {
      return null;
    }

    let stringValue = control.value;
    if (typeof stringValue === 'number') {
      stringValue = control.value.toString();
    }

    const updatedValue = stringValue.replace(/[$,]/g, '');
    // Whole dollar and optional . or .0 or .00
    const regex = /^\d+(\.00|\.0|\.)?$/;
    if (!updatedValue.match(regex)) {
      return {wholeDollar: true};
    }
    return null;
  };
};

export type FormFieldType = string | number | null | undefined;
