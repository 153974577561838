import {Subject} from 'rxjs';
import {Injectable} from '@angular/core';

interface ModalData {
  modalName: string;
  args?: any;
}

export const BFO_REQUIREMENTS_MODAL_NAME = 'bfoRequirements';

@Injectable({providedIn: 'root'})
export class ModalService {
  private showModal = new Subject<ModalData>();
  hasViewedBfoRequirements = false;

  showModal$ = this.showModal.asObservable();

  constructor(){}

  openModal(modalToShow: string, args: any = undefined) {
    this.showModal.next({modalName: modalToShow, args});
  }

  attemptOpenBfoRequirementModal() {
    if(!this.hasViewedBfoRequirements) {
      this.showModal.next({modalName: BFO_REQUIREMENTS_MODAL_NAME});
      this.hasViewedBfoRequirements = true;
    }
  }

  closeModal() {
    this.showModal.next({modalName: ''});
  }
}
