import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../../environments/environment';
import {tap} from 'rxjs/operators';

export abstract class AbstractTokenHeaderInterceptor implements HttpInterceptor {

  protected abstract getTokenKey(): string;
  protected abstract getTokenValue(): string;
  protected abstract updateTokenValue(value);

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!req.url.startsWith(environment.lynxApi) && !req.url.startsWith(environment.lynxApiV2)) {
      return next.handle(req);
    } else if (req.url.endsWith('/sessions')) {
      return next.handle(req).pipe(
        tap((event: any) => {
          if (event instanceof HttpResponse) {
            this.updateTokenValue(event.headers.get(this.getTokenKey()));
          }
        }),
      );
    } else {
      return next.handle(this.addToken(req, this.getTokenValue()))
    }
  }

  protected addToken(req: HttpRequest<any>, token: string) {
    const headers = {};
    headers[this.getTokenKey()] = token;

    return req.clone({setHeaders: headers});
  }

}