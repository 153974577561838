import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {HttpService} from '../../../services/http.service';
import {catchError, filter, map} from 'rxjs/operators';
import {HttpResponse} from '@angular/common/http';
import {TrackingServiceConfig} from './analytics-tracking-types';


const URI = `/analytics-tracking`;

@Injectable({providedIn: 'root'})
export class AnalyticsTrackingConfigService {

  constructor(private httpService: HttpService) {
  }
  public getTrackingServiceConfigs(): Observable<TrackingServiceConfig[]> {
    return this.httpService.get(URI, {observe: 'response'}).pipe(
      filter(response => response.status === 200),
      map((response: HttpResponse<TrackingServiceConfig[]>) => {
        return response.body;
      }),
      filter((configs: TrackingServiceConfig[]) => !!configs && configs.length > 0),
      catchError(err => of(err))
    )
  }
}