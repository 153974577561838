import {Component} from '@angular/core';
import {Observable, Subscription} from 'rxjs';
import {FooterService} from './footer.service';
import {map} from "rxjs/operators";
import {PromoConfigurationService} from "../../services/promo-configuration.service";

@Component({
    selector: 'lynx-footer',
    templateUrl: './footer.component.html'
})
export class FooterComponent {
    $copyrightLanguage: Observable<string> = this.footerService.getCopyrightLanguage();

    private partnerCopyrightLanguageSubscription: Subscription;
    partnerCopyrightLanguage: string | undefined;

    constructor(private footerService: FooterService, private promoConfigurationService: PromoConfigurationService) {
    }

    ngOnInit(): void {
        this.partnerCopyrightLanguageSubscription = this.promoConfigurationService.promoConfiguration.pipe(
            map(promoConfig => promoConfig.partnerConfiguration.copyright),
        ).subscribe(copyright => {
            this.partnerCopyrightLanguage = copyright;
        });
    }
    ngOnDestroy(): void {
        if (this.partnerCopyrightLanguageSubscription) {
            this.partnerCopyrightLanguageSubscription.unsubscribe();
        }
    }
}
