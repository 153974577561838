import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class EquityInterestService {

  public hasEquityInterest: boolean;

  constructor() {}
}
