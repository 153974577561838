export class CompanyInfo {
  companyName: string;
  companyRewardsNumber: string;
  mailingAddressLine1: string;
  mailingAddressLine2: string;
  mailingAddressCity: string;
  mailingAddressState: string;
  mailingAddressZip: string;
  nameToBeEmbossedOnCard: string;
  phoneNumber: string;
  physicalAddressLine1: string;
  physicalAddressLine2: string;
  physicalAddressCity: string;
  physicalAddressState: string;
  physicalAddressZip: string;
  taxId: string;
  yearBusinessStarted: number;
  companyEmailAddress: string;
}
