import {AbstractControl} from '@angular/forms';
import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ErrorDisplay {
  shouldShow(formControl: AbstractControl, formSubmitted: boolean, validateWhenDisabled: boolean = false) {
    if (formControl == null) {
      return false;
    }
    return (formControl.status === 'INVALID' && (formSubmitted || formControl.touched)) ||
      (validateWhenDisabled && formSubmitted && formControl.disabled && !formControl.valid);
  }
}
