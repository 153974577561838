import {Injectable} from '@angular/core';
import {Action, Page} from '../action-determiner';
import {ActionDispatcher} from '../action-dispatcher';
import {ModalService} from '../../modalService.service';

@Injectable({providedIn: 'root'})
export class ModalActionHandler {
    constructor(private dispatcher: ActionDispatcher, private modalService: ModalService) {
        this.dispatcher.$actions.subscribe(this.handle);
    }

    handle = (action: Action) => {
        if (action.type === 'MODAL') {
            this.modalService.openModal(toModal(action.metaData.which), action.metaData.parameter);
        }
    }

}

function toModal(which: Page) {
    if (which === 'ADDRESS_VERIFICATION') {
        return 'addressVerification';
    } else if (which === 'PASSCODE_AUTHORIZATION') {
        return 'passcodeAuthorization';
    }
}