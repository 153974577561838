import {Component, OnInit, Renderer2} from '@angular/core';
import {SessionTimeoutService} from '../../../shared/services/sessionTimeout.service';
import {Router} from '@angular/router';
import {environment} from '../../../../../environments/environment';
import {ModalService} from '../../../shared/services/modalService.service';
import {BaseModalComponent} from '../../../shared/components/modal/base-modal.component';
import {SessionService} from '../../../shared/services/session.service';
import {Subject} from 'rxjs';

@Component({
  selector: 'session-timeout-warning',
  templateUrl: './session-timeout-warning.component.html',
  styleUrls: ['../../../shared/components/modal/modal.scss', './session-timeout-warning.component.scss']
})
export class SessionTimeoutWarningComponent extends BaseModalComponent implements OnInit {
  displayedTimeoutTime: number = 60;
  private keepWorking$: Subject<boolean>;
  private sessionEnded$: Subject<boolean>;

  constructor(
    private sessionTimeoutService: SessionTimeoutService,
    private router: Router,
    modalService: ModalService,
    private sessionService: SessionService,
    renderer: Renderer2
  ) {
    super(renderer, modalService);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.keepWorking$ = new Subject<boolean>();
    this.sessionEnded$ = new Subject<boolean>();
    this.sessionTimeoutService
      .countDownTimer(1000 * this.displayedTimeoutTime, this.keepWorking$, this.sessionEnded$)
      .subscribe(
        sec => this.displayedTimeoutTime = sec,
        this.timerCancelled.bind(this),
        this.timerCompleted.bind(this),
      );
  }

  keepWorking() {
    this.keepWorking$.next(true);
  }

  endSession() {
    this.sessionEnded$.next(true);
  }

  private timerCancelled() {
    this.modalService.closeModal();
    this.sessionTimeoutService.restart(() => {
      this.modalService.openModal('sessionTimeout');
    });
    // Startup event listeners to restart timeout counter
    this.sessionTimeoutService.startListeners.next(null);
  }

  private timerCompleted() {
    this.modalService.closeModal();
    this.router.navigate(['/timeout']);
  }
}