import {Directive, OnDestroy, OnInit} from '@angular/core';
import {PromoConfigurationService} from '../../../shared/services/promo-configuration.service';
import {Subject} from 'rxjs';
import {filter, map, takeUntil} from 'rxjs/operators';
import {GeolocationService} from './geolocation.service';
import {ModalService} from "../../../shared/services/modalService.service";
import {RESULTSTATE} from '../../../shared/models/geolocation-type';


@Directive({
    selector: '[useYourGeoLocationPopUp]'
})
export class GeolocationDirective implements OnInit, OnDestroy {
    private destroy$: Subject<void> = new Subject<void>();

    constructor(
        private promoConfigService: PromoConfigurationService,
        private geolocationService: GeolocationService,
        private modalService: ModalService
    ) {}

    ngOnInit(): void {
        this.promoConfigService.promoConfiguration.pipe(
            map(config => config.enableGeofencing),
            filter(enableGeofencing => enableGeofencing),
            takeUntil(this.destroy$))
            .subscribe(() => {
                if (this.geolocationService.getResultState() === RESULTSTATE.NOT_REQUESTED) {
                    this.modalService.openModal('geolocationAuthorization');
                }
            })
    }

    ngOnDestroy() {
        this.destroy$.next();
    }
}