import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ReactiveTextFieldComponent} from './forms/reactive-text-field/reactive-text-field.component';
import {ReactiveSelectFieldComponent} from './forms/reactive-select-field/reactive-select-field.component';
import {PhoneNumberFieldComponent} from './forms/phone-number-field/phone-number-field.component';
import {DateFieldComponent} from './forms/date-field/date-field.component';
import {DatePipe} from './pipes/date.pipe';
import {CurrencyFieldComponent} from './forms/currency-field/currency-field.component';
import {SsnFieldComponent} from './forms/ssn-field/ssn-field.component';
import {AgentSubagentFieldComponent} from './forms/agent-subagent-field/agent-subagent-field.component';
import {RadioGroupComponent} from './forms/radio-group-field/radio-group.component';
import {
  ReactiveStateSelectFieldComponent
} from './forms/reactive-state-select-field/reactive-state-select-field.component';
import {InlineSelectComponent} from './forms/inline-select/inline-select.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {TextMaskModule} from 'angular2-text-mask';
import {TrackFocusDirective} from './directives/track-focus/track-focus.directive';
import {TextFieldComponent} from './forms/text-field/text-field.component';
import {NonblankDirective} from './directives/nonblank/nonblank.directive';
import {SelectFieldComponent} from './forms/select-field/select-field.component';
import {StateSelectFieldComponent} from './forms/select-field/state-select-field.component';
import {NoWrapPhoneNumberPipe} from './pipes/no-wrap-phone-number.pipe';
import {OrEmDashPipe} from './pipes/or-em-dash.pipe';
import {PhoneNumberPipe} from './pipes/phone-number.pipe';
import {SuperscriptSymbolPipe} from './pipes/superscript-symbol.pipe';
import {SsnMaskPipe} from './pipes/ssn-mask.pipe';
import {ZipCodePipe} from './pipes/zip-code.pipe';
import {PrefixMaskPipe} from './pipes/prefix-mask.pipe';
import {LoadingSpinnerComponent} from './ui/loading-spinner/loading-spinner.component';
import {ReactiveTextAreaFieldComponent} from './forms/reactive-text-area-field/reactive-text-area-field.component';
import {NgbTooltipModule} from '@ng-bootstrap/ng-bootstrap';
import {InfoTooltipComponent} from './ui/info-tooltip/info-tooltip.component';
import {LoaderComponent} from './ui/loader/loader.component';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    TextMaskModule,
    NgbTooltipModule,
  ],
  declarations: [
    ReactiveTextFieldComponent,
    ReactiveSelectFieldComponent,
    PhoneNumberFieldComponent,
    DateFieldComponent,
    DatePipe,
    CurrencyFieldComponent,
    SsnFieldComponent,
    AgentSubagentFieldComponent,
    RadioGroupComponent,
    ReactiveStateSelectFieldComponent,
    InlineSelectComponent,
    TrackFocusDirective,
    TextFieldComponent,
    NonblankDirective,
    SelectFieldComponent,
    StateSelectFieldComponent,
    LoaderComponent,
    LoadingSpinnerComponent,
    NoWrapPhoneNumberPipe,
    OrEmDashPipe,
    PhoneNumberPipe,
    SuperscriptSymbolPipe,
    SsnMaskPipe,
    ZipCodePipe,
    PrefixMaskPipe,
    ReactiveTextAreaFieldComponent,
    InfoTooltipComponent
  ],
  exports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    TextMaskModule,
    ReactiveTextFieldComponent,
    ReactiveSelectFieldComponent,
    PhoneNumberFieldComponent,
    DateFieldComponent,
    DatePipe,
    CurrencyFieldComponent,
    SsnFieldComponent,
    AgentSubagentFieldComponent,
    RadioGroupComponent,
    ReactiveStateSelectFieldComponent,
    InlineSelectComponent,
    TrackFocusDirective,
    TextFieldComponent,
    NonblankDirective,
    SelectFieldComponent,
    StateSelectFieldComponent,
    LoaderComponent,
    LoadingSpinnerComponent,
    NoWrapPhoneNumberPipe,
    OrEmDashPipe,
    PhoneNumberPipe,
    SuperscriptSymbolPipe,
    SsnMaskPipe,
    ZipCodePipe,
    PrefixMaskPipe,
    ReactiveTextAreaFieldComponent,
    InfoTooltipComponent
  ]
})
export class LynxCommonModule {}
