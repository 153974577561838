import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpRequest, HttpResponse} from '@angular/common/http';

import {Observable, of, throwError} from 'rxjs';
import {catchError, switchMap} from 'rxjs/operators';
import {TokenHolder} from './token-holder.service';
import {AbstractTokenHeaderInterceptor} from './abstract-token-header-interceptor';

const CSRF_TOKEN_KEY_NAME = 'syncToken';

@Injectable({
  providedIn: 'root'
})
export class CSRFTokenInterceptor extends AbstractTokenHeaderInterceptor {

  protected getTokenKey(): string {
    return CSRF_TOKEN_KEY_NAME;
  }

  protected getTokenValue(): string {
    return this.tokenHolder.getToken(CSRF_TOKEN_KEY_NAME) ;
  }

  protected updateTokenValue(value) {
    this.tokenHolder.setToken(CSRF_TOKEN_KEY_NAME, value);
  }

  constructor(private tokenHolder: TokenHolder) {
    super();
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return super.intercept(req, next).pipe(
      switchMap(event => {
        if (event instanceof HttpResponse) {
            this.updateToken(event);
        }
        return of(event);
      }),
      catchError(error => {
          this.updateToken(error);
          return throwError(error);
        }
      )
    );
  }

  private updateToken(event) {
    const token = event.headers.get(CSRF_TOKEN_KEY_NAME) || this.getTokenValue();
    this.updateTokenValue(token);
  }
}
