import {Component, OnInit, Renderer2} from '@angular/core';
import {BaseModalComponent} from '../base-modal.component';
import {ModalService} from '../../../services/modalService.service';

@Component({
  selector: 'pending-terms',
  templateUrl: './pending-terms.component.html',
  styleUrls: ['../modal.scss', './pending-terms.component.scss']
})
export class PendingTermsComponent extends BaseModalComponent implements OnInit {

  constructor(private renderer: Renderer2, modalService: ModalService) {
    super(renderer, modalService);
  }
}
