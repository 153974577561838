import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {NotFoundComponent} from './shared/components/not-found/not-found.component';
import {ErrorComponent} from './shared/components/error/error.component';
import {TimeoutComponent} from './shared/components/timeout/timeout.component';
import {ProcessingComponent} from './shared/components/processing/processing.component';
import {ApprovedComponent} from './shared/components/approved/approved.component';
import {PendingComponent} from './shared/components/pending/pending.component';
import {ConsumerStarterComponent} from './consumer/consumer-starter/consumer-starter.component';
import {CommercialStarterComponent} from './commercial/commercial-starter/commercial-starter.component';

export const appRoutes: Routes = [
  {
    path: 'consumer-starter',
    component: ConsumerStarterComponent
  },
  {
    path: 'commercial-starter',
    component: CommercialStarterComponent
  },
  {
    path: 'business',
    loadChildren: () => import('./commercial/lynx-commercial.module').then(m => m.LynxCommercialModule)
  },
  {
    path: '',
    loadChildren: () => import('./consumer/lynx-consumer.module').then(m => m.LynxConsumerModule)
  },
  {
    path: 'pre-qual',
    loadChildren: () => import('./pre-qual/lynx-pre-qual.module').then(m => m.LynxPreQualModule)
  },
  {
    path: 'timeout',
    component: TimeoutComponent
  },
  {
    path: 'processing',
    component: ProcessingComponent
  },
  {
    path: 'approved',
    component: ApprovedComponent
  },
  {
    path: 'pending',
    component: PendingComponent
  },
  {
    path: 'declined-due-to-existing-account',
    component: PendingComponent
  },
  {
    path: '404',
    component: NotFoundComponent,
  },
  {
    path: 'error',
    component: ErrorComponent
  },
  {
    path: '**',
    redirectTo: '404',
  }
];

@NgModule({
  imports: [RouterModule.forRoot(
    appRoutes, {useHash: true})],
  exports: [
    RouterModule
  ]
})
export class LynxUiRoutingModule {
}
