import {AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {AbstractControl, FormGroup, Validators} from '@angular/forms';
import {poBoxValidator} from '../../validators/form-field-validators';
import {isFormEmpty} from '../../utils/form-util';
import {RequiredNonBlankValidator} from '../../validators/required-non-blank.validator';
import {Subscription} from 'rxjs';
import {OTHER_INTERNATIONAL} from '../../../commercial/bfo/bfo.component';
import {SelectOption} from '../../../../common/models/select-option';

@Component({
  selector: 'lynx-address',
  templateUrl: './address.component.html',
  styleUrls: ['./address.component.scss']
})
export class AddressComponent implements AfterViewInit, OnInit, OnDestroy {
  @Input() fGroup: FormGroup;
  // Prefix
  @Input() fieldId: string;
  @Input() submitted: boolean;
  @Input() labelText: string;
  @Input() poBoxAllowed = false;
  @Input() errorLabelPrefix = '';
  @Input() defaultOptionSelectable = false;
  @Input() customStateOptions: SelectOption[];
  @Input() countryOptions: SelectOption[];
  @Input() customCityCss = '';
  @Input() customStateCss = '';
  @Input() hasValueChangesFromParent = false;
  @Input() stateFormGroupName = 'state';
  @Input() zipCodeFormGroupName = 'zipCode';
  @Input() countryFormGroupName = 'country';
  @Input() parentFormGroup: FormGroup;
  @Input() hasCountryDropdown = false;

  @Input() addressLine1ReadOnly = false;
  @Input() addressLine2ReadOnly = false;
  @Input() cityReadOnly = false;
  @Input() stateReadOnly = false;
  @Input() zipCodeReadOnly = false;

  showCountryDropdown = false;
  showZipCodeDropdown = true;
  @Output() onStateChanged: EventEmitter <object> = new EventEmitter();

  private stateValueChangeSubscription: Subscription;
  private mapOfValidators;

  alphaOnlyErrorMessages: object = {
    pattern: 'Please enter letters only. Special characters are not accepted.'
  };
  countryControl: AbstractControl;

  ngOnInit(): void {
    if (this.hasCountryDropdown) {
      const stateControl: AbstractControl = this.fGroup.get(this.stateFormGroupName);
      this.handleInternational(stateControl.value);
      this.stateValueChangeSubscription = stateControl.valueChanges.subscribe((value) => {
        this.handleInternational(value);
      });
    }
  }

  private handleInternational(value) {
    const isInternational = value === OTHER_INTERNATIONAL;
    this.onStateChanged.emit({value, isInternational: isInternational});
    this.countryControl = this.fGroup.get(this.countryFormGroupName);
    this.showCountryDropdown = isInternational;
    this.showZipCodeDropdown = !isInternational;
  }

  // Zip Code control gets a pattern validator only after the view is created
  ngAfterViewInit() {
    if (!this.poBoxAllowed) {
      if (this.fGroup.get('addressLine1').validator) {
        this.fGroup.get('addressLine1').setValidators([this.fGroup.get('addressLine1').validator, poBoxValidator()]);
      } else {
        this.fGroup.get('addressLine1').setValidators([poBoxValidator()]);
      }
    }

    this.mapOfValidators = Object.keys(this.fGroup.controls).reduce((tempMap, controlName) => {
      tempMap[controlName] = this.fGroup.get(controlName).validator;
      return tempMap;
    }, {});

    const isRequired: boolean = Object.keys(this.fGroup.controls).map(key => this.fGroup.controls[key]).reduce((result, c) => {
      return result || c.hasError('required');
    }, false);

    if (!isRequired) {
      if(this.hasValueChangesFromParent) {
        this.parentFormGroup.valueChanges.subscribe(changes => {
          this.updateRequired(changes);
        });
      }
      else {
        this.fGroup.valueChanges.subscribe(changes => {
          this.updateRequired(changes);
        });
      }
    }
  }

  ngOnDestroy(): void {
    if(this.stateValueChangeSubscription) {
      this.stateValueChangeSubscription.unsubscribe();
    }
  }

  private updateRequired(changes) {
    if (!isFormEmpty(changes)) {
      Object.keys(this.fGroup.controls)
        .forEach(key => {
          if (key != 'addressLine2') {
            const control = this.fGroup.controls[key];
            control.setValidators(Validators.compose([RequiredNonBlankValidator.valid(), this.mapOfValidators[key]]));
            control.updateValueAndValidity({emitEvent: false});
          }
        });
    } else {
      Object.keys(this.fGroup.controls)
        .forEach(key => {
          const control = this.fGroup.controls[key];
          control.setValidators(Validators.compose([this.mapOfValidators[key]]));
          control.updateValueAndValidity({emitEvent: false});
        });
    }
  }

}
