import {AfterViewInit, Component, EventEmitter, Input, OnDestroy, Output, SimpleChanges} from '@angular/core';
import {ErrorDisplay} from '../../services/error-display';
import {ScrollToableFields} from '../scroll-to-able-fields';
import {BaseReactiveFieldComponent} from '../base-reactive-field/base-reactive-field-component';
import {Global} from '../../global';
import {Subscription} from 'rxjs';

@Component({
  selector: 'phone-number-field',
  templateUrl: './phone-number-field.component.html',
  styleUrls: ['./phone-number-field.component.scss']
})
export class PhoneNumberFieldComponent extends BaseReactiveFieldComponent implements AfterViewInit, ScrollToableFields, OnDestroy {
  mask = ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];

  @Input() submitted: boolean;
  @Input() defaultValue: string;
  @Input() fieldId: string;
  @Input() readOnly = false;
  @Input() hasFormGroupBehavior = true;
  @Input() isGroupInvalid = false;
  @Input() explanatoryText: string;
  @Output() focusChange: EventEmitter<boolean> = new EventEmitter();
  @Output() ngModelChange: EventEmitter<any> = new EventEmitter();

  private valueChangesSubscription: Subscription;

  constructor(public errorDisplay: ErrorDisplay, protected global: Global) {
    super(global);
  }

  ngOnInit() {
    this.valueChangesSubscription = this.control.valueChanges.subscribe((value: string) => {
      let sanitizedValue = value;
      if (value) {
        sanitizedValue = value.replace(/\D/g, '').slice(0, 10);
      }
      this.control.setValue(sanitizedValue, {emitEvent: false});
      this.ngModelChange.emit(sanitizedValue);
    });
  }

  ngOnDestroy(): void {
    if (this.valueChangesSubscription) {
      this.valueChangesSubscription.unsubscribe();
    }
  }

  ngAfterViewInit(): void {
    if (this.control.value) {
      this.control.updateValueAndValidity();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.disabled) {
      changes.disabled.currentValue ? this.control.disable() : this.control.enable();
    }
  }

  getMinimumLengthMessage() {
    return 'Please enter a 10 digit number';
  }

  onFocus() {
    if(this.focusChange) {
      this.focusChange.emit(true);
    }
  }

  onBlur() {
    this.focusChange.emit(false);
  }
}
