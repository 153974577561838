import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {PromoConfigurationService} from '../shared/services/promo-configuration.service';

@Component({
  selector: 'application-form',
  templateUrl: './application-form.component.html',
  styleUrls: ['./application-form.scss']
})
export class ApplicationForm implements OnInit {
  routesNotRequiringShortCode = [
    /^\/apply/,
    /^\/close/,
    /^\/direct-mail/,
    /^\/takeone/,
    /^\/soct/,
    /^\/terms\/.+/,
    /^\/error/,
    /^\/timeout/,
    /^.+starter/,
    /^\/pre-qual\/.+/,
  ];

  @ViewChild('top') top: ElementRef;

  constructor(
    private router: Router,
    private promoConfigurationService: PromoConfigurationService
    ) {
  }

  ngOnInit(): void {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        const url = event.urlAfterRedirects;
        if (this.pathRequiresShortCode(url) && !this.promoConfigurationService.shortCode) {
          this.router.navigate(['/404']);
        }

        if (!!this.top) {
          this.top.nativeElement.scrollIntoView();
        }
      }
    });
  }

  private pathRequiresShortCode(url) {
    return !this.routesNotRequiringShortCode.find(allowedRoute => {
      const match = url.match(allowedRoute);
      return !!match;
    });
  }

}
