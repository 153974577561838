import {Injectable} from '@angular/core';
import * as MarkdownIt from 'markdown-it';
import {Options} from 'markdown-it';
import * as iterator from 'markdown-it-for-inline';
import * as sanitizer from 'markdown-it-sanitizer';
import {PromoConfiguration} from './promo-configuration.service';

@Injectable({
  providedIn: 'root'
})
export class MarkdownService {
  private md: MarkdownIt.MarkdownIt;

  constructor() {
    this.md = new MarkdownIt(MarkdownService.defaultOpts())
      .use(sanitizer)
      .use(iterator, 'cleanup_links', 'link_open', (tokens, index) => {
        const token = tokens[index];
        token.attrPush(['target', '_blank']);
        token.attrPush(['rel', 'noopener noreferrer']);
        token.attrPush(['title', 'Opens in new window']);
      });
  }

  renderInline(content: string, config?: Options): string {
    if (config) {
      this.md.set(config);
    }
    const result = this.md.renderInline(content);
    this.md.set(MarkdownService.defaultOpts());
    return result;
  }

  render(content: string): string | null {
    if (content === null) {
      return null;
    }
    return this.md.render(content)
  }

  renderWithinPromoConfig(content: string, promoConfig: PromoConfiguration) {
    const state = this.renderReferenceLinks({'Summary Of Credit Terms': promoConfig.soctUrl});
    return this.md.render(content, state);
  }

  private renderReferenceLinks(linkMappings: { [p: string]: string }) {
    const state = {};
    for (let key in linkMappings) {
      this.md.parse(`[${key}]: ${linkMappings[key]}`, state);
    }
    return state;
  }

  private static defaultOpts() {
    return {html: true};
  }
}
