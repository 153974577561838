import {Component, OnDestroy, OnInit} from '@angular/core';
import {NavigationStart, Router} from '@angular/router';
import {SessionService} from '../../services/session.service';
import {SessionTimeoutService} from '../../services/sessionTimeout.service';
import {Subscription} from 'rxjs';
import {PromoConfigurationService} from '../../services/promo-configuration.service';

@Component({
  selector: 'timeout',
  templateUrl: './timeout.component.html',
  styleUrls: ['./timeout.component.scss']
})

export class TimeoutComponent implements OnInit, OnDestroy {
  private routerEventsSubscription: Subscription;

  constructor(
    private router: Router,
    private sessionService: SessionService,
    private sessionTimeoutService: SessionTimeoutService,
    private promoConfigurationService: PromoConfigurationService) {
  }

  ngOnInit(): void {
    this.routerEventsSubscription = this.router.events.subscribe(event => {
      if(event instanceof NavigationStart) {
        this.promoConfigurationService.invalidateShortCode();
      }
    });

    this.sessionService.endSession();
  }

  routeToRestartPage() {
    if(this.routerEventsSubscription) {
      this.routerEventsSubscription.unsubscribe();
    }
    this.sessionService.startSession();

    const restartPage = this.sessionTimeoutService.getOnTimeoutNavigateTo();
    this.router.navigate([restartPage]);
  }

  ngOnDestroy(): void {
    if(this.routerEventsSubscription){
      this.routerEventsSubscription.unsubscribe();
    }
  }
}
