import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {PromoField} from '../../services/promo-configuration.service';
import {AbstractControl, FormControl, Validators} from '@angular/forms';
import {FormFieldValidators} from '../../validators/form-field-validators';
import {Subscription} from 'rxjs';
import {SelectOption} from '../../../../common/models/select-option';

@Component({
  selector: 'lynx-customer-id-fields',
  templateUrl: './customer-id-fields.component.html',
  styleUrls: ['./customer-id-fields.component.scss']
})
export class CustomerIdFieldsComponent implements OnInit, OnDestroy {
  @Input() submitted: boolean;
  @Input() customerIdFields: PromoField;
  @Input() customerIdType: string;
  @Input() customerIdIssuanceState: string;
  @Input() customerIdNumber: string;
  @Input() requiredErrorMessage: string;

  @Output() customerIdControlsCreated: EventEmitter <CustomerIdControls> = new EventEmitter();

  customerIdTypeControl: FormControl;
  customerIdIssuanceStateControl: FormControl;
  customerIdNumberControl: FormControl;
  controls: CustomerIdControls;
  customerIdTypeOptions: SelectOption[] = [
    {value: 'Driver\'s License'},
    {value: 'State ID'},
    {value: 'Military ID'}
  ];
  private controllerChangesSubscription: Subscription[] = [];

  private customerIdValidators = {
    required: {
      customerIdType: [Validators.required],
      customerIdIssuanceState: [],
      customerIdNumber: [Validators.required, this.formFieldValidators.validateNotBlank()]
    },
    optional: {
      customerIdType: [],
      customerIdIssuanceState: [],
      customerIdNumber: []
    }
  };

  constructor(private formFieldValidators: FormFieldValidators) { }

  updateValidity(isRequired: boolean) {
    const validators = isRequired ? this.customerIdValidators.required : this.customerIdValidators.optional;
    Object.keys(validators).forEach(k => {
      const control = (this.controls[k] as AbstractControl);
      control.setValidators(validators[k]);
      control.updateValueAndValidity({emitEvent: false});
    });
  }

  ngOnInit() {
    if(this.customerIdFields) {
      this.customerIdTypeControl = new FormControl<string|null>(this.customerIdType, this.customerIdValidators.optional.customerIdType);
      this.customerIdIssuanceStateControl = new FormControl<string|null>(this.customerIdIssuanceState, this.customerIdValidators.optional.customerIdIssuanceState);
      this.customerIdNumberControl = new FormControl<string|null>(this.customerIdNumber, this.customerIdValidators.optional.customerIdNumber);

      this.controls = {
        customerIdType: this.customerIdTypeControl,
        customerIdIssuanceState: this.customerIdIssuanceStateControl,
        customerIdNumber: this.customerIdNumberControl
      };

      if(this.customerIdFields.locked) {
        this.customerIdTypeControl.disable();
        this.customerIdIssuanceStateControl.disable();
      }

      if(this.customerIdFields.required) {
        this.updateValidity(true);
      } else {
        this.handleConditionalRequiredValidation();
      }

      if(!this.customerIdFields.required) {
        this.handleConditionalRequiredValidation();
        Object.values(this.controls).forEach(control => {
          this.listenForChanges(control);
        });
      } else {
        this.updateValidity(true);
      }

      this.customerIdControlsCreated.emit(this.controls);
    }
  }

  ngOnDestroy(): void {
    this.controllerChangesSubscription.forEach(subscription => {
      subscription.unsubscribe();
    });
  }

  private listenForChanges(control) {
    const subscription = control.valueChanges.subscribe(() => {
      this.handleConditionalRequiredValidation();
    });
    this.controllerChangesSubscription.push(subscription);
  }

  private handleConditionalRequiredValidation() {
    const hasValues = Object.values(this.controls).find(control => {
      return control.value !== null && control.value !== undefined && control.value.length;
    });
    this.updateValidity(hasValues);
  }
}

export interface CustomerIdControls {
  customerIdType: FormControl<string|null>;
  customerIdIssuanceState: FormControl<string|null>;
  customerIdNumber: FormControl<string|null>;
}
