import {Component} from '@angular/core';
import {SelectFieldComponent} from './select-field.component';

@Component({
  selector: 'state-select-field',
  templateUrl: './select-field.component.html',
  styles: ['select.form-control:not([size]):not([multiple]) { height: 44px; }']
})
export class StateSelectFieldComponent extends SelectFieldComponent {
  options = [
    'AL',
    'AK',
    'AZ',
    'AR',
    'CA',
    'CO',
    'CT',
    'DE',
    'DC',
    'FL',
    'GA',
    'HI',
    'ID',
    'IL',
    'IN',
    'IA',
    'KS',
    'KY',
    'LA',
    'ME',
    'MA',
    'MD',
    'MI',
    'MN',
    'MS',
    'MO',
    'MT',
    'NE',
    'NV',
    'NH',
    'NJ',
    'NM',
    'NY',
    'NC',
    'ND',
    'OH',
    'OK',
    'OR',
    'PA',
    'RI',
    'SC',
    'SD',
    'TN',
    'TX',
    'UT',
    'VT',
    'VA',
    'WA',
    'WV',
    'WI',
    'WY',
    'AA',
    'AE',
    'AP'
  ];
}