import {Component, OnDestroy, OnInit} from '@angular/core';
import {NavigationStart, Router} from '@angular/router';
import {ErrorConfig, ErrorConfigService} from './config/error-config.service';
import {PromoConfigurationService} from '../../services/promo-configuration.service';
import {SessionService} from '../../services/session.service';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss']
})
export class ErrorComponent implements OnInit, OnDestroy {
  title: string;
  message: string;
  showActionButton: boolean;
  actionButtonRoute: string;
  private routerEventsSubscription: Subscription;
  sessionId: string;

  constructor(
    private router: Router,
    private errorConfigService: ErrorConfigService,
    private promoConfigurationService: PromoConfigurationService,
    private sessionService: SessionService
  ) {}

  ngOnInit() {
    this.routerEventsSubscription = this.router.events.subscribe(event => {
      if(event instanceof NavigationStart) {
        this.promoConfigurationService.invalidateShortCode();
      }
    });

    this.setDefaultConfig();
    const overrideConfig = this.errorConfigService.getConfig();
    if (overrideConfig) {
      this.setConfig(overrideConfig);
    }
  }

  setDefaultConfig() {
    this.title = 'Sorry!';
    this.sessionId=this.sessionService.getLynxSessionId();
    this.message = 'Something went wrong on our end and we weren\'t able to submit your application. ' +
      'A fresh start should help. Let\'s try that again.<br>';
    if(this.sessionId)
       this.message+=`For support: Please include this session ID when reporting the issue to FNBO. Lynx Session ID:${this.sessionId}`;
    this.showActionButton = true;
    this.actionButtonRoute = '/personal';
  }

  private setConfig(errorConfig: ErrorConfig) {
    if (errorConfig.title !== undefined) {
      this.title = errorConfig.title;
    }
    if (errorConfig.message !== undefined) {
      this.message = errorConfig.message;
    }
    if (errorConfig.showActionButton !== undefined) {
      this.showActionButton = errorConfig.showActionButton;
    }
    if (errorConfig.actionButtonRoute !== undefined) {
      this.actionButtonRoute = errorConfig.actionButtonRoute;
    }
    this.errorConfigService.setConfig(null);
  }

  goToRoute() {
    if(this.routerEventsSubscription) {
      this.routerEventsSubscription.unsubscribe();
    }

    this.sessionService.endSession();
    this.sessionService.startSession();
    this.router.navigate([this.actionButtonRoute]);
  }

  ngOnDestroy(): void {
    if(this.routerEventsSubscription) {
      this.routerEventsSubscription.unsubscribe();
    }
  }
}
