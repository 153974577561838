export class Representative {
  firstName: string;
  middleInitial: string;
  lastName: string;
  addressLine1: string;
  addressLine2: string;
  addressCity: string;
  addressState: string;
  addressZip: string;
  homePhoneNumber: string;
  emailAddress: string;
  annualIncome: number;
  dob: string;
  ssn: string;
  authUserDisclosureAgree: boolean;
  customerIdIssuanceState: string;
  customerIdType: string;
  customerIdNumber: string;
}
