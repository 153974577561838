export abstract class AbstractScriptInjectorService {
  scriptSourcesInjected: string[] = [];

  constructor(protected _window) {
  }

  /**
   * Injects a script, and resolves the promise when it finished loading. Will only load a script once
   * per browser session. Duplicate calls with the same scriptSrc have no effect.
   * @param scriptSrc the URI of the script to load
   */
  async injectAndLoadScript(scriptSrc: string) {
    if (typeof scriptSrc === 'undefined' || scriptSrc === '') {
      return;
    }
    if (this.scriptSourcesInjected.includes(scriptSrc)) {
      return;
    }

    this.scriptSourcesInjected.push(scriptSrc);
    await new Promise<void>((resolve, reject) => {
      const doc = this._window.document;

      let firstScriptElement;
      let scriptToInsert = this.createScriptElement(doc, scriptSrc);
      if (doc.addEventListener) {
        scriptToInsert.addEventListener('load', () => resolve(), false);
        scriptToInsert.addEventListener('error', () => reject(new Error(`script blocked by client`)), false)
      } else {
        // old IE support
        scriptToInsert.onreadystatechange = function () {
          if (this.readyState === 'complete' || this.readyState === 'loaded') {
            this.onreadystatechange = null;
            resolve();
          }
        };
      }

      firstScriptElement = doc.getElementsByTagName('script')[0];
      firstScriptElement.parentNode.insertBefore(scriptToInsert, firstScriptElement);
    });
  }

   abstract createScriptElement(doc: Document, scriptSrc: string);
}
