import {Component, Input, OnInit} from '@angular/core';
import {FormArray, FormBuilder} from '@angular/forms';

@Component({
  selector: 'lynx-balance-transfer-list',
  templateUrl: './balance-transfer-list.component.html',
  styleUrls: ['./balance-transfer-list.component.scss']
})
export class BalanceTransferListComponent implements OnInit {

  @Input() submitted: boolean;
  @Input() btFormArray: FormArray;
  @Input() soctUrl: string;

  constructor(
    private formBuilder: FormBuilder
  ) { }

  ngOnInit() {
    this.btFormArray.push(this.formBuilder.group({}));
    this.btFormArray.push(this.formBuilder.group({}));
    this.btFormArray.push(this.formBuilder.group({}));
  }

}
