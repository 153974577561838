export default class Address {
  static BLANK: Address = new Address({});

  private addressLine1;
  private addressLine2;
  private city;
  private state;
  private zipCode;

  constructor(addr: object) {
    this.setAddressLine1(addr['addressLine1']);
    this.setAddressLine2(addr['addressLine2']);
    this.setCity(addr['city']);
    this.setState(addr['state']);
    this.setZipCode(addr['zipCode']);
  }

  private setAddressLine1(addressLine1: string) {
    if (addressLine1 !== null && addressLine1 != undefined) {
      this.addressLine1 = addressLine1;
    } else {
      this.addressLine1 = '';
    }
  }

  private setAddressLine2(addressLine2: string) {
    if (addressLine2 !== null && addressLine2 != undefined) {
      this.addressLine2 = addressLine2;
    } else {
      this.addressLine2 = '';
    }
  }

  private setCity(city: string) {
    if (city !== null && city != undefined) {
      this.city = city;
    } else {
      this.city = '';
    }
  }

  private setState(state: string) {
    if (state !== null && state != undefined) {
      this.state = state;
    } else {
      this.state = '';
    }
  }

  private setZipCode(zipCode: string) {
    if (zipCode !== null && zipCode != undefined) {
      this.zipCode = zipCode;
    } else {
      this.zipCode = '';
    }
  }

  private equals: (Address) => boolean = (other: Address) => {
    return this.addressLine1 === other.addressLine1 &&
      this.addressLine2 === other.addressLine2 &&
      this.city === other.city &&
      this.state === other.state &&
      this.zipCode === other.zipCode;
  };

  isEmpty(): boolean {
    return !this.addressLine1 &&
      !this.addressLine2 &&
      !this.city &&
      !this.state &&
      !this.zipCode;
  };

  toFormGroup(): {addressLine1: string, addressLine2: string, city: string, state: string, zipCode: string } {
    return {
      addressLine1: this.addressLine1,
      addressLine2: this.addressLine2,
      city: this.city,
      state: this.state,
      zipCode: this.zipCode,
    };
  }

  sameButNotBlank(obj2: Address) {
    return !this.isEmpty() && !obj2.isEmpty() && this.equals(obj2);
  }
}
