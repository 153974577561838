import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FieldsToDisplay, PromoConfigurationService} from '../../services/promo-configuration.service';
import {FormControl, FormGroup, ValidatorFn, Validators} from '@angular/forms';
import {Subscription} from 'rxjs';
import {CommercialApplicationModel} from '../../models/commercial/commercial-application-model';
import {FormFieldValidators} from '../../validators/form-field-validators';
import {DecimalPipe} from '@angular/common';

@Component({
  providers: [DecimalPipe],
  selector: 'lynx-secured-card-deposit',
  templateUrl: './secured-card-deposit.component.html',
  styleUrls: ['./secured-card-deposit.component.scss']
})
export class SecuredCardDepositComponent implements OnInit {
  @Input() showDepositVerification: boolean;
  @Input() showPersonalBankerText: boolean;
  @Input() businessApplication: boolean;
  @Input() minimumDepositAmount: number;
  @Input() maximumDepositAmount: number;
  @Input() submitted: boolean = false;
  @Output() securedCardFormGroupCreated = new EventEmitter<FormGroup>();

  fieldsToDisplay: FieldsToDisplay = {};
  showSecuredDeposit: boolean;
  routingErrorMessages: object = {
    required: 'Please enter a routing number',
    maxlength: 'Please enter a 9 digit number',
    minlength: 'Please enter a 9 digit number'
  };
  verifyRoutingErrorMessages: object = {
    required: 'Please verify the routing number',
    maxlength: 'Please enter a 9 digit number',
    minlength: 'Please enter a 9 digit number',
    noMatch: 'The Routing Numbers do not match. Please enter again.',
  };
  secureDepositAccountNumberErrorMessages: object = {
    required: 'Please enter an account number'
  };
  verifySDAccountNumberErrorMessages: { [key: string]: string } = {
    required: 'Please verify the account number',
    noMatch: 'The Account Numbers do not match. Please enter again.',
  };
  secureDepositAuthCodeErrorMessages: object = {
    required: 'Please enter the Deposit Authorization Code',
    invalidAuthCode: 'The deposit authorization code does not match our records for the applicant. ' +
      'Please verify the code and submit the application again.'
  };
  verifyAuthCodeErrorMessages: { [key: string]: string } = {
    required: 'Please verify the Deposit Authorization Code',
    noMatch: 'The Deposit Authorization Codes do not match. Please enter again.',
  };

  verifyDepositAmountErrorMessages: {};

  secureDepositAmount: FormControl;
  secureDepositAccountType: FormControl;
  secureDepositRoutingNumber: FormControl;
  verifyRoutingNumber: FormControl;
  secureDepositAccountNumber: FormControl;
  verifyAccountNumber: FormControl;
  secureDepositAuthCode: FormControl;
  verifyAuthCode: FormControl;
  private promoSubscription: Subscription;
  securedCardFormGroup: FormGroup = new FormGroup({});


  constructor(
    private promoConfigurationService: PromoConfigurationService,
    private formFieldValidators: FormFieldValidators,
    private ccApp: CommercialApplicationModel,
    private decimalPipe: DecimalPipe
  ) { }

  ngOnInit() {
    this.promoSubscription = this.promoConfigurationService.promoConfiguration.subscribe((promoConfiguration) => {
      this.fieldsToDisplay = promoConfiguration.fieldsToDisplay;
      this.showSecuredDeposit = (!!promoConfiguration.fieldsToDisplay.secureDeposit &&
        promoConfiguration.fieldsToDisplay.secureDeposit.required);
      if (this.showSecuredDeposit) {
        this.verifyDepositAmountErrorMessages = this.getVerifyDepositAmountErrorMessages();

        this.showDepositVerification = (!!promoConfiguration.fieldsToDisplay.depositVerification &&
          promoConfiguration.fieldsToDisplay.depositVerification.required);
      }
    });
    const routingValidators = [Validators.required, Validators.minLength(9), Validators.maxLength(9)];
    const depositAmountValidators = [
      Validators.required,
      this.formFieldValidators.validateWholeDollarFormat(),
      this.formFieldValidators.minCurrencyValidator(this.minimumDepositAmount),
      this.formFieldValidators.maxCurrencyValidator(this.maximumDepositAmount),
      this.formFieldValidators.multiplesValidator(this.getMultipleAmount())
    ];

    this.secureDepositAmount = new FormControl(this.ccApp.securedDepositAmount,
      depositAmountValidators);
    this.securedCardFormGroup.addControl('amount', this.secureDepositAmount);

    this.secureDepositAccountType = new FormControl(this.ccApp.securedDepositAccountType,
      [Validators.required]);
    this.securedCardFormGroup.addControl('accountType', this.secureDepositAccountType);

    this.secureDepositRoutingNumber = new FormControl(this.ccApp.securedDepositRoutingNumber,
      routingValidators);
    this.securedCardFormGroup.addControl('routingNumber', this.secureDepositRoutingNumber);

    this.verifyRoutingNumber = new FormControl(this.ccApp.securedDepositVerifyRoutingNumber,
      []);
    this.verifyRoutingNumber.setValidators([...routingValidators,
      this.matchingVerification(this.secureDepositRoutingNumber, this.verifyRoutingNumber)]);
    this.securedCardFormGroup.addControl('verifyRoutingNumber', this.secureDepositRoutingNumber);

    this.secureDepositAccountNumber = new FormControl(this.ccApp.securedDepositAccountNumber,
      [Validators.required]);
    this.securedCardFormGroup.addControl('accountNumber', this.secureDepositAccountNumber);


    this.verifyAccountNumber = new FormControl(this.ccApp.securedDepositAccountNumber,
      []);
    this.verifyAccountNumber.setValidators([
      Validators.required,
      this.matchingVerification(this.secureDepositAccountNumber, this.verifyAccountNumber)
    ]);
    this.securedCardFormGroup.addControl('verifyAccountNumber', this.verifyAccountNumber);


    if (this.showDepositVerification) {
      this.initializeDepositVerification();
    }

    this.securedCardFormGroupCreated.emit(this.securedCardFormGroup);
  }

  private getMultipleAmount(): number {
    return this.businessApplication ? 100 : 50;
  }

  public getVerifyDepositAmountErrorMessages() {
    return {
      required: 'Please enter an amount to deposit',
      multipleOf50: this.buildErrorMessage(),
      multipleOf100: this.buildErrorMessage(),
      max: this.buildErrorMessage(),
      min: this.buildErrorMessage()
    };
  }

  private buildErrorMessage(): string {
    const multipleAmount = this.getMultipleAmount();
    const formattedMin = this.decimalPipe.transform(this.minimumDepositAmount);
    const formattedMax = this.decimalPipe.transform(this.maximumDepositAmount);
    return `Please enter a value between $${formattedMin} and $${formattedMax} & a multiple of $${multipleAmount}.`;
  }

  private matchingVerification(original: FormControl, verification: FormControl): ValidatorFn {
    original.valueChanges.subscribe(() => verification.updateValueAndValidity());
    return (control) => {
      if (control.value !== original.value) {
        return {noMatch: true};
      }
    };
  }

  private initializeDepositVerification() {
    this.secureDepositAuthCode = new FormControl<string|null>(null, [Validators.required]);
    this.securedCardFormGroup.addControl('depositAuthorizationCode', this.secureDepositAuthCode);

    this.verifyAuthCode = new FormControl<string|null>(null, []);
    this.securedCardFormGroup.addControl('verifyAuthCode', this.verifyAuthCode);

    this.verifyAuthCode.setValidators([
      Validators.required,
      this.matchingVerification(this.secureDepositAuthCode, this.verifyAuthCode)
    ]);
  }

  ngOnDestroy() {
    if (this.promoSubscription) {
      this.promoSubscription.unsubscribe();
    }
  }
}
