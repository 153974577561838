import {Component} from '@angular/core';

@Component({
  selector: 'not-found',
  templateUrl: 'not-found.component.html',
})
export class NotFoundComponent {
  public title = 'Sorry, that page doesn\'t exist.';
  public text = 'The page youre looking for may have been removed, had its name changed, ' +
    'or is temporarily unavailable. Please check the URL and try again.';
}
