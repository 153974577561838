import {Component, OnDestroy, OnInit} from '@angular/core';
import {BaseCommercialFormComponent} from '../base-commercial-form/base-commercial-form.component';
import {
  CommercialConfig,
  PromoConfiguration,
  PromoConfigurationService
} from '../../shared/services/promo-configuration.service';
import {Subscription} from 'rxjs';
import {CommercialApplicationModel, maxNumberOfBfos} from '../../shared/models/commercial/commercial-application-model';
import {Router} from '@angular/router';
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {ModalService} from '../../shared/services/modalService.service';
import {BeneficialOwner} from '../../shared/models/commercial/BeneficialOwner';
import {FormFieldValidators} from '../../shared/validators/form-field-validators';
import {isFormEmpty} from '../../shared/utils/form-util';
import {ControllingParty} from '../../shared/models/ControllingParty';
import {RequiredNonBlankValidator} from '../../shared/validators/required-non-blank.validator';
import {BfoBusinessRules} from '../../shared/models/commercial/BfoBusinessRules';
import {LegalEntityRules} from '../services/LegalEntityRules';
import {LegalEntityRulesService} from '../services/legal-entity-rules.service';
import {toggleFormControl} from '../utils/reactive-form-helpers';
import {EquityInterestService} from '../services/equity-interest.service';
import {Address} from '../../shared/models/Address';

export const OTHER_INTERNATIONAL = 'OTHER_INTERNATIONAL';
const ADDRESS = 'address';

interface BfoControllingPartyForm {
  firstName?: FormControl<string>;
  middleInitial?: FormControl<string>;
  lastName?: FormControl<string>;
  address?: FormControl<Address>;
  dob?: FormControl<string>;
  isUsCitizen?: FormControl<boolean>;
  ssn?: FormControl<string>;
  title?: FormControl<string>;

  countryOfCitizenship: FormControl<string>;
  identificationType: FormControl<string>;
  identificationNumber: FormControl<string>;
  idIssuerState?: FormControl<string>;
  idIssuerCountry?: FormControl<string>;
  idIssueDate: FormControl<string>;
  idExpirationDate: FormControl<string>;
}

@Component({
  selector: 'lynx-bfo',
  templateUrl: './bfo.component.html',
  styleUrls: ['./bfo.component.scss']
})
export class BfoComponent extends BaseCommercialFormComponent implements OnInit, OnDestroy {
  designatedBfoIndex: number = null;

  formGroup: FormGroup;
  private promoSubscription: Subscription;
  private entityRulesSubscription: Subscription;
  commercialConfig: CommercialConfig;
  controllingPartyFormGroup = new FormGroup({} as BfoControllingPartyForm);
  bfoFormGroupArray: FormArray;
  activeBusinessRules: BfoBusinessRules;
  private usCitizenConfig = {
    disabled: {
      countryOfCitizenship: [Validators.required],
      identificationType: [Validators.required],
      identificationNumber: [Validators.required, Validators.maxLength(25), this.formFieldValidators.validateNotBlank(), Validators.pattern('[ -~]*')],
      idIssueDate: this.formFieldValidators.issueDateValidators(true),
      idExpirationDate: this.formFieldValidators.expirationDateValidators(true),
      ssn: [],
      idIssuerState: [Validators.required],
      idIssuerCountry: [Validators.required],
    },
    enabled: {
      countryOfCitizenship: [],
      identificationType: [],
      identificationNumber: [],
      idIssueDate: [],
      idExpirationDate: [],
      idIssuerState: [],
      idIssuerCountry: [],
    }
  };

  private formValidatorsConfig = {
    first: {
      addressLine1: [RequiredNonBlankValidator.valid(), Validators.maxLength(32)],
      addressLine2: [Validators.maxLength(32)],
      city: [RequiredNonBlankValidator.valid(), Validators.maxLength(20), Validators.pattern('[a-zA-Z ]*')],
      state: [Validators.required],
      zipCode: [Validators.required],
      country: [Validators.required],
      firstName: [RequiredNonBlankValidator.valid(), Validators.maxLength(12), Validators.pattern('[a-zA-Z ]*')],
      middleInitial: [Validators.maxLength(1), Validators.pattern('[a-zA-Z]*')],
      lastName: [RequiredNonBlankValidator.valid(), Validators.maxLength(25), Validators.pattern('[a-zA-Z\- ]*')],
      dob: this.formFieldValidators.dateOfBirthValidators(true),
      ssn: this.formFieldValidators.ssnValidators({required: true}),
      isDesignatedControllingParty: [],
      isUsCitizen: [Validators.required]
    },
    additionalDefault: {
      addressLine1: [Validators.maxLength(32)],
      addressLine2: [Validators.maxLength(32)],
      city: [Validators.maxLength(20), Validators.pattern('[a-zA-Z ]*')],
      state: [],
      zipCode: [],
      country: [],
      firstName: [Validators.maxLength(12), Validators.pattern('[a-zA-Z ]*')],
      middleInitial: [Validators.maxLength(1), Validators.pattern('[a-zA-Z]*')],
      lastName: [Validators.maxLength(25), Validators.pattern('[a-zA-Z\- ]*')],
      dob: this.formFieldValidators.dateOfBirthValidators(false),
      ssn: this.formFieldValidators.ssnValidators({required: false}),
      isDesignatedControllingParty: [],
      isUsCitizen: []
    }
  };
  private bfoFormChangesSubscriptions: Subscription[] = [];
  private numberOfBfos: number = maxNumberOfBfos;
  private countryAddressControls: FormControl[] = [];
  private zipCodeAddressControls: FormControl[] = [];

  constructor(
    ccApp: CommercialApplicationModel,
    router: Router,
    private promoConfigurationService: PromoConfigurationService,
    private modalService: ModalService,
    private formBuilder: FormBuilder,
    private formFieldValidators: FormFieldValidators,
    private legalEntityRulesService: LegalEntityRulesService,
    public equityInterestService: EquityInterestService
  ) {
    super(ccApp, router);
  }

  ngOnInit() {
    this.promoSubscription = this.promoConfigurationService.promoConfiguration.subscribe((promoConfiguration: PromoConfiguration) => {
      this.commercialConfig = promoConfiguration.commercialConfig;
    });

    this.entityRulesSubscription = this.legalEntityRulesService.legalEntityRules.subscribe((rules: LegalEntityRules) => {
      this.activeBusinessRules = rules.businessRules;
      if(!this.activeBusinessRules || this.activeBusinessRules && !this.activeBusinessRules.beneficialOwnerRequired) {
        this.numberOfBfos = 0;
      }
    });
    this.setUpBfoAndCpControls();
    this.designatedBfoIndex = this.findDesignatedControllingPartyIndex();
    if (!this.designatedBfoIndex){
      this.formGroup.addControl('controllingParty', this.controllingPartyFormGroup);
    }
    this.setDesignatedCpCheckboxEnableStatus(this.designatedBfoIndex);
    this.onEquityInterestChange(this.equityInterestService.hasEquityInterest);
  }

  private setUpBfoAndCpControls() {
    this.bfoFormGroupArray = this.formBuilder.array([]);
    this.formGroup = this.formBuilder.group({
    });

    for (let i = 0; i < this.numberOfBfos; i++) {
      let bfoFormGroup: FormGroup;
      const currentState = this.getDefaultAddressValue(this.ccApp.bfos, 'state', i);
      const isInternational = currentState === OTHER_INTERNATIONAL;
      const currentFieldValidator = i === 0 ? this.formValidatorsConfig.first : this.formValidatorsConfig.additionalDefault;
        const addressGroup: FormGroup = this.formBuilder.group({
          addressLine1: [this.getDefaultAddressValue(this.ccApp.bfos, 'addressLine1', i), currentFieldValidator.addressLine1],
          addressLine2: [this.getDefaultAddressValue(this.ccApp.bfos, 'addressLine2', i), currentFieldValidator.addressLine2],
          city: [this.getDefaultAddressValue(this.ccApp.bfos, 'city', i), currentFieldValidator.city],
          state: [currentState, currentFieldValidator.state]
        });

      const isUsCitizen = this.getDefaultUsCitizenValue(this.ccApp.bfos, 'isUsCitizen', i);

      bfoFormGroup = this.formBuilder.group({
        firstName: [this.getDefaultValue(this.ccApp.bfos, 'firstName', i), currentFieldValidator.firstName],
        middleInitial: [this.getDefaultValue(this.ccApp.bfos, 'middleInitial', i), currentFieldValidator.middleInitial],
        lastName: [this.getDefaultValue(this.ccApp.bfos, 'lastName', i), currentFieldValidator.lastName],
        address: addressGroup,
        dob: [this.getDefaultValue(this.ccApp.bfos, 'dob', i), currentFieldValidator.dob],
        ssn: [this.getDefaultValue(this.ccApp.bfos, 'ssn', i)],
        isDesignatedControllingParty: [this.getDefaultValue(this.ccApp.bfos, 'isDesignatedControllingParty', i), currentFieldValidator.isDesignatedControllingParty],
        isUsCitizen: [isUsCitizen, currentFieldValidator.isUsCitizen],
        countryOfCitizenship: [this.getDefaultValue(this.ccApp.bfos, 'countryOfCitizenship', i)],
        identificationType: [this.getDefaultValue(this.ccApp.bfos, 'identificationType', i)],
        identificationNumber: [this.getDefaultValue(this.ccApp.bfos, 'identificationNumber', i)],
        idIssueDate: [this.getDefaultValue(this.ccApp.bfos, 'idIssueDate', i)],
        idExpirationDate: [this.getDefaultValue(this.ccApp.bfos, 'idExpirationDate', i)]
      });

      const countryAddressControl = new FormControl(this.getDefaultAddressValue(this.ccApp.bfos, 'country', i),
          currentFieldValidator.country);
      const zipCodeAddressControl = new FormControl(this.getDefaultAddressValue(this.ccApp.bfos, 'zipCode', i),
          currentFieldValidator.zipCode);

      this.countryAddressControls.push(countryAddressControl);
      this.zipCodeAddressControls.push(zipCodeAddressControl);

      if (i > 0) {
        const bfoFormChangesSubscription: Subscription = bfoFormGroup.valueChanges.subscribe(changes => {
          const isFormEmptyFlag = isFormEmpty(changes);
          const formValidatorsConfig = isFormEmptyFlag ? this.formValidatorsConfig.additionalDefault : this.formValidatorsConfig.first;
          this.updateFormBehavior(bfoFormGroup, formValidatorsConfig);
        });
        this.bfoFormChangesSubscriptions.push(bfoFormChangesSubscription);
      }

      this.bfoFormGroupArray.insert(i, bfoFormGroup);
      this.handleUsCitizenConditions(bfoFormGroup, isUsCitizen);
      this.onStateChange({value: currentState, isInternational, bfoIndex: i});
    }
  }

  private updateFormBehavior(bfoFormGroup: FormGroup, currentFieldValidator: object) {
    const controls = bfoFormGroup.controls;
    Object.keys(controls).forEach((controlName) => {
      // Address already updates the form behavior when the initial field is changed.
      // Non us citizen fields update their form behavior when the non us citizen check box is changed.
      if (controlName !== ADDRESS && !this.usCitizenConfig.disabled[controlName]) {
        if (currentFieldValidator[controlName]) {
          controls[controlName].setValidators(currentFieldValidator[controlName]);
          controls[controlName].updateValueAndValidity({onlySelf: true, emitEvent: false});
        }
      }
    });
  }

  private getDefaultValue(bfos: BeneficialOwner[], attributeOfModel: string, index: number) {
    return (bfos && bfos[index] && bfos[index][attributeOfModel]) || undefined;
  }

  private getDefaultUsCitizenValue(bfos: BeneficialOwner[], attributeOfModel: string, index: number) {
    if (bfos && bfos[index]) {
      return bfos[index][attributeOfModel];
    }
    return undefined;
  }

  private getDefaultAddressValue(bfos: BeneficialOwner[], attributeOfModel: string, index: number) {
    return (bfos && bfos[index] && bfos[index][ADDRESS] && bfos[index][ADDRESS][attributeOfModel]) || undefined;
  }

  getControllingPartyAndHandleForm(bfoIndex): ControllingParty {
    let controllingParty = new ControllingParty();
    if(bfoIndex > 0) {
      const designatedBfoFormGroup: FormGroup = this.bfoFormGroupArray.controls[bfoIndex-1] as FormGroup;
      controllingParty.populate(designatedBfoFormGroup.value);
    } else {
      controllingParty.populate(this.controllingPartyFormGroup.value);
    }
    return controllingParty;
  }

  getNextRoute(): string {
    return 'business/review';
  }

  getPreviousRoute(): string {
    return 'business/representative';
  }

  getFormObject() {
    return {
      bfos: this.getBfos(),
      controllingParty: this.getControllingPartyAndHandleForm(this.designatedBfoIndex),
    } as CommercialApplicationModel;
  }

  private getBfos() {
    const bfos = this.bfoFormGroupArray.value as BeneficialOwner[];
    bfos.forEach((bfo => {
      if (bfo.ssn == '') {
        bfo.ssn = null;
      }
    }));
    return bfos.filter(bfo => (!isFormEmpty(bfo)));
  }

  bfoDisclosureModal($event) {
    $event.preventDefault();
    this.modalService.openModal('bfoDisclosure');
  }

  bfoUsCitizenChange($event) {
    const bfoFormGroup = this.bfoFormGroupArray.at($event.index) as FormGroup;
    this.handleUsCitizenConditions(bfoFormGroup, $event.optionSelected);
  }

  cpUsCitizenChange($event) {
    this.handleSsnValidation(this.controllingPartyFormGroup, $event.optionSelected);
  }

  protected shouldShowExitConfirmation(): boolean {
    return true;
  }

  private getConditionalUsCitizenValidators(isUsCitizen: boolean, controlName: string) {
    const usCitizenValidatorConfig = (isUsCitizen !== false)
      ? this.usCitizenConfig.enabled : this.usCitizenConfig.disabled;
    return usCitizenValidatorConfig[controlName];
  }

  private handleUsCitizenConditions(ownerFormGroup: FormGroup, isUsCitizen: boolean) {
    if(isUsCitizen !== false) {
      ownerFormGroup.get('countryOfCitizenship').reset();
      ownerFormGroup.get('identificationType').reset();
      ownerFormGroup.get('identificationNumber').reset();
      ownerFormGroup.get('idIssueDate').reset();
      ownerFormGroup.get('idExpirationDate').reset();
    }

    this.handleUsCitizenValidators(ownerFormGroup, 'countryOfCitizenship', isUsCitizen);
    this.handleUsCitizenValidators(ownerFormGroup, 'identificationType', isUsCitizen);
    this.handleUsCitizenValidators(ownerFormGroup, 'identificationNumber', isUsCitizen);
    this.handleUsCitizenValidators(ownerFormGroup, 'idIssueDate', isUsCitizen);
    this.handleUsCitizenValidators(ownerFormGroup, 'idExpirationDate', isUsCitizen);

    if(ownerFormGroup.get('idIssuerState')) {
      this.handleUsCitizenValidators(ownerFormGroup, 'idIssuerState', isUsCitizen);
    }
    if(ownerFormGroup.get('idIssuerCountry')) {
      this.handleUsCitizenValidators(ownerFormGroup, 'idIssuerCountry', isUsCitizen);
    }

    this.handleSsnValidation(ownerFormGroup, isUsCitizen);
  }

  private handleUsCitizenValidators(
    ownerFormGroup: FormGroup,
    controlName: any,
    isUsCitizen: boolean
  ) {
    ownerFormGroup.get(controlName).setValidators(this.getConditionalUsCitizenValidators(isUsCitizen, controlName));
    ownerFormGroup.get(controlName).updateValueAndValidity();
  }

  findDesignatedControllingPartyIndex() {
    const arrayIndex = this.ccApp.bfos.findIndex(bfo => bfo.isDesignatedControllingParty);
    // Controlling party index is 1 based.
    return arrayIndex !== -1 ? arrayIndex + 1 : null;
  }

  setDesignatedControl(event: { id:number, fromEquityDropdown?: boolean }) {
    const hadDesignatedBfoIndex = this.designatedBfoIndex != null && this.designatedBfoIndex != undefined;
    const designatedBfoIndex = event.id;
    this.designatedBfoIndex = designatedBfoIndex;
    if (designatedBfoIndex) {
      this.formGroup.removeControl('controllingParty');
    } else {
      if(!event.fromEquityDropdown || (event.fromEquityDropdown && hadDesignatedBfoIndex)) {
        this.ccApp.controllingParty = new ControllingParty();
        this.controllingPartyFormGroup.reset({}, {emitEvent: false});
      }
      this.formGroup.setControl('controllingParty', this.controllingPartyFormGroup);
    }
    this.formGroup.updateValueAndValidity({emitEvent: false});
    this.setDesignatedCpCheckboxEnableStatus(designatedBfoIndex);
  }



  // Bfo address state changes only
  onStateChange(stateStatus: {value: string, isInternational?: boolean, bfoIndex?: number }) {
    const countryFormControl = this.countryAddressControls[stateStatus.bfoIndex];
    const zipCodeFormControl = this.zipCodeAddressControls[stateStatus.bfoIndex];
    const bfoForm = this.bfoFormGroupArray.at(stateStatus.bfoIndex) as FormGroup;

    const address: FormGroup = bfoForm.get('address') as FormGroup;
    if(stateStatus.isInternational) {
      zipCodeFormControl.reset();
    } else {
      countryFormControl.reset();
    }
    toggleFormControl(address, countryFormControl, 'country', stateStatus.isInternational);
    toggleFormControl(address, zipCodeFormControl, 'zipCode', !stateStatus.isInternational);
  }

  private setDesignatedCpCheckboxEnableStatus(designatedBfoIndex) {
    if (designatedBfoIndex) {
      this.disableOtherDesignatedCpCheckboxes(designatedBfoIndex);
    } else {
      this.reenableAllDesignatedCpCheckboxes();
    }
  }

  private reenableAllDesignatedCpCheckboxes() {
    let bfoFormGroupArray = this.bfoFormGroupArray;
    for (let i = 1; i <= this.numberOfBfos; i++) {
      bfoFormGroupArray.controls[i - 1].get('isDesignatedControllingParty').enable();
    }
  }

  private disableOtherDesignatedCpCheckboxes(designatedBfoIndex) {
    let bfoFormGroupArray = this.bfoFormGroupArray;
    for (let i = 1; i <= this.numberOfBfos; i++) {
      if (i != designatedBfoIndex) {
        bfoFormGroupArray.controls[i - 1].get('isDesignatedControllingParty').disable();
      }
    }
  }

  private handleSsnValidation(ownershipFormGroup: FormGroup, isUsCitizen: boolean) {
    let isSsnRequired;
    const formValues = ownershipFormGroup.getRawValue();
    const hasValueChanges = !isFormEmpty(formValues);

    if (!isUsCitizen) {
      isSsnRequired = false;
    } else {
      isSsnRequired = hasValueChanges;
    }
    ownershipFormGroup.get('ssn').setValidators(this.formFieldValidators.ssnValidators({required: isSsnRequired}));
    ownershipFormGroup.get('ssn').updateValueAndValidity({emitEvent: false});
  }

  ngOnDestroy(): void {
    if (this.promoSubscription) {
      this.promoSubscription.unsubscribe();
    }
    if (this.bfoFormChangesSubscriptions) {
      this.bfoFormChangesSubscriptions.forEach(subscription => {
        subscription.unsubscribe();
      });
    }
    if (this.entityRulesSubscription) {
      this.entityRulesSubscription.unsubscribe();
    }
  }

  addEquityInterestFormControl(equityInterest: string, $event) {
    this.formGroup.setControl(equityInterest, $event);
  }

  onEquityInterestChange(hasEquityInterest: boolean) {
    if(hasEquityInterest) {
      this.formGroup.setControl('bfos', this.bfoFormGroupArray);
    } else {
      this.bfoFormGroupArray.reset({emitEvent: true});
      this.setDesignatedControl({id: null, fromEquityDropdown: true});
      this.formGroup.removeControl('bfos');
    }
  }
}

export interface BfoCheckBoxEvent {
  value: string;
  index: number;
  fromEquityDropdown?: boolean;
  isInternational?: boolean
}
