import {AfterViewChecked, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {ErrorDisplay} from '../../../../common/services/error-display';
import {PromoConfiguration, PromoConfigurationService} from '../../services/promo-configuration.service';
import {Subscription} from 'rxjs';
import {AbstractControl} from '@angular/forms';

@Component({
  selector: 'lynx-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss']
})

export class CheckboxComponent implements OnInit, OnDestroy, AfterViewChecked {
  @Input() errorText: string;
  @Input() control: AbstractControl
  @Input() fieldId: string;
  @Input() labelText: string;
  @Input() submitted: boolean;
  @Input() validateWhenDisabled: boolean = false;
  @Input() withBorder = false;
  @Input() readOnly = false;
  @Input() infoTitle?: string;
  @Input() infoText?: string;
  @Output() hasViewedWrittenAuthorizationFormChange = new EventEmitter();

  writtenAuthorizationFormUrl: string;
  @Output()
  toggle: EventEmitter<any> = new EventEmitter();
  private promoConfigSubscription: Subscription;

  constructor(public errorDisplay: ErrorDisplay, private promoConfigurationService: PromoConfigurationService, private elRef: ElementRef) {}

  ngOnInit(): void {
    if (this.readOnly) {
      this.control.disable()
    }
    this.promoConfigSubscription = this.promoConfigurationService.promoConfiguration.subscribe((promoConfiguration: PromoConfiguration) => {
      this.writtenAuthorizationFormUrl = promoConfiguration.writtenAuthorizationFormUrl;
    });
  }

  onAuthorizationFormClick() {
    this.hasViewedWrittenAuthorizationFormChange.emit();
  }

  getErrorText() {
    if(this.control.getError('writtenAuthorizationFormNotClicked')) {
      return 'Please open the <a class="writtenAuthorizationFormLink" href="' + this.writtenAuthorizationFormUrl + '" target="_blank" rel="noopener noreferrer" title="Opens in new window">Written Authorization Form</a> and complete the instructions within the document.';
    } else {
      return this.errorText;
    }
  }

  onClick($event) {
    this.control.setValue($event.target.checked);
    this.toggle.emit({checked: this.control.value});
  }

  ngAfterViewChecked(): void {
    if(this.errorDisplay.shouldShow(this.control, this.submitted) && this.getErrorText()) {
      const link = this.elRef.nativeElement.querySelector('.writtenAuthorizationFormLink');
      if(link) {
        link.addEventListener('click', this.onAuthorizationFormClick.bind(this), {once: true});
      }
    }
  }

  ngOnDestroy(): void {
    const link = this.elRef.nativeElement.querySelector('.writtenAuthorizationFormLink');
    if(link) {
      link.removeEventListener('click', this.onAuthorizationFormClick.bind(this));
    }
    if(this.promoConfigSubscription) {
      this.promoConfigSubscription.unsubscribe();
    }
  }
}
