import {Component, DoCheck, Inject, Input} from '@angular/core';
import {Router} from '@angular/router';

@Component({
  selector: 'application-progress',
  templateUrl: './application-progress.component.html',
  styleUrls: ['./application-progress.component.scss']
})

export class ApplicationProgressComponent implements DoCheck {

  @Input() steps: string[];
  stepIndex: number;

  constructor(@Inject(Router) protected router: Router) {
  }

  ngDoCheck(): void {
    const currentStep = this.steps.findIndex(step => this.router.url.indexOf(step.toLowerCase()) > -1, this);
    this.stepIndex = currentStep;
  }
}
