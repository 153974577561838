import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpService} from '../../services/http.service';
import {map} from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class FooterService {

  constructor(private httpService: HttpService) {
  }

  public getCopyrightLanguage(): Observable<string> {
    return this.httpService.get(`/copyright`)
      .pipe(map((info: CopyrightDetails) => info.copyrightLanguage));
  }
}

type CopyrightDetails = {
  copyrightLanguage: string;
}