import {Address} from '../Address';
import {Owner} from './Owner';

export class BeneficialOwner extends Owner {
  firstName: string;
  middleInitial: string;
  lastName: string;
  address = new Address();
  dob: string;
  ssn: string;
  isUsCitizen: boolean;
  cpTitle?: string;
  isDesignatedControllingParty: boolean;
}