import {Directive, HostListener} from '@angular/core';
import {NgModel} from '@angular/forms';

@Directive({
  selector: "[trackFocus]"
})
export class TrackFocusDirective {
  constructor(private model:NgModel){}

  @HostListener('focus') onFocus() {
    this.model['focused'] = true;
  }

  @HostListener('blur') onBlur() {
    this.model['focused'] = false;
  }
}