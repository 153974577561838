import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {HeaderComponent} from '../components/header/header.component';
import {NotFoundComponent} from '../components/not-found/not-found.component';
import {FooterComponent} from '../components/footer/footer.component';
import {ExpandableSectionComponent} from '../components/expandable-section/expandable-section.component';
import {AddressComponent} from '../components/address/address.component';
import {ApplicationProgressComponent} from '../components/application-progress/application-progress.component';
import {ProcessingComponent} from '../components/processing/processing.component';
import {LoaderComponent} from '../../../common/ui/loader/loader.component';
import {PendingComponent} from '../components/pending/pending.component';
import {TimesOutComponent} from '../../times-out/times-out.component';
import {ApprovedComponent} from '../components/approved/approved.component';
import {TimeoutComponent} from '../components/timeout/timeout.component';
import {ErrorComponent} from '../components/error/error.component';
import {SessionTimeoutWarningComponent} from '../../consumer/modal/session-timeout-warning/session-timeout-warning.component';
import {SharedModalFactoryComponent} from '../components/modal/shared-modal-factory/shared-modal-factory.component';
import {TermsErrorComponent} from '../components/modal/terms-error/terms-error.component';
import {PendingTermsComponent} from '../components/modal/pending-terms/pending-terms.component';
import {CardFeaturesComponent} from '../components/card-features/card-features.component';
import {LabelValueComponent} from '../components/label-value/label-value.component';
import {BySubmittingLanguageComponent} from '../components/by-submitting-language/by-submitting-language.component';
import {ReferralFieldComponent} from '../components/referral-field/referral-field.component';
import {PbContactFieldsComponent} from '../components/pb-contact-fields/pb-contact-fields.component';
import {PrintSoctComponent} from '../../consumer/modal/print-soct/print-soct.component';
import {SecuredCardDepositComponent} from '../components/secured-card-deposit/secured-card-deposit.component';
import {CustomerIdFieldsComponent} from '../components/customer-id-fields/customer-id-fields.component';
import {SubstituteW9Component} from '../components/subtitute-W9/substitute-W9.component';
import {ConsumerStarterComponent} from '../../consumer/consumer-starter/consumer-starter.component';
import {BalanceTransferComponent} from '../components/balance-transfer/balance-transfer.component';
import {BalanceTransferListComponent} from '../components/balance-transfer-list/balance-transfer-list.component';
import {BaseErrorComponent} from '../components/base-error/base-error.component';
import {CreditCardComponent} from '../components/approved/credit-card/credit-card.component';
import {LynxCommonModule} from '../../../common/lynx-common.module';
import { AccountBalanceFieldsComponent } from '../components/account-balance-fields/account-balance-fields.component';
import {CommercialStarterComponent} from '../../commercial/commercial-starter/commercial-starter.component';
import {ElectronicTermsComponent} from '../components/modal/electronic-terms/electronic-terms.component';
import {TemporaryCreditLineComponent} from '../components/approved/temporary-credit-line/temporary-credit-line.component';
import {SecurityCenterLockComponent} from '../components/security-center-lock/security-center-lock.component';
import {ZipCodeComponent} from '../components/zip-code/zip-code.component';
import {CheckboxComponent} from '../components/checkbox/checkbox.component';
import {ConfirmExitDirective} from '../directives/confirm-exit/confirm-exit.directive';
import {AnalyticsTrackingDirective} from '../tracking/analytics/directives/analytics-tracking.directive';
import {RealTimeTrackingDirective} from '../tracking/real-time/directives/real-time-tracking.directive';
import {GeolocationDirective} from '../../consumer/modal/goelocation-authorization/geolocation.directive';

@NgModule({
  imports: [
    CommonModule,
    LynxCommonModule
  ],
  declarations: [
    HeaderComponent,
    BaseErrorComponent,
    NotFoundComponent,
    FooterComponent,
    ExpandableSectionComponent,
    AddressComponent,
    ApplicationProgressComponent,
    ProcessingComponent,
    // LoaderComponent,
    PendingComponent,
    ApprovedComponent,
    CreditCardComponent,
    TimesOutComponent,
    TimeoutComponent,
    ErrorComponent,
    SessionTimeoutWarningComponent,
    SharedModalFactoryComponent,
    TermsErrorComponent,
    PendingTermsComponent,
    ElectronicTermsComponent,
    LabelValueComponent,
    BySubmittingLanguageComponent,
    CardFeaturesComponent,
    ReferralFieldComponent,
    PbContactFieldsComponent,
    PrintSoctComponent,
    SecuredCardDepositComponent,
    SecurityCenterLockComponent,
    CustomerIdFieldsComponent,
    SubstituteW9Component,
    ConsumerStarterComponent,
    CommercialStarterComponent,
    BalanceTransferComponent,
    BalanceTransferListComponent,
    AccountBalanceFieldsComponent,
    TemporaryCreditLineComponent,

    ZipCodeComponent,
    CheckboxComponent,
    ConfirmExitDirective,
    AnalyticsTrackingDirective,
    RealTimeTrackingDirective,
    GeolocationDirective

  ],
  exports: [
    CommonModule,
    LynxCommonModule,
    HeaderComponent,
    BaseErrorComponent,
    NotFoundComponent,
    FooterComponent,
    ExpandableSectionComponent,
    AddressComponent,
    ApplicationProgressComponent,
    ProcessingComponent,
    // LoaderComponent,
    PendingComponent,
    ApprovedComponent,
    CreditCardComponent,
    TimesOutComponent,
    TimeoutComponent,
    ErrorComponent,
    SessionTimeoutWarningComponent,
    SharedModalFactoryComponent,
    TermsErrorComponent,
    PendingTermsComponent,
    LabelValueComponent,
    BySubmittingLanguageComponent,
    CardFeaturesComponent,
    ReferralFieldComponent,
    PbContactFieldsComponent,
    PrintSoctComponent,
    SecuredCardDepositComponent,
    SecurityCenterLockComponent,
    CustomerIdFieldsComponent,
    SubstituteW9Component,
    ConsumerStarterComponent,
    CommercialStarterComponent,
    BalanceTransferComponent,
    BalanceTransferListComponent,
    AccountBalanceFieldsComponent,
    TemporaryCreditLineComponent,
    ElectronicTermsComponent,
    ZipCodeComponent,
    CheckboxComponent,
    ConfirmExitDirective,
    AnalyticsTrackingDirective,
    RealTimeTrackingDirective,
    GeolocationDirective
  ]
})
export class LynxUiCommonModule {}
