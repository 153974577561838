import {Component, OnDestroy, OnInit} from '@angular/core';
import {PromoConfigurationService} from '../../services/promo-configuration.service';
import {Title} from '@angular/platform-browser';
import {MarkdownWithBreaksService} from '../../services/markdown-with-breaks.service';
import {EMPTY, Observable, Subscription} from 'rxjs';
import {removeHtmlMarkup} from '../../services/text-transforms';
import {ApplicationIdService} from '../../services/application-id.service';
import {filter} from 'rxjs/operators';
import {ActivatedRoute} from "@angular/router";

@Component({
  selector: 'pending',
  templateUrl: './pending.component.html',
  styleUrls: ['./pending.component.scss']
})
export class PendingComponent implements OnInit, OnDestroy {

  analyticsTrackingPageName: string;
  primaryMessageTitle: string;

  productName: string;
  pendingResponseButtonText: string;
  pendingResponseUrl: string;
  pendingMessage: string;
  private promoSubscription: Subscription;

  applicationId$: Observable<string> = EMPTY;

  constructor(private promoConfigurationService: PromoConfigurationService,
    private title: Title,
    private markdownService: MarkdownWithBreaksService,
    private applicationIdService : ApplicationIdService,
    private activatedRoute: ActivatedRoute,
  ) {
  }

  ngOnInit() {
    this.setAnalyticsTrackingPageAndMessageDisplayedBasedOnCurrentUrl();
    this.promoConfigurationService.invalidateShortCode();
    this.promoSubscription = this.promoConfigurationService.promoConfiguration.subscribe((promoConfiguration) => {
      this.pendingResponseButtonText = promoConfiguration.pendingResponseButtonText;
      this.pendingResponseUrl = promoConfiguration.pendingResponseUrl;
      // The normal pending message will (likely) not make sense for a decline due to an existing account scenario, so
      // don't use it.
      if (!this.isDeclinedDueToExistingAccount()) {
        this.pendingMessage = this.markdownService.render(promoConfiguration.pendingMessage);
      }

      this.productName = promoConfiguration.productName;
      this.title.setTitle(removeHtmlMarkup(this.productName));
    });
    this.applicationId$ = this.applicationIdService.applicationId$
      .pipe(filter(id => !!id));
  }

  shouldDisplayResponseButton(): boolean {
    return !!this.pendingResponseButtonText && !!this.pendingResponseUrl;
  }

  goToPage(url: string) {
    url = url.trim();
    if (url.endsWith('/#/close')) {
      window.close();
    } else {
      window.open(url, '_self');
    }
  }

  ngOnDestroy() {
    if (this.promoSubscription) {
      this.promoSubscription.unsubscribe();
    }
    this.applicationIdService.applicationId$.next(null);
  }

  private setAnalyticsTrackingPageAndMessageDisplayedBasedOnCurrentUrl(): void {
    if (this.isDeclinedDueToExistingAccount()) {
      this.analyticsTrackingPageName = 'Lynx Declined Due to Existing Account Page';
      this.primaryMessageTitle = 'Application not processed.'
      this.pendingMessage = 'Due to an existing account(s) with the credit card issuer, we regret that we are not able to process your application at this time. Since this application was not processed, it will not impact your credit bureau report.';
    } else {
      this.analyticsTrackingPageName = 'Lynx Pending Page';
      this.primaryMessageTitle = 'Thanks for applying.';
    }
  }

  private isDeclinedDueToExistingAccount(): boolean {
    return this.activatedRoute.snapshot.url.join('').includes('declined-due-to-existing-account');
  }
}
