import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {FormControl, NgForm} from '@angular/forms';
import {BaseReactiveFieldComponent} from '../base-reactive-field/base-reactive-field-component';
import {ErrorDisplay} from '../../services/error-display';
import {Global} from '../../global';
import {Subscription} from 'rxjs';

@Component({
  selector: 'ssn-field',
  templateUrl: './ssn-field.component.html',
  styleUrls: ['./ssn-field.component.scss']
})
export class SsnFieldComponent extends BaseReactiveFieldComponent implements OnInit, OnDestroy {
  @Input() submitted: boolean;
  @Input() autocomplete = 'on';
  @Input() control: FormControl = new FormControl<string|null>(null);
  @Input() form: NgForm;
  @Input() explanatoryText: string;
  @Input() fieldId = 'social-security-number';
  @Input() showSsnMask = false;
  @Input() readOnly = false;

  placeHolder: string;
  maskOptions: {mask:(string|RegExp)[]; guide: boolean};
  pattern = /^\d{9}$/;

  private defaultMask: (string|RegExp)[] = [/\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
  private maskWithShowSsnMask: (string|RegExp)[] = [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/];

  private defaultPlaceHolder = '___-__-____';
  private showSsnMaskPlaceHolder= '_________';
  private focus = false;

  valueChangeSubscription: Subscription;

  constructor(public errorDisplay: ErrorDisplay, protected global: Global) {
    super(global);
  }

  ngOnInit(): void {
    const maskValue = this.showSsnMask ? this.maskWithShowSsnMask : this.defaultMask;
    this.maskOptions = {mask: maskValue, guide: !this.showSsnMask};
    this.placeHolder = this.showSsnMask ? this.showSsnMaskPlaceHolder : this.defaultPlaceHolder;

    this.labelText = 'Social Security Number';
    if (this.control.value) {
      this.control.setValue(this.control.value.replace(/\D/g, ''));
    }
    this. valueChangeSubscription = this.control.valueChanges.subscribe((value) => {
      if(value) {
        this.control.setValue(value.replace(/\D/g, ''), {emitEvent: false});
      }
    });
  }

  getErrorText() {
    if (this.control.hasError('invalidSSN')) {
      return `Please enter a valid ${this.labelText}`;
    }
    return super.getErrorText();
  }

  focusInput() {
    this.focus = true;
  }

  unfocusInput() {
    this.focus = false;
  }

  ngOnDestroy() {
    if (this.valueChangeSubscription) {
      this.valueChangeSubscription.unsubscribe();
    }
  }
}
