import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'lynx-credit-card',
  templateUrl: './credit-card.component.html',
  styleUrls: ['./credit-card.component.scss']
})
export class CreditCardComponent implements OnInit {
  @Input()
  cardholderName: string;
  @Input()
  temporaryCardNumber: string;
  @Input()
  expirationDate: string;
  @Input()
  temporarySecurityCode: string;
  @Input()
  zipCode: string;

  cardPart1: string;
  cardPart2: string;
  cardPart3: string;
  cardPart4: string;

  ngOnInit(): void {
    this.cardPart1 = this.temporaryCardNumber.substring(0, 4);
    this.cardPart2 = this.temporaryCardNumber.substring(4, 8);
    this.cardPart3 = this.temporaryCardNumber.substring(8, 12);
    this.cardPart4 = this.temporaryCardNumber.substring(12, 16);
  }
}