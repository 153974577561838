import {Component, Input} from '@angular/core';
import {BaseFieldComponent} from '../base-field/base-field.component';
import {NgModel} from '@angular/forms';

@Component({
  selector: 'text-field',
  templateUrl: './text-field.component.html',
})

export class TextFieldComponent extends BaseFieldComponent {
  @Input('maxlength') maxlength: string;
  @Input('minlength') minlength: string;
  @Input('type') type: string = 'text';
  @Input('required') required: boolean = true;
  @Input('grayText') grayText: string;
  @Input('autocomplete') autocomplete: string = 'on';
  @Input('placeholder') placeholder: string = '';
  @Input() readOnly: boolean = false;

  // [ -~] matches all ASCII printable characters
  @Input() pattern = '[ -~]*';
  @Input() inputClass: string;
  @Input() patternErrorText: string;

  get model(): NgModel {
    return this.fieldValue;
  }

  @Input('model') set model(val) {
    this.fieldValue = val;

    if (this.maxlength && this.fieldValue) {
      this.fieldValue = this.fieldValue.slice(0, +this.maxlength);
    }
  }
}
