import {Component} from '@angular/core';
import {ProcessingComponentService} from './processing.component.service';
import {map} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {MarkdownService} from '../../services/markdown.service';

@Component({
  selector: 'processing',
  templateUrl: './processing.component.html',
  styleUrls: ['./processing.component.scss']
})

export class ProcessingComponent {
  text$: Observable<string>;

  constructor(
    private service: ProcessingComponentService,
    markDown: MarkdownService
  ) {
    this.text$ = service.config$
      .pipe(map(config => markDown.renderInline(config.mainMessage, {breaks: true})));
  }
}
