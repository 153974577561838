import {Component, Input} from '@angular/core';
import {ErrorDisplay} from '../../services/error-display';
import {ScrollToableFields} from '../scroll-to-able-fields';
import {BaseReactiveFieldComponent} from '../base-reactive-field/base-reactive-field-component';
import {Global} from '../../global';
import createAutoCorrectedDatePipe from '../../pipes/addons/create-auto-corrected-date-pipe-addon';

@Component({
  selector: 'date-field',
  templateUrl: './date-field.component.html',
  styleUrls: ['./date-field.component.scss']
})
export class DateFieldComponent extends BaseReactiveFieldComponent implements ScrollToableFields {

  mask = [/\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
  @Input() fieldId: string;
  @Input() explanatoryText: string;
  @Input() submitted: boolean;
  @Input() readOnly: boolean = false;

  constructor(private errorDisplay: ErrorDisplay, protected global: Global) {
    super(global);
  }

  getErrorText() {
    if (this.control.hasError('invalidDOB')) {
      return 'Please enter a valid date of birth';
    }
    if (this.control.hasError('invalidIdExpirationDate')) {
        return 'Please enter a valid ID Expiration Date';
    }
    if (this.control.hasError('invalidIdIssueDate')) {
        return 'Please enter a valid ID Issue Date';
    }

    return super.getErrorText();
  }

  shouldShowError(): boolean {
    return this.errorDisplay.shouldShow(this.control, this.submitted);
  }

  adjustCursor(event) {
    if (!event || !event.target || !event.target.value) {
      return;
    }
    if (event.target.selectionStart !== event.target.selectionEnd && event.type === 'click') {
      return;
    }
    const matcher = /[^0-9\-$,()\s]/g;
    matcher.exec(event.target.value);

    if (matcher.lastIndex === 0) {
      return event.target.setSelectionRange(event.target.selectionEnd, event.target.selectionEnd);
    }
    const newPosition = Math.min(matcher.lastIndex - 1, event.target.selectionEnd);
    event.target.setSelectionRange(newPosition, newPosition);
  }

  getTextMask() {
    return {mask: this.mask, guide: false, pipe: datePipe()};
  }
}

function datePipe() {
  return conformedValue => {
    const placeholderReference = 'MM-DD-YYYY';
    if(conformedValue === null) {
      conformedValue = '';
    }
    const maybeValueObject = createAutoCorrectedDatePipe('mm-dd-yyyy')(conformedValue);
    if (!maybeValueObject || !maybeValueObject.value) {
      return maybeValueObject;
    }
    const {value} = maybeValueObject;
    return {
      ...maybeValueObject,
      value: (value || '') + placeholderReference.slice((value || '').length),
    };
  };
}
