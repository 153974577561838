import { DoCheck, EventEmitter, Input, OnInit, Output, ViewChild, Directive } from '@angular/core';
import {NgForm, NgModel} from '@angular/forms';
import {ScrollToableFields} from '../scroll-to-able-fields';

@Directive()
export class BaseFieldComponent implements DoCheck, ScrollToableFields, OnInit {
  protected fieldValue: any;
  modelName: string;
  fieldId: string;
  @ViewChild('field', { static: true }) control: NgModel;
  @Input() fieldName: string;
  @Input() labelText: string;
  @Input() form: NgForm;
  @Output() modelChange = new EventEmitter();
  @Input() explanatoryText: string;
  @Input() id: string;
  @Input() errorText: string;
  shouldShowError = false;
  shouldShowExplanatoryText = false;

  get model(): NgModel {
    return this.fieldValue;
  }

  @Input('model') set model(val) {
    this.fieldValue = val;
  }

  ngDoCheck() {
    if (this.form) {
      this.shouldShowError = !this.control['focused'] && this.control.invalid && (this.form.submitted || this.control.touched);
      this.shouldShowExplanatoryText = this.explanatoryText && this.control['focused'];
    }
  }

  ngOnInit(): void {
    if (this.form) {
      this.form.addControl(this.control);
    }

    this.deriveNameRelatedAttributes(this.fieldName);
  }

  getErrorText() {
    return this.errorText || `Please enter your ${this.labelText}`;
  }

  onChange($event) {
    this.modelChange.emit($event);
  }

  private deriveNameRelatedAttributes(name) {
    this.fieldId = this.id || name.replace(/\s/g, '-').replace('/', '-').replace('\'', '');
    if (!this.labelText) {
      const capitalizeFirstLetter = word => word[0].toUpperCase() + word.slice(1);
      this.labelText = name.split(/\s/).map(capitalizeFirstLetter).join(' ')
        .split('/').map(capitalizeFirstLetter).join('/');
    }
    this.modelName = name.replace('\'', '').split(/[\s\/]/).map((word, index) => {
      if (index === 0) {
        return word.toLowerCase();
      }
      else {
        return word[0].toUpperCase() + word.slice(1);
      }
    }).join('');
  }
}
