/*
 * Copyright(c)2001-2018 First National Bank of Omaha
 * 1620 Dodge Street Omaha, NE 68197, USA
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of
 *  First National Bank ("Confidential Information"). You shall not
 *  disclose such Confidential Information and shall use it only in
 *  accordance with the terms of the license agreement you entered into
 *  with First National Bank
 */

import {Injectable} from '@angular/core';
import * as MarkdownIt from 'markdown-it';
import * as iterator from 'markdown-it-for-inline';
import * as sanitizer from 'markdown-it-sanitizer';

@Injectable({
  providedIn: 'root'
})
export class MarkdownWithBreaksService {
  private md: MarkdownIt.MarkdownIt;

  constructor() {
    this.md = new MarkdownIt({html: true, breaks: true})
      .use(sanitizer)
      .use(iterator, 'cleanup_links', 'link_open', (tokens, index) => {
        const token = tokens[index];
        token.attrPush(['target', '_blank']);
        token.attrPush(['rel', 'noopener noreferrer']);
        token.attrPush(['title', 'Opens in new window']);
      });
  }

  render(content: string): string {
    return this.md.renderInline(content);
  }
}
