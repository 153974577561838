import {Inject, Injectable} from '@angular/core';
import {PromoConfigurationService} from '../../../services/promo-configuration.service';
import {environment} from '../../../../../../environments/environment';
import {Observable, ReplaySubject} from 'rxjs';
import {filter, map, take} from 'rxjs/operators';
import {ScriptInjectorService} from '../common/script-injector.service';

@Injectable({
  providedIn: 'root'
})
export class TealiumService {
  private static readonly START: TealiumOptions = {application_start: '1'};

  script_src = '';
  private readonly config: Observable<{[key: string]: string}>;
  private readonly views: ReplaySubject<any> = new ReplaySubject<any>();
  private applicationStarted = false;

  // Typically set "noview" flag (no first page automatic view event) to true for Single Page Apps (SPAs)
  constructor(@Inject('Window') private _window,
              private injector: ScriptInjectorService,
              private promoConfig: PromoConfigurationService) {
    this._window.utag_cfg_ovrd = {noview: true};
    this._window.utag_data = {};

    this.config = promoConfig.promoConfiguration.pipe(
      map(config => config.tealiumConfiguration),
      filter(config => !!config && Object.keys(config).length > 0),
      take(1))

    this.config.subscribe(c => {
      this.views.subscribe((v) => {
        this.view(v, c);
      })
    })
}

  link(data?: any) {
    if (environment.tealiumEnvironment !== 'ci') {
      this.setEnvironment();
      this.trackEvent('link', data);
    }
  }

  /*
    Track event, but wait until we have the promo config loaded so all promo-specific attributes (app type, agent bank,
    ect) get populated with the event
   */
  trackDefer(options: TealiumOptions) {
    if (environment.tealiumEnvironment !== 'ci') {
      this.setEnvironment();
      this.views.next({ ...options, application_name: 'Lynx'});
    }
  }

  /*
    Track event and send to Tealium now and assume the promo config has not been loaded. Event will not associate with
    a particular promo code or partner or app type.
  */
  trackNow(options: TealiumOptions) {
    if (environment.tealiumEnvironment !== 'ci') {
      this.setEnvironment();
      this.view({...options, application_name: 'Lynx'}, {})
    }
  }

  trackStartDefer() {
    if (!this.applicationStarted) {
      this.trackDefer(TealiumService.START);
    }
    this.applicationStarted = true;
  }

  trackStartNow() {
    if (!this.applicationStarted) {
      this.trackNow(TealiumService.START);
    }
    this.applicationStarted = true;
  }


  // Data layer is optional set of key/value pairs
  private trackEvent(tealium_event: string, data?: any) {
    if (this.script_src === '') {
      console.log('Tealium config not set.');
      return;
    }
    if (this._window.utag === undefined) {
      this.getScript(this.script_src, () => {
        this._window.utag.track(tealium_event, data);
      });
    } else {
      this._window.utag.track(tealium_event, data);
    }
  }

  private view(viewInfo: any, config: any) {
    const newData: {} = {...viewInfo, ...config};

    if (this.script_src === '') {
      console.log('Tealium config not set.');
    }
    if (this._window.utag === undefined) {
      this.getScript(this.script_src, () => {
        this._window.utag.view(newData);
      });
    } else {
      this._window.utag.view(newData);
    }
  }

  private setEnvironment() {
    if (this.script_src === '') {
      this.setConfig({
        account: 'fnbo',
        profile: 'lynx',
        environment: environment.tealiumEnvironment
      });
    }
  }

  // Config settings used to build the path to the utag.js file
  private setConfig(config: { account: string, profile: string, environment: string }) {
    if (config.account !== undefined && config.profile !== undefined && config.environment !== undefined) {
      this.script_src = 'https://tags.tiqcdn.com/utag/' + config.account + '/' + config.profile + '/' + config.environment + '/utag.js';
    }
  }

  private getScript(src: string, callback: Function) {
    this.injector.injectAndLoadScript(src)
      .then(() => callback());
  }
}

interface TealiumOptions {
  page_name?: string;
  application_start?: '1';
  [key: string ]: any;
}
