import {Inject, Injectable, InjectionToken, OnDestroy} from '@angular/core';
import {BehaviorSubject, combineLatest, interval, NEVER, Observable, SchedulerLike, Subscription, timer} from 'rxjs';
import {map, startWith, takeUntil} from 'rxjs/operators';
import {environment} from '../../../../environments/environment';

export const defaultTimerToken = timer;
export const TIMEOUT_TIMER_TOKEN = new InjectionToken('timeoutTimerToken', {
  providedIn: 'root',
  factory: () => defaultTimerToken
});
export const DEFAULT_TIMEOUT_ROUTE = '/personal';

@Injectable({providedIn: 'root'})
export class SessionTimeoutService implements OnDestroy {
  private sessionTimeoutMs: number = environment.timeoutWarning;
  private subscriptions: Subscription[];

  static readonly IN_PROGRESS = 'IN_PROGRESS';
  static readonly NEW_OR_COMPLETED = 'NEW_OR_COMPLETED';

  private onTimeoutNavigateTo = DEFAULT_TIMEOUT_ROUTE;
  private currentApplicationStatus = SessionTimeoutService.NEW_OR_COMPLETED;

  startListeners: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  constructor(@Inject(TIMEOUT_TIMER_TOKEN) private timer: (
    dueTime?: number | Date,
    periodOrScheduler?: number | SchedulerLike,
    scheduler?: SchedulerLike
  ) => Observable<number>) {
    this.subscriptions = [];
  }

  public restart(callback: () => void): void {
    this.abort();
    this.subscriptions.push(this.timer(this.sessionTimeoutMs).subscribe((_) => {
      this.abort();
      callback();
    }));
  }

  public setSessionTimeoutMs(ms: number): void {
    this.sessionTimeoutMs = ms;
  }

  public countDownTimer(ms: number, cancel: Observable<any> = NEVER, early: Observable<any> = NEVER): Observable<number> {
    const start = (ms / 1000) - 1;
    return combineLatest(
      cancel.pipe(startWith(undefined), map(x => x !== undefined)),
      interval(1000)
    ).pipe(
      map(([cancelled, count]) => {
        if (cancelled) {
          throw new Error('Cancelled');
        }
        return start - count;
      }),
      takeUntil(timer(ms + 1)),
      takeUntil(early),
    );
  }

  public abort(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
    this.subscriptions = [];
  }

  setApplicationStatusToNew() {
    this.currentApplicationStatus = SessionTimeoutService.NEW_OR_COMPLETED;
  }

  attemptToSetNavigationPage(location: string){
    if (this.currentApplicationStatus === SessionTimeoutService.NEW_OR_COMPLETED){
      this.currentApplicationStatus = SessionTimeoutService.IN_PROGRESS;
      this.onTimeoutNavigateTo = location;
    }
  }

  getOnTimeoutNavigateTo() : string{
    return this.onTimeoutNavigateTo;
  }

  ngOnDestroy(): void {
  }
}
