import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FieldsToDisplay} from '../../services/promo-configuration.service';
import {FormControl, FormGroup} from '@angular/forms';
import {FormFieldValidators} from '../../validators/form-field-validators';
import {ConsumerApplicationModel} from '../../models/consumer-application-model';

const accountBalanceFieldsControlsName = [
  'checkingAccountBalance',
  'savingsAccountBalance',
  'investmentAccountBalance'
];
export interface CurrencyFieldFormGroup {
  [key: string]: FormControl<number|null>;
}
@Component({
  selector: 'lynx-account-balance-fields',
  templateUrl: './account-balance-fields.component.html'
})
export class AccountBalanceFieldsComponent implements OnInit {

  @Input() fieldsToDisplay: FieldsToDisplay;
  @Input() applicationModel: ConsumerApplicationModel;
  @Input() submitted: boolean;

  @Output() accountBalanceFieldsCreated = new EventEmitter<FormGroup>();
  accountBalanceFieldFormGroup: FormGroup = new FormGroup<CurrencyFieldFormGroup>({});

  constructor(private formFieldValidators: FormFieldValidators) {
  }

  ngOnInit() {
    accountBalanceFieldsControlsName.forEach(controlName => {
      let control = new FormControl(
        this.applicationModel[controlName],
        this.formFieldValidators.currencyValidators(!!this.fieldsToDisplay && this.fieldsToDisplay.accountBalanceFields)
      );
      this.accountBalanceFieldFormGroup.addControl(controlName, control);
    });
    this.accountBalanceFieldsCreated.emit(this.accountBalanceFieldFormGroup);
  }

}
