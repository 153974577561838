import {AbstractControl, FormGroup} from '@angular/forms';

export function toggleFormControl(formGroup: FormGroup, formControl: AbstractControl, formControlName: string, isAddingToForm: boolean): void {
  if (isAddingToForm) {
    if (!formGroup.contains(formControlName)) {
      formGroup.addControl(formControlName, formControl);
    }
  } else {
    if (formGroup.contains(formControlName)) {
      formGroup.removeControl(formControlName);
    }
  }
}