import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'noWrapPhoneNumber'
})
export class NoWrapPhoneNumberPipe implements PipeTransform {

  transform(textToDisplay: any): string {
    if (!textToDisplay) {
      return textToDisplay;
    }
    return textToDisplay.replace(/((1-)?((\(\d{3}\) ?)|(\d{3}-))?\d{3}-\d{4})/g,
      '<span class="text-nowrap">$1</span>');
  }
}

