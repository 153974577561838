import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ErrorConfigService {

  private errorConfig : ErrorConfig;

  constructor() { }

  setConfig(config: ErrorConfig) {
    this.errorConfig = config;
  }

  getConfig() {
    return this.errorConfig;
  }
}

export interface ErrorConfig {
  title? : string;
  message? : string;
  showActionButton? : boolean;
  actionButtonRoute? : string;
}
