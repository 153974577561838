import {Component, Input, OnInit, Renderer2} from '@angular/core';
import {BaseModalComponent} from '../base-modal.component';
import {ModalService} from '../../../services/modalService.service';

@Component({
  selector: 'terms-error',
  templateUrl: './terms-error.component.html',
  styleUrls: ['../modal.scss', './terms-error.component.scss']
})
export class TermsErrorComponent extends BaseModalComponent implements OnInit {

  @Input() errorMessage: string;

  constructor(private renderer: Renderer2, modalService: ModalService) {
    super(renderer, modalService);
  }

  ngOnInit() {
  }

}
