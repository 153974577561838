import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpRequest} from '@angular/common/http';

import {Observable} from 'rxjs';
import {TokenHolder} from './token-holder.service';
import {AbstractTokenHeaderInterceptor} from "./abstract-token-header-interceptor";

export const LYNX_SESSION_ID_HEADER_NAME = 'X-Lynx-Session-Id';

@Injectable({
  providedIn: 'root'
})
export class LynxSessionIdHeaderInterceptor extends AbstractTokenHeaderInterceptor {

  protected getTokenKey(): string {
    return LYNX_SESSION_ID_HEADER_NAME;
  }

  protected getTokenValue(): string {
    return this.tokenHolder.getToken(LYNX_SESSION_ID_HEADER_NAME);
  }

  protected updateTokenValue(value) {
    this.tokenHolder.setToken(LYNX_SESSION_ID_HEADER_NAME, value);
  }

  constructor(private tokenHolder: TokenHolder) {
    super();
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return super.intercept(req, next);
  }

}