export class BalanceTransfer {
  creditorName?: string;
  accountNumber?: string;
  amount?: string;
  paymentAddress?: string;
  city?: string;
  state?: string = null;
  zipCode?: string;

  constructor(bt: Partial<BalanceTransfer> = {}) {
    this.creditorName = bt.creditorName;
    this.accountNumber = bt.accountNumber;
    this.amount = bt.amount;
    this.paymentAddress = bt.paymentAddress;
    this.city = bt.city;
    this.state = bt.state;
    this.zipCode = bt.zipCode;
  }
}
