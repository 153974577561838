import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {ErrorDisplay} from '../../../../common/services/error-display';
import {BaseReactiveFieldComponent} from '../../../../common/forms/base-reactive-field/base-reactive-field-component';
import {Global} from '../../../../common/global';
import {Subscription} from 'rxjs';

@Component({
  selector: 'lynx-zip-code',
  templateUrl: './zip-code.component.html',
  styleUrls: ['./zip-code.component.scss']
})
export class ZipCodeComponent extends BaseReactiveFieldComponent implements OnInit, OnDestroy {
  pattern = /(^\d{5}$|^\d{9}$|^\d{5}-\d{4}$)/;

  @Input() shouldShowError: boolean;
  @Input() fieldId: string;
  @Input() readOnly = false;
  @Input() submitted: boolean;

  private valueChangeSubscription: Subscription;

  constructor(public errorDisplay: ErrorDisplay, protected global: Global) {
    super(global);
  }

  getPatternErrorMessage() {
    return 'Please enter a 5 or 9 digit number';
  }

  getMask = () => {
    if (this.control) {
      return {mask: this.zipCodeMask, guide: false};
    }
  }

  private zipCodeMask = (value) => {
    if (value.length === 5) {
      return [/\d/, /\d/, /\d/, /\d/, /\d/, /[\d-]/, /\d/, /\d/, /\d/, /\d/];
    }
    return [/\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
  }

  ngOnInit(): void {
    if (this.control.value) {
      this.control.setValue(this.control.value.replace(/\D/g, ''));
    }
    this.valueChangeSubscription = this.control.valueChanges.subscribe((value) => {
      if(value) {
        this.control.setValue(value.replace(/\D/g, ''), {emitEvent: false});
      }
    });
  }

  ngOnDestroy() {
    if (this.valueChangeSubscription) {
      this.valueChangeSubscription.unsubscribe();
    }
  }
}
