import {Directive, Input, OnInit} from '@angular/core';
import {LoggingService} from '../../../services/logging/logging.service';

@Directive({
  selector: '[realTimeTracking]'
})
export class RealTimeTrackingDirective implements OnInit {
  @Input('realTimeTracking') pageName: string;

  constructor(private loggingService: LoggingService) {
  }

  ngOnInit(): void {
    if (this.pageName) {
      this.loggingService.logPageView(this.pageName);
    }
  }

}
