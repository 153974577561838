import {Inject, Injectable} from '@angular/core';
import {getSocurePublicKeyForEnvironment} from '../socure/socure-public-key';
import {AbstractScriptInjectorService} from './abstract-script-injector.service';
import {environment} from '../../../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SocureScriptInjectorService extends AbstractScriptInjectorService {

  constructor(@Inject('Window') _window) {
    super(_window)
  }
   createScriptElement(doc: Document, scriptSrc: string) {
    let scriptToInsert;
    scriptToInsert = doc.createElement('script');
    scriptToInsert.language = 'javascript';
    scriptToInsert.type = 'text/javascript';
    scriptToInsert.defer = 1;
    scriptToInsert.setAttribute('data-public-key', getSocurePublicKeyForEnvironment(environment.socureEnvironment))
    scriptToInsert.setAttribute('src', scriptSrc);
    return scriptToInsert;
  }
}