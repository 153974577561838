import {Injectable} from '@angular/core';
import {HttpService} from '../http.service';
import {PromoConfigurationService} from '../promo-configuration.service';

@Injectable({
  providedIn: 'root'
})
export class LoggingService {
  private promoCode;
  private agentBank;

  constructor(private httpService: HttpService, promoService: PromoConfigurationService) {
    promoService.promoConfiguration.subscribe(promo => {
      this.promoCode = promo.promoCode;
      this.agentBank = promo.agentBank;
    })
  }
  logPageView(page: string) {
    this.httpService.post('logging/page-views',
      {
        pageName: page,
        promoCode: this.promoCode,
        agentBank: this.agentBank
      })
      .subscribe();
  }

}