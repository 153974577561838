import {AfterContentInit, Component, Input} from '@angular/core';
import {BaseFieldComponent} from '../base-field/base-field.component';

@Component({
  selector: 'select-field',
  templateUrl: './select-field.component.html',
  styles: ['select.form-control:not([size]):not([multiple]) { height: 44px; }']
})
export class SelectFieldComponent extends BaseFieldComponent implements AfterContentInit {
  @Input() required = true;
  @Input() options: string[] = [];
  @Input() errorText: string;
  @Input() inputClass: string;
  @Input() defaultOptionSelectable = false;
  @Input() disabled = false;

  ngAfterContentInit(): void {
    if (this.fieldValue === undefined) {
      this.fieldValue = null;
    }
  }

  getErrorText() {
    return this.errorText || `Please select your ${this.labelText}`;
  }
}
