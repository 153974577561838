import { Injectable } from '@angular/core';
import {Subject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ElectronicTermsService {
  private readonly agreeClickedSubject: Subject<boolean> = new Subject<boolean>();
  readonly agreeClicked$ = this.agreeClickedSubject.asObservable();
  constructor() { }

  agreeClick() {
    this.agreeClickedSubject.next(true);
  }

  closeClick() {
    this.agreeClickedSubject.next(false);
  }
}
