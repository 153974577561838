import {Component} from '@angular/core';
import {PromoConfigurationService} from '../../services/promo-configuration.service';
import {Card} from '../../models/card';

@Component({
  selector: 'card-features',
  templateUrl: './card-features.component.html',
  styleUrls: ['card-features.component.scss']
})
export class CardFeaturesComponent {
  productName: string;
  selectedCard: Card;
  soctUrl: string;
  limitedTimeOffer: boolean;
  autoProvision: boolean;

  constructor(private promoConfigurationService: PromoConfigurationService) {
  }

  ngOnInit(): void {
    this.promoConfigurationService.promoConfiguration.subscribe((promoConfiguration) => {
      this.productName = promoConfiguration.productName;
      this.selectedCard = promoConfiguration.selectedCard;
      this.soctUrl = promoConfiguration.soctUrl;
      this.limitedTimeOffer = promoConfiguration.limitedTimeOffer;
      this.autoProvision = promoConfiguration.autoProvision;
    });
  }
}
