import {Directive, Input, OnInit} from '@angular/core';
import {TealiumService} from '../tealium/tealium.service';
import {AdobeLaunchService} from '../adobe-launch/adobe-launch.service';
import {SocureDeviceTrackingService} from '../socure/socure-device-tracking.service';

@Directive({
  selector: '[analyticsTracking]'
})
export class AnalyticsTrackingDirective implements OnInit {
  @Input('analyticsTracking') pageName: string;
  @Input('analyticsTrackingOpts') parameters: { [keys: string]: any } = {};
  @Input('analyticsTrackingForceView') viewPageNow = false;

  constructor(
    private tealiumService: TealiumService,
    private adobeLaunchService: AdobeLaunchService,
    private socureDeviceTrackingService: SocureDeviceTrackingService,
  ) {}

  ngOnInit(): void {
    if (this.pageName) {
      this.socureDeviceTrackingService.track();

      if (this.viewPageNow) {
        this.tealiumService.trackNow({...this.parameters, page_name: this.pageName});
        this.adobeLaunchService.trackNow({...this.parameters, page_name: this.pageName});
      } else {
        this.tealiumService.trackDefer({...this.parameters, page_name: this.pageName});
        this.adobeLaunchService.trackDefer({...this.parameters, page_name: this.pageName});
      }
    }
  }

}
