import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AbstractControl, FormControl, FormGroup, ValidatorFn, Validators} from '@angular/forms';
import {FieldsToDisplay, getReferralFields, PromoField} from '../../services/promo-configuration.service';
import {ApplicationModel} from '../../models/application-model';

@Component({
  selector: 'lynx-referral-field',
  templateUrl: './referral-field.component.html',
  styleUrls: ['./referral-field.component.scss']
})
export class ReferralFieldComponent implements OnInit {

  @Input() ccApp: ApplicationModel;
  @Input() submitted = false;
  @Input() fieldsToDisplay: FieldsToDisplay;
  @Output() referralInfoCreated = new EventEmitter<FormGroup>();

  referralFormGroup: FormGroup = new FormGroup({});
  shouldDisplayReferralInfoFlag: boolean;

  constructor() { }

  ngOnInit() {
    this.shouldDisplayReferralInfoFlag = this.shouldDisplayReferralInfo();
    if (this.shouldDisplayReferralInfoFlag) {
      this.referralFormGroup = this.createReferralFormGroup(this.ccApp, this.fieldsToDisplay);
      this.referralInfoCreated.emit(this.referralFormGroup);
    }
  }

  private createReferralFormGroup(ccApp, fieldsToDisplay: FieldsToDisplay) {

    const referralFields = getReferralFields(fieldsToDisplay);

    const controls: {[p: string]: AbstractControl} = Object.keys(referralFields)
      .reduce((previousState, field) => {
        const validators: ValidatorFn[] = [];
        if (fieldsToDisplay[field].required) {
          validators.push(Validators.required);
        }
        previousState[field] = new FormControl(
          ccApp[field],
          validators
        );
        return previousState;
      }, {});

    const bankNumberValidators: ValidatorFn[] = [];
    if (fieldsToDisplay.bankNumber && fieldsToDisplay.bankNumber.required) {
      bankNumberValidators.push(Validators.required);
    }

    const branchNumberValidators: ValidatorFn[] = [Validators.minLength(3)];
    if (fieldsToDisplay.branchNumber && fieldsToDisplay.branchNumber.required) {
      branchNumberValidators.push(Validators.required);
    }

    if (fieldsToDisplay.bankNumber) {
      controls.bankNumber = new FormControl(this.ccApp.agentBank, bankNumberValidators);
    } if (fieldsToDisplay.branchNumber) {
      controls.branchNumber = new FormControl(this.ccApp.subAgent, branchNumberValidators);
    }

    return new FormGroup(controls);
  }

  getPattern(field: PromoField) : string {
    let bounds : string = '*';
    let format : string = '\\w';
    if (field.configuration) {
      if (field.configuration.length) {
        bounds = '{' + field.configuration.length + '}';
      }
      if (field.configuration.format) {
        switch (field.configuration.format) {
          case 'ALPHA':
            format = '[a-zA-Z]';
            break;
          case 'NUMERIC':
            format = '\\d';
            break;
        }
      }
    }
    return format + bounds;
  }

  getMask(field: PromoField) {
    let maxlength = !!field.configuration && field.configuration.length || 25;
    if (field.configuration) {
      if (field.configuration.format) {
        switch (field.configuration.format) {
          case 'ALPHA':
            return {mask: Array(maxlength).fill(/[a-zA-Z]/), guide: false};
          case 'NUMERIC':
            return {mask: Array(maxlength).fill(/\d/), guide: false};
        }
      }
    }
    return {mask: Array(maxlength).fill(/\w/), guide: false};
  }


  shouldDisplayReferralInfo() {
    return Object.keys(getReferralFields(this.fieldsToDisplay)).length > 0;
  }
}
