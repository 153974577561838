import {AfterViewInit, Component, Input, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, ValidatorFn, Validators} from '@angular/forms';
import {isFormEmpty} from '../../utils/form-util';
import {validateWholeDollarFormat} from '../../validators/form-field-validators';

@Component({
  selector: 'lynx-balance-transfer',
  templateUrl: './balance-transfer.component.html',
  styleUrls: ['./balance-transfer.component.scss']
})
export class BalanceTransferComponent implements OnInit, AfterViewInit {
  @Input() balanceTransferFormGroup: FormGroup;
  @Input() submitted: boolean;
  @Input() index = 1;
  @Input() soctUrl: string;

  validators = {
    'accountNumber': [Validators.maxLength(16)],
    'creditorName': [Validators.maxLength(25)],
    'amount': [validateWholeDollarFormat()],
    'paymentAddress': [Validators.maxLength(31)],
    'city': [Validators.maxLength(20)],
    'state': [],
    'zipCode': []
  };

  constructor(
    private formBuilder: FormBuilder
  ) {
  }

  ngOnInit() {
    this.balanceTransferFormGroup.addControl('accountNumber', this.formBuilder.control(null, this.validators.accountNumber));
    this.balanceTransferFormGroup.addControl('creditorName', this.formBuilder.control(null, this.validators.creditorName));
    this.balanceTransferFormGroup.addControl('amount', this.formBuilder.control(null, this.validators.amount));
    this.balanceTransferFormGroup.addControl('paymentAddress', this.formBuilder.control(null, this.validators.paymentAddress));
    this.balanceTransferFormGroup.addControl('city', this.formBuilder.control(null, this.validators.city));
    this.balanceTransferFormGroup.addControl('state', this.formBuilder.control(null, this.validators.state));
    this.balanceTransferFormGroup.addControl('zipCode', this.formBuilder.control(null, this.validators.zipCode));

    this.balanceTransferFormGroup.valueChanges.subscribe((value: any) => {
      const isFilledOut: boolean = !isFormEmpty(value);
      let controls = this.balanceTransferFormGroup.controls;
      if (isFilledOut) {
        Object.keys(controls).forEach(name => {
          const validatorFns = (this.validators[name] as ValidatorFn[]).concat(Validators.required);
          this.balanceTransferFormGroup.get(name).setValidators(validatorFns);
          this.balanceTransferFormGroup.get(name).updateValueAndValidity({emitEvent: false});
        });
      }
      else {
        Object.keys(controls).forEach(name => {
          this.balanceTransferFormGroup.get(name).setValidators(this.validators[name]);
          this.balanceTransferFormGroup.get(name).updateValueAndValidity({emitEvent: false});
        });
      }

    });
  }

  ngAfterViewInit(): void {
    // Update validators with computed validator FN that
    // includes validators added outside of this component (patterns, masks, ect)
    Object.keys(this.validators)
      .filter(key => !!this.balanceTransferFormGroup.get(key).validator)
      .forEach(key => {
        const validator = this.balanceTransferFormGroup.get(key).validator;
        this.validators[key] = [validator];
    });
  }

  removeBalanceTransfer() {
    this.balanceTransferFormGroup.reset();
  }

}
